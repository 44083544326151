/* eslint-disable import/no-named-as-default */
import { Stack, Text } from 'native-base';
import { Controller } from 'react-hook-form';

import Checkbox from '../../../Checkbox';
import ImageZone from '../../../Dropzone/ImageZone';
import ImageZoneMini from '../../../Dropzone/ImageZoneMini';
import Input from '../../../Input';
import InputSearchLocation from '../../../InputSearchLocation';
import Radio from '../../../Radio';
import Select from '../../../Select';
import TagGroup from '../../../Tags/TagGroup';
import TextArea from '../../../TextArea';
import { AppSection } from '../AppSection';
import { RadioAppSection } from '../RadioAppSection';
import {
  IInputItemsRender,
  IInputsProps,
  INPUT_COMPONENT_TYPES,
} from './types';

const renderInput = ({
  component,
  inputProps,
  formState,
  name,
  ...rest
}: IInputItemsRender) => {
  switch (component) {
    case INPUT_COMPONENT_TYPES.INPUT:
      return (
        <Input
          name={name}
          errorMessage={formState.errors[name]?.message as string}
          {...inputProps}
          {...rest}
        />
      );
    case INPUT_COMPONENT_TYPES.TEXT_AREA:
      return (
        <TextArea
          name={name}
          errorMessage={formState.errors[name]?.message as string}
          {...inputProps}
          {...rest}
        />
      );
    case INPUT_COMPONENT_TYPES.SELECT:
      return (
        <Controller
          name={name}
          control={rest.control}
          defaultValue={inputProps.defaultValue}
          render={({ field: { onChange } }) => (
            <Select
              name={name}
              errorMessage={formState.errors[name]?.message as string}
              onChange={onChange}
              {...inputProps}
              {...rest}
            />
          )}
        />
      );
    case INPUT_COMPONENT_TYPES.DYNAMIC_INPUT:
      return (
        <TagGroup
          name={name}
          errorMessage={formState.errors[name]?.message as string}
          {...inputProps}
          {...rest}
        />
      );
    case INPUT_COMPONENT_TYPES.RADIO:
      return (
        <Radio
          name={name}
          errorMessage={formState.errors[name]?.message as string}
          {...inputProps}
          {...rest}
        />
      );
    case INPUT_COMPONENT_TYPES.CHECKBOX:
      return (
        <Checkbox
          name={name}
          errorMessage={formState.errors[name]?.message as string}
          {...inputProps}
          {...rest}
        />
      );
    case INPUT_COMPONENT_TYPES.LOCALE:
      //FIXME: Create controlled component
      return (
        <Controller
          name={name}
          control={rest.control}
          render={({ field: { onChange } }) => (
            <InputSearchLocation onChangeValue={onChange} />
          )}
        />
      );
    case INPUT_COMPONENT_TYPES.DROPZONE_AREA:
      return (
        <Controller
          name={name}
          control={rest.control}
          render={({ field: { onChange, value } }) => {
            return (
              <ImageZone
                filesField={name}
                handleDropFile={onChange}
                resetState={() => {
                  onChange(null);
                }}
                // If we leave this without defaultImage, we can't delete API images (Edit Activity).
                {...inputProps}
                {...rest}
                // TODO: Not a ideal solution for removing API images (string), but to avoid this we would need to refactor the entire ImageZone component, wich for now it's not viable.
                defaultImage={value}
              />
            );
          }}
        />
      );

    case INPUT_COMPONENT_TYPES.UPLOAD_FILES:
      return (
        <Controller
          name={name}
          control={rest.control}
          render={({ field: { onChange, value } }) => {
            return (
              <ImageZoneMini
                thumbnailImages={value}
                filesField={name}
                handleDropFile={onChange}
                multiple
                {...inputProps}
                {...rest}
              />
            );
          }}
        />
      );
    case INPUT_COMPONENT_TYPES.ACTIVITY_METRICS:
      return (
        <Stack space={4}>
          <Stack space={2}>
            <Text color="gray.600" fontSize="xl">
              Activity Metrics
            </Text>
            <Text color="gray.600" fontSize="sm" fontWeight="400">
              Tell volunteers how you will measure the success of this activity.
            </Text>
          </Stack>

          <Input
            name={name}
            errorMessage={formState.errors[name]?.message as string}
            {...inputProps}
            {...rest}
          />
        </Stack>
      );

    case INPUT_COMPONENT_TYPES.APP_SELECT:
      return <AppSection name={name} {...inputProps} {...rest} />;

    case INPUT_COMPONENT_TYPES.RADIO_APP_SELECT:
      return <RadioAppSection name={name} {...inputProps} {...rest} />;
    default:
      return (
        <Input
          name={name}
          errorMessage={formState.errors[name]?.message as string}
          {...inputProps}
          {...rest}
        />
      );
  }
};

export const useFormFieldsRenderController = ({
  control,
  inputsSections,
  options,
  formState,
}: IInputsProps) => {
  return { control, inputsSections, options, formState, renderInput };
};

import { useCallback, useMemo, useRef, useState } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { OrganizationDepartment } from 'ui/types/interfaces/organization';
import { formatMeasurementAmount } from 'ui/utils/measurements/format';

import { useCurrentOrganization } from '~/hooks/organization/useCurrentOrganization';

import { CompanyDetailsSectionProps } from './types';

export const useCompanyDetailsSectionController = ({
  onRefetch,
  organization,
}: CompanyDetailsSectionProps) => {
  // States
  const departmentModalRef = useRef<IModalRefProps>(null);
  const companyGoalHelpModalRef = useRef<IModalRefProps>(null);
  const deleteDepartmentModalRef = useRef<IModalRefProps>(null);

  const [selectedDepartment, setSelectedDepartment] =
    useState<OrganizationDepartment | null>(null);

  // Hooks
  const { isNFP } = useCurrentOrganization();

  // handlers
  const openCompanyGoalHelpModal = () =>
    companyGoalHelpModalRef.current?.open();

  const handleLoadData = useCallback(() => {
    return (organization?.organizationSettings?.departments || []).map(
      (department) => department,
    );
  }, [organization]);

  const handleOpenDepartmentModal = (department?: OrganizationDepartment) => {
    setSelectedDepartment(department || null);
    departmentModalRef?.current?.open();
  };

  const handleOpenDeleteDepartmentModal = (
    department: OrganizationDepartment,
  ) => {
    setSelectedDepartment(department);
    deleteDepartmentModalRef?.current?.open();
  };

  const handleSubmitSuccess = () => {
    setSelectedDepartment(null);
    onRefetch();
  };

  // Computed Values
  const companyMeasurementGoal = useMemo(() => {
    if (!organization) return 'N/A';
    const { measurementGoal = 0 } = organization?.organizationSettings ?? {};
    return formatMeasurementAmount(measurementGoal);
  }, [organization]);

  return {
    isNFP,
    selectedDepartment,
    departmentModalRef,
    companyGoalHelpModalRef,
    deleteDepartmentModalRef,
    companyMeasurementGoal,
    onRefetch,
    handleLoadData,
    handleSubmitSuccess,
    openCompanyGoalHelpModal,
    handleOpenDepartmentModal,
    handleOpenDeleteDepartmentModal,
  };
};

import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadAppsConnectedToEcosystem } from '~/hooks/useLoadAppsConnectedToEcosystem';
import { useQuery } from '~/hooks/useQuery';
import { useRouter } from '~/hooks/useRouter';
import { IEcosystem } from '~/types/interfaces/ecosystem';

export const useEcosystemsController = () => {
  const modalRef = useRef<IModalRefProps>(null);

  const { goToRoute, goBack, params, replaceRoute } = useRouter();
  const ecosystemId = params.id as string;

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);

  const { connectedApps, isLoadingConnectedApps } =
    useLoadAppsConnectedToEcosystem(organizationSelectedId, ecosystemId);

  const {
    data: ecosystem,
    isLoading: isLoadingEcosystem,
    isError,
  } = useQuery<IEcosystem>(`/ecosystem/${ecosystemId}`);

  const handleLeftButtonAction = () => modalRef.current?.close();

  const handleEditEcosystem = () => goToRoute(`/ecosystem/edit/${ecosystemId}`);

  const handleManageApps = () =>
    goToRoute(`/ecosystem/edit/${ecosystemId}/manage-apps`);

  const handleBackToEcosystems = () => {
    console.log('BAck to Ecosystem');
    replaceRoute(PAGES.ECOSYSTEM_SETTINGS);
  };

  useEffect(() => {
    if (isError) {
      toast.error('Ecosystem not found.');
      goBack();
    }
  }, [isError, goBack]);

  return {
    ecosystem,
    connectedApps,
    modalRef,
    isLoadingEcosystem,
    isLoadingConnectedApps,
    handleManageApps,
    goBack,
    handleEditEcosystem,
    handleLeftButtonAction,
    handleBackToEcosystems,
  };
};

export const tabElements = [
  {
    title: 'Ecosystem details',
    component: () => <></>,
  },
  {
    title: 'Customization',
    component: () => <></>,
  },
  {
    title: 'Apps',
    component: () => <></>,
  },
];

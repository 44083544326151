import { ActivityTypeEnum } from '~/enums';
import { api } from '~/services/api';
import { QueryParams } from '~/services/resources/types';
import {
  ICreateActivityDTO,
  ICreateEventDTO,
  IEditEventDTO,
} from '~/types/dtos';

const MODULE_URL = 'activity-definition';

export default class ActivityDefinitionService {
  static async findAll(params?: QueryParams) {
    const options = {
      params,
    };
    return await api.get(MODULE_URL, options);
  }
  static async findOne(id: string) {
    return await api.get(`${MODULE_URL}/${id}`);
  }

  static async deleteOne(id: string) {
    return await api.delete(`${MODULE_URL}/${id}`);
  }

  static async get(id: string) {
    return await api.get(`${MODULE_URL}/${id}`);
  }

  static async createEventActivityDefinition(data: Partial<ICreateEventDTO>) {
    return await api.post(`${MODULE_URL}/event`, {
      ...data,
      type: 'Event',
    });
  }

  static async editEventActivityDefinition(
    actionActivityDefinitionId: string,
    newData: IEditEventDTO,
  ) {
    return await api.put(`${MODULE_URL}/event/${actionActivityDefinitionId}`, {
      ...newData,
      type: 'Event', // NOTE: This is really usefull?
    });
  }

  static async createActionActivityDefinition(
    data: Partial<ICreateActivityDTO>,
  ) {
    return await api.post(`${MODULE_URL}/action`, {
      ...data,
      type: 'Action',
    });
  }

  static async editActionActivityDefininition(
    actionActivityDefinitionId: string,
    newData: IEditEventDTO,
  ) {
    return await api.put(`${MODULE_URL}/action/${actionActivityDefinitionId}`, {
      ...newData,
      type: 'Action', // NOTE: This is really usefull?
    });
  }

  static async createOpportunityActivityDefininition(
    data: Partial<ICreateEventDTO>,
  ) {
    return await api.post(`${MODULE_URL}/ongoing-opportunity`, {
      ...data,
      type: ActivityTypeEnum.OngoingOpportunity,
    });
  }

  static async editOpportunityActivityDefinition(
    actionActivityDefinitionId: string,
    newData: IEditEventDTO,
  ) {
    return await api.put(`${MODULE_URL}/event/${actionActivityDefinitionId}`, {
      ...newData,
      type: ActivityTypeEnum.OngoingOpportunity, // NOTE: This is really usefull?
    });
  }
}

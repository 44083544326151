import { useMemo } from 'react';
import { makeActivityTypeBadgeTitleNode } from 'ui/components/v2/Cards/NotificationCard/utils';

import { userUserNotificationCardController } from '../controller';
import { MultipleMeasurementsCreatedNotificationCardProps } from './types';

export const useMultipleMeasurementsCreatedNotificationCardController = (
  props: MultipleMeasurementsCreatedNotificationCardProps,
) => {
  const { activityType, isExternal = false } = props;

  const { authorTitleNode, date, profilePictureUrl } =
    userUserNotificationCardController(props);

  const titleNodes = useMemo(() => {
    const nodes = [
      authorTitleNode,
      { text: 'logged' },
      {
        text: isExternal ? 'an off-platform activity' : `on an 'activity'}`,
      },
      makeActivityTypeBadgeTitleNode(activityType),
    ];

    return nodes;
  }, [activityType, authorTitleNode, isExternal]);

  return { titleNodes, date, profilePictureUrl };
};

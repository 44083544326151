import { Box, HStack, Stack } from 'native-base';
import React from 'react';
import Icon from 'ui/components/Icon';
import Tag from 'ui/components/Tags/Tag';

import PageHeader from '~/components/Layout/PageHeader';
import { useScaffoldController } from '~/components/Scaffold/controller';
import { IScaffoldProps } from '~/components/Scaffold/types';
import { colors } from '~/theme/colors';
import {
  iconToShowByActivityType,
  labelTypeEventBgColorByActivityType,
  showCustomIconByActivityTypeAction,
  textToShowByActivityType,
} from '~/utils/functions';

const Scaffold: React.FC<IScaffoldProps> = ({
  title,
  children,
  height,
  withBg = true,
  actionComponents,
  heightContent,
  alignItemsChildren = 'flex-start',
  headerChildren,
  titleIcon,
  titleIconColor = colors.gray['500'],
  titleIconSize = 'md',
  showActivityTypeIcon,
  activityType,
  eventApplicationType,
  isViewTeam,
  teamMembersQuantity,
  ...rest
}) => {
  const {
    isTitleOnlyHeader,
    isHeaderWithIcon,
    isActivityHeader,
    isTeamHeader,
    showActionComponents,
  } = useScaffoldController({
    title,
    titleIcon,
    showActivityTypeIcon,
    activityType,
    isViewTeam,
    teamMembersQuantity,
    actionComponents,
  });
  return (
    <Box
      w="full"
      height={height ? height : 'Calc(100vh - 82px)'}
      overflowY="scroll"
      pb={12}
      background={withBg ? 'singletons.mediumWhite' : 'transparent'}
    >
      <Box w="min(90%, 1300px)" mx="auto">
        <Stack pt={10} space={10}>
          {!!isHeaderWithIcon ? (
            <HStack
              justifyContent={
                showActionComponents ? 'space-between' : 'inherit'
              }
            >
              <HStack space={3} alignItems="center">
                <Icon
                  icon={titleIcon}
                  size={titleIconSize}
                  color={titleIconColor}
                />
                {!!title && <PageHeader title={title} {...rest} />}
              </HStack>
              {showActionComponents ? <Stack>{actionComponents}</Stack> : null}
            </HStack>
          ) : null}

          {isTitleOnlyHeader && title ? (
            <>
              <HStack>
                <PageHeader flex={1} width="100%" title={title} {...rest} />
                {showActionComponents && <Stack>{actionComponents}</Stack>}
              </HStack>
            </>
          ) : null}

          {isActivityHeader && activityType ? (
            <HStack
              justifyContent={
                showActionComponents
                  ? 'space-between'
                  : 'inherit'
                  ? 'space-between'
                  : 'inherit'
              }
            >
              <HStack flex={1} space={3} alignItems="center">
                {!!title && <PageHeader flex={1} title={title} {...rest} />}

                <Tag
                  text={textToShowByActivityType(
                    activityType,
                    eventApplicationType,
                  )}
                  type="actions"
                  icon={iconToShowByActivityType(
                    activityType,
                    eventApplicationType,
                  )}
                  tagTextColor={colors.darkText}
                  tagBgColor={labelTypeEventBgColorByActivityType(activityType)}
                  showCustomIcon={showCustomIconByActivityTypeAction(
                    activityType,
                  )}
                  showIcon
                />
              </HStack>
              {actionComponents ? <Stack>{actionComponents}</Stack> : null}
            </HStack>
          ) : null}

          {isTeamHeader ? (
            <HStack
              justifyContent={actionComponents ? 'space-between' : 'inherit'}
            >
              <HStack space={3} alignItems="center">
                {!!title && <PageHeader flex={1} title={title} {...rest} />}

                <Tag
                  text={`${teamMembersQuantity} members`}
                  type="actions"
                  tagTextColor={colors.singletons.black}
                  tagBgColor={colors.gray[200]}
                />
              </HStack>
              {actionComponents ? <Stack>{actionComponents}</Stack> : null}
            </HStack>
          ) : null}

          {headerChildren ? <Box>{headerChildren}</Box> : null}

          <Stack
            w="100%"
            bgColor={withBg ? 'white' : 'transparent'}
            p={withBg ? 6 : 0}
            h={heightContent}
            borderRadius={withBg ? '3xl' : 'transparent'}
            alignItems={alignItemsChildren}
            space={withBg ? 5 : 0}
          >
            {children}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default Scaffold;

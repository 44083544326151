import { Image, VStack } from 'native-base';

import { CoverImageCaption } from './CoverImageCaption';
import { CardCoverImageProps } from './types';

export const CardCoverImage = ({
  ratio = 56,
  maxWidth = 'unset',
  imageAlt = '',
  imageUrl = '',
  imageFallBackUrl = '',
  caption,
}: CardCoverImageProps) => {
  return (
    <VStack
      paddingBottom={`${ratio}%`}
      width={'100%'}
      maxWidth={maxWidth}
      backgroundColor={'gray.400'}
      overflow={'hidden'}
      position={'relative'}
    >
      {caption && <CoverImageCaption {...caption} />}

      <Image
        position={'absolute'}
        top={0}
        left={0}
        w="full"
        h="full"
        resizeMode="cover"
        alt={imageAlt}
        source={{ uri: imageUrl || '' }}
        fallbackSource={{ uri: imageFallBackUrl }}
      />
    </VStack>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { ORGANIZATION_DEPARTMENT_MESSAGES } from '~/constants/messages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import OrganizationDepartmentsService from '~/services/resources/organization/departments/departments.service';

import { INITIAL_FORM_DATA, schemaValidation } from './constants';
import { DepartmentFormFields, DepartmentFormModalProps } from './types';

export const useDepartmentFormModalController = (
  { defaultDepartmentData, onSuccess }: DepartmentFormModalProps,
  ref: React.MutableRefObject<IModalRefProps>,
) => {
  // Providers
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  // States
  const [isLoading, setIsLoading] = useState(false);

  // Hooks
  const { control, handleSubmit, formState, reset } =
    useForm<DepartmentFormFields>({
      resolver: yupResolver(schemaValidation),
      defaultValues: INITIAL_FORM_DATA,
    });

  useEffect(() => {
    reset({ name: defaultDepartmentData?.name || '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDepartmentData]);

  const onSubmitHandler = handleSubmit(async (data) => {
    setIsLoading(true);
    try {
      const { name } = data;
      if (!!defaultDepartmentData?._id) {
        await OrganizationDepartmentsService.update({
          name,
          organizationId: selectedEcosystem?.organization || '',
          departmentId: defaultDepartmentData._id,
        });
        toast.success(ORGANIZATION_DEPARTMENT_MESSAGES.UPDATE_SUCCESS);
      } else {
        await OrganizationDepartmentsService.create({
          name,
          organizationId: selectedEcosystem?.organization || '',
        });
        toast.success(ORGANIZATION_DEPARTMENT_MESSAGES.CREATE_SUCCESS);
      }
      reset({ name: '' });
      onSuccess?.();
      ref?.current?.close();
    } catch (error: unknown) {
      toast.error(ORGANIZATION_DEPARTMENT_MESSAGES.CREATE_FAILURE);
    } finally {
      setIsLoading(false);
    }
  });

  const handleCancel = () => {
    ref?.current?.close();
    reset({ name: '' });
  };

  // Computed values
  const title = !!defaultDepartmentData?._id ? 'Edit group' : 'Add group';

  const actionLabel = !!defaultDepartmentData?._id ? 'Save' : 'Add department';

  return {
    title,
    actionLabel,
    formState,
    isLoading,
    control,
    handleCancel,
    onSubmitHandler,
  };
};

import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { GeneratingReport } from 'ui/components/GeneratingReport';

import { HeaderLayout } from '~/components/HeaderLayout';
import Page404 from '~/components/Page404';
import { PAGES } from '~/constants/pages.constants';
import { useFirebaseRoutesAnalytics } from '~/hooks/useFirebaseAnalytics';
import AdminSuccessfullyJoined from '~/pages/Common/JoinAdmin/AdminSuccessfullyJoined';
import JoinAsAdminInvite from '~/pages/Common/JoinAdmin/JoinAsAdminInvite';
import JoinAsAdminInviteDeclined from '~/pages/Common/JoinAdmin/JoinAsAdminInviteDeclined';
import { JoinAsPartner } from '~/pages/Common/JoinAsPartner';
import { JoinAsPartnerSignUp } from '~/pages/Common/JoinAsPartnerSignup';
import { AccountDeletedFeedback } from '~/pages/Unauthenticated/AccountDeletedFeedback';
import { JoinAsPartnerSignIn } from '~/pages/Unauthenticated/JoinAsPartnerSignIn';
import { LoginPage } from '~/pages/Unauthenticated/Login';
import { RecoveryPassword } from '~/pages/Unauthenticated/RecoveryPassword';
import { ForgotPassword } from '~/pages/Unauthenticated/RecoveryPassword/ForgotPassword';
import SignUpPage from '~/pages/Unauthenticated/SignUpPage';
import ConfirmEmailPage from '~/pages/Unauthenticated/SignUpPage/ConfirmEmailPage';

export const UnauthenticatedRoutes = () => {
  useFirebaseRoutesAnalytics();

  return (
    <Routes>
      <Route
        element={
          <HeaderLayout>
            <Outlet />
          </HeaderLayout>
        }
      >
        <Route path={PAGES.SIGN_UP} element={<SignUpPage />} />
        <Route path={PAGES.RECOVERY_PASSWORD} element={<RecoveryPassword />} />
        <Route
          path={PAGES.SIGN_UP_EMAIL_CONFIRMATION}
          element={<ConfirmEmailPage />}
        />
        <Route path={PAGES.INVITE_JOIN_ADMIN} element={<JoinAsAdminInvite />} />
        <Route
          path={PAGES.INVITE_ADMIN_DECLINED}
          element={<JoinAsAdminInviteDeclined />}
        />
        <Route
          path={PAGES.JOIN_ADMIN_SUCCESS}
          element={<AdminSuccessfullyJoined />}
        />
        <Route
          path={PAGES.INVITE_JOIN_AS_PARTNER}
          element={<JoinAsPartner />}
        />
      </Route>

      <Route
        path={PAGES.INVITE_JOIN_AS_PARTNER_SLUG}
        element={<JoinAsPartner />}
      />
      <Route
        path={PAGES.INVITE_JOIN_AS_PARTNER_SIGN_UP}
        element={<JoinAsPartnerSignUp />}
      />
      <Route
        path={PAGES.INVITE_JOIN_AS_PARTNER_SIGN_UP_SLUG}
        element={<JoinAsPartnerSignUp />}
      />
      <Route
        path={PAGES.INVITE_JOIN_AS_PARTNER_SIGN_IN}
        element={<JoinAsPartnerSignIn />}
      />
      <Route
        path={PAGES.INVITE_JOIN_AS_PARTNER_SIGN_IN_SLUG}
        element={<JoinAsPartnerSignIn />}
      />
      <Route path={PAGES.SIGNIN} element={<LoginPage />} />
      <Route path={PAGES.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route
        path={PAGES.ACCOUNT_DELETED}
        element={<AccountDeletedFeedback />}
      />
      <Route path={PAGES.PAGE_404} element={<Page404 />} />
      <Route path={PAGES.GENERATING_REPORT} element={<GeneratingReport />} />
      <Route path="*" element={<Navigate to={PAGES.PAGE_404} replace />} />
    </Routes>
  );
};

import { RefObject } from 'react';
import { Control, FieldArrayWithId, FieldErrors } from 'react-hook-form';
import { ILocationSelected } from 'ui/components/InputSearchLocation/types';
import { IOptionsItem } from 'ui/components/Select/types';
import { ITabsRefProps } from 'ui/components/Tabs/types';

import { OrganizationType } from '~/enums';

export interface IRegisterOrganizationForm {
  name: string;
  type: OrganizationType;
  domainSlug: string;
  causeOptions: string[];
  purpose: string;
  logo?: File[] | string;
  phone: string;
  email: string;
  websiteLink: string;
  description: string;
  termsOfServiceLink: string;
  location: ILocationSelected;
  adminEmail: string;
  adminEmailList: { email: string }[];
  currentStep: number;
}

export const organizationTypes = [
  {
    value: 'NFP',
    label: 'Charity',
  },
  {
    value: 'COMPANY',
    label: 'Company',
  },
];

export interface IRegisterOrganizationProps {
  control: Control<IRegisterOrganizationForm>;
  errors: FieldErrors<IRegisterOrganizationForm>;
  isDisabledButton: boolean;
  tabsRef: RefObject<ITabsRefProps>;
}

export interface IBasicInformationFormProps extends IRegisterOrganizationProps {
  organisationTypes: IOptionsItem[];
  causeOptions: IOptionsItem[];
  handleBack: () => void;
  handleContinue: () => void;
}

export interface IDetailsFormProps extends IRegisterOrganizationProps {
  selectedLocation?: ILocationSelected;
  onLocationChangeHandler: (location: ILocationSelected | undefined) => void;
  handleContinue: () => void;
  handleBack: () => void;
}

export interface IInviteAdminFormProps extends IRegisterOrganizationProps {
  adminEmails: FieldArrayWithId<IRegisterOrganizationForm, 'adminEmailList'>[];
  isSubmitting: boolean;
  onAddEmail: (email: string) => () => void;
  onRemoveEmail: (index: number) => () => void;
  handleBack: () => void;
  onSubmit: () => void;
}

export const FAKE_MEASUREMENT_UNITIES = [
  {
    _id: '65941cfefd5d562cb3083a25',
    singularLabel: 'Hour',
    pluralLabel: 'Hours',
    steps: [],
    category: 'Time',
    createdAt: '2023-01-02T12:17:22.635Z',
    updatedAt: '2023-01-02T12:17:22.635Z',
  },
  {
    _id: '65941da3fd5d562cb3083a26',
    category: 'Weight',
    singularLabel: 'Kilogram',
    pluralLabel: 'Kilograms',
    steps: [],
    createdAt: '2023-01-02T12:17:22.635Z',
    updatedAt: '2023-01-02T12:17:22.635Z',
  },

  {
    _id: '65941e2dfd5d562cb3083a27',
    category: 'Completion',
    singularLabel: 'Step',
    pluralLabel: 'Steps',
    steps: [
      {
        title: 'Step 1',
        description: '',
      },
      {
        title: 'Step 2',
        description: '',
      },
    ],
    createdAt: '2023-01-02T12:19:22.635Z',
    updatedAt: '2023-08-08T12:19:22.635Z',
  },
];

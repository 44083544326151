import { Hidden, HStack, Stack, VStack } from 'native-base';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { NotificationCard } from 'ui/components/NotificationCard';

import { useAuthLayoutController } from '~/components/AuthLayout/controller';
import { AuthLayoutProps } from '~/components/AuthLayout/types';
import Drawer from '~/components/Layout/Drawer';
import { Loading } from '~/components/Loading';
import { PAGES } from '~/constants/pages.constants';
import { HeaderAuthenticated } from '~/HeaderAuthenticated';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { useSetEcosystemsToSelectOptions } from '~/hooks/useSetEcosystemsToSelectOptions';

export const AuthLayout = ({ children }: AuthLayoutProps) => {
  const location = useLocation();
  const { goToRoute } = useRouter();
  const { organizations } = useAppSelector(({ auth }) => auth);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  const { ecosystemName, ecosystemLogo } = useSetEcosystemsToSelectOptions();

  const { isJoiningAsAdmin, code, organizationId } = useAppSelector(
    ({ joinAsAdmin }) => joinAsAdmin,
  );

  const { content, isNotificationOpen } = useAuthLayoutController();

  const shouldRender = useMemo(
    () =>
      !(!Array.isArray(organizations) || organizations.length === 0) ||
      !selectedEcosystem,
    [organizations, selectedEcosystem],
  );

  useEffect(() => {
    if (isJoiningAsAdmin) {
      goToRoute(`/invite/${code}/admin?organization=${organizationId}`);
      return;
    }

    if (
      (!organizations || organizations?.length === 0) &&
      location.pathname !== PAGES.SIGN_UP_CREATE_ORGANISATION
    ) {
      goToRoute(PAGES.SIGN_UP_CREATE_ORGANISATION);
    }
  }, [
    organizations,
    shouldRender,
    location,
    isJoiningAsAdmin,
    code,
    organizationId,
    goToRoute,
  ]);

  const handleLogoPress = () => {
    goToRoute(PAGES.HOME);
  };

  if (!shouldRender) return <Loading containerHeight="100vh"></Loading>;

  return (
    <VStack w={'full'} h={'full'}>
      {/* header */}
      <HeaderAuthenticated
        ecosystemLogo={ecosystemLogo}
        ecosystemName={ecosystemName}
        onLogoPress={handleLogoPress}
      />

      {/* main */}
      <HStack flex={1} w={'full'} h={'full'}>
        {/* Modals */}
        {isNotificationOpen ? (
          <Stack
            position="absolute"
            width={{ base: '100%', md: '95.5%' }}
            alignItems="flex-end"
            padding={{ base: 4 }}
            zIndex={999}
          >
            <NotificationCard>{content}</NotificationCard>
          </Stack>
        ) : null}

        {/* aside */}
        <Hidden till={'lg'}>
          <Drawer />
        </Hidden>

        {/* content */}
        <VStack bgColor="light.100" overflowY="auto" flex={1}>
          {children}
        </VStack>
      </HStack>
    </VStack>
  );
};

import { Box, HStack } from 'native-base';
import { useEffect, useMemo, useState } from 'react';
import Select from 'ui/components/v2/inputs/Select';
import { ISelectOptionsItem } from 'ui/components/v2/inputs/Select/types';
import TextInput from 'ui/components/v2/inputs/TextInput';
import { MeasurementCategory } from 'ui/enums';
import { MeasurementUnit } from 'ui/types/measurementUnit';

import { useGetMeasurementUnits } from '~/hooks/useGetMeasurementUnits';
import { activityMetricsOptions } from '~/pages/Authenticated/Actions/AddAction/ActionForm/AboutForm/utils';

export type ICharitiesHelpedFilters = {
  text: string;
  option: ISelectOptionsItem<{
    data: MeasurementUnit;
    group: MeasurementCategory;
  }> | null;
};

type CharitiesHelpedFiltersProps = {
  onFilterChange: (filters: ICharitiesHelpedFilters) => void;
  isMeasurementFilterEnabled?: boolean;
};

export const CharitiesHelpedFilters = ({
  onFilterChange,
  isMeasurementFilterEnabled = true,
}: CharitiesHelpedFiltersProps) => {
  const [textFilter, setTextFilter] = useState('');
  const { measurementUnits } = useGetMeasurementUnits({
    enabled: isMeasurementFilterEnabled,
  });
  const [selectedOption, setSelectedOption] =
    useState<ICharitiesHelpedFilters['option']>(null);

  useEffect(() => {
    onFilterChange({
      text: textFilter,
      option: selectedOption,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, textFilter]);

  const selectOptions = useMemo(() => {
    const values = activityMetricsOptions(measurementUnits) || [];
    const filteredValues = values.filter(
      (item) => item.label !== MeasurementCategory.Completion,
    );

    return filteredValues;
  }, [measurementUnits]);

  useEffect(() => {
    setSelectedOption((prevState) => {
      if (prevState === null && selectOptions.length > 0) {
        return selectOptions[0].options[0] ?? null;
      }
      return prevState;
    });
  }, [selectOptions]);

  return (
    <HStack space={2} px={6}>
      <Box flex={1}>
        <TextInput.Base placeholder="Search charity" onChange={setTextFilter} />
      </Box>
      {isMeasurementFilterEnabled && (
        <Box flex={1}>
          <Select.Base
            options={selectOptions}
            value={selectedOption}
            // @ts-expect-error - valid arg
            onChange={setSelectedOption}
          />
        </Box>
      )}
    </HStack>
  );
};

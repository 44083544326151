import { MeasurementCategory } from 'ui/enums';
import { MeasurementUnit } from 'ui/types/measurementUnit';
import { secondsToHoursAndMinutes } from 'ui/utils/secondsToDuration';

export function formatMeasurementAmount(
  amount: number,
  measurementUnit?: MeasurementUnit,
) {
  if (
    !measurementUnit ||
    measurementUnit.category === MeasurementCategory.Time
  ) {
    const { hours, minutes } = secondsToHoursAndMinutes(amount);
    return `${hours}h ${minutes}m`;
  }

  const unitSuffixLabel =
    amount != 1 ? measurementUnit.pluralLabel : measurementUnit.singularLabel;

  return `${amount} ${unitSuffixLabel}`;
}

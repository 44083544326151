import { formatDurationWithOptions } from 'date-fns/fp';
import { ActivityTypeBadge } from 'ui/components/v2/Badges/ActivityTypeBadge';
import { ActivityType } from 'ui/types/activities';
import { mapToActivitySemanticType } from 'ui/utils/activity/mappers';

export const ACTIVITY_PAGE_ROUTE = '/activities/:type/:id';

export const ActivityTypeRouteMap = {
  [ActivityType.Action]: 'action',
  [ActivityType.Event]: 'event',
  [ActivityType.Ongoing]: 'opportunity',
};

export const getActivityRoute = (
  activityDefinitionId: string,
  activityType: ActivityType,
) => {
  const type = ActivityTypeRouteMap[activityType];
  const route = ACTIVITY_PAGE_ROUTE.replace(':type', type).replace(
    ':id',
    activityDefinitionId,
  );
  return route;
};

export const makeActivityTitleNode = (
  activityDefinitionId: string,
  activityTitle: string,
  activityType: ActivityType,
) => {
  const text = activityTitle || '';
  const linkUrl = getActivityRoute(activityDefinitionId, activityType);

  return { text, linkUrl };
};

export const makeOrganizationTitleNode = (
  organizationId: string,
  organizationName: string,
) => {
  // NOTE: The profile page for organizations does not exist yet
  const text = organizationName || '';
  return { text, linkUrl: '', fontWeight: 500 };
};

export const makeActivityTypeBadgeTitleNode = (activityType: ActivityType) => {
  const activitySemanticType = mapToActivitySemanticType(activityType);
  return {
    customRender: (
      <ActivityTypeBadge activityType={activitySemanticType} defaultSize="sm" />
    ),
  };
};

export enum UploadsSignedURLObjectTarget {
  OrganizationPicture = 'OrganizationPicture',
  ActivityPicture = 'ActivityPicture',
  ImportActivityDefinition = 'ImportActivityDefinition',
  ImportActivity = 'ImportActivity',
}

export interface UploadsGenerateURLProps {
  Target: UploadsSignedURLObjectTarget;
  FileExtension: string;
  ImportFileId?: string;
}

export interface UploadsUploadFileToS3Props {
  file: File;
  signedURL: SignedURLResponse;
}

export interface SignedURLResponse {
  uploadURL: UploadURL;
  Key: string;
}

export interface UploadURL {
  url: string;
  fields: UploadURLFields;
}

export interface QueryParams {
  skip?: number;
  limit?: number;
  filter?: object | string;
  sort?: string;
  populate?: string;
}

export interface UploadURLFields {
  key: string;
  bucket: string;
  'X-Amz-Algorithm': string;
  'X-Amz-Credential': string;
  'X-Amz-Date': string;
  'X-Amz-Security-Token': string;
  Policy: string;
  'X-Amz-Signature': string;
}

export type BaseInsightQueryParams = {
  ecosystem: string;
  organization: string;
  startDate: string;
  endDate: string;
  apps: string[];
  granularity: 'Daily' | 'Monthly' | 'Yearly';
};

export enum InsightsPeriodType {
  Today = 'Today',
  ThisWeek = 'ThisWeek',
  ThisMonth = 'ThisMonth',
  Last7Days = 'Last7Days',
  Last30Days = 'Last30Days',
  Last3Months = 'Last3Months',
  Last6Months = 'Last6Months',
  Last12Months = 'Last12Months',
  Custom = 'Custom',
}

export type InsightByPeriodTypeQueryParams = BaseInsightQueryParams & {
  periodType: InsightsPeriodType;
};

export enum InsightMeasurementGroupedBy {
  ActivityOwnerOrganization = 'ActivityOwnerOrganization',
  UserProfileDepartment = 'UserProfileDepartment',
  MeasurementDate = 'MeasurementDate',
  UserProfile = 'UserProfile',
}

export enum InsightActivityApplicationGroupedBy {
  ApplicationDate = 'ApplicationDate',
  ActivityType = 'ActivityType',
}
export type CharitiesReportResponse = {
  currentPeriodInsights: {
    total: number;
    activityOwnerOrganizationSummary: {
      _id?: string;
      name: string;
      logo?: string;
    };
  }[];
  totalCurrentPeriod: number;
  totalPreviousPeriod: number;
  percentageVariation: number;
};

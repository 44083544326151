import { colors } from 'doit-bho-admin/src/theme/colors';
import { useCopyLinkController } from 'doit-bho-volunteer/src/components/CopyLinkActivity/useCopyLinkController';
import { Box, HStack, Input, Stack, Text } from 'native-base';
import React from 'react';
import Button from 'ui/components/Button';

import { ICopyInviteLinkProps } from './types';

export const CopyInviteLinkComponent = ({ link }: ICopyInviteLinkProps) => {
  const { handleCopyLink } = useCopyLinkController();

  if (!link) return null;

  return (
    <>
      <Box h="8" />
      <Text mb="3" fontSize="md" fontWeight="medium" color="gray.600">
        Invite by link
      </Text>
      <HStack alignItems="center" space={5} w="full">
        <Stack width="80%">
          <Input
            value={link}
            w="100%"
            style={{ textDecorationStyle: 'dashed' }}
            editable={false}
            isDisabled
          />
        </Stack>
        <Button
          flex="1"
          borderColor="primary.600"
          leftIconName="copy"
          leftIconColor={colors.green[600]}
          py="6"
          variant="outline"
          onPress={() => handleCopyLink(link)}
          minW="16%"
          maxW="16%"
        >
          <Text color="primary.600" fontSize="md" fontWeight="500">
            Copy
          </Text>
        </Button>
      </HStack>
    </>
  );
};

import { useCallback, useMemo, useRef } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { useCurrentOrganization } from '~/hooks/organization/useCurrentOrganization';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { colors } from '~/theme/colors';

import { getMenuItems } from './utils';

const useDrawerController = () => {
  const modalSignOutRef = useRef<IModalRefProps>(null);

  const { isNFP } = useCurrentOrganization();
  const { goToRoute, location } = useRouter();
  const { organizations, organizationSelectedId } = useAppSelector(
    ({ auth }) => auth,
  );

  const isActiveMenu = useCallback(
    (pathName: string) => {
      return pathName === '/'
        ? pathName === location.pathname
        : location.pathname.includes(pathName);
    },
    [location.pathname],
  );

  const currentOrganization = useMemo(() => {
    return organizations.find((org) => org._id === organizationSelectedId);
  }, [organizationSelectedId, organizations]);

  const getStyles = useCallback(
    (pathName: string) => {
      return {
        text: isActiveMenu(pathName) ? 'primary.600' : 'gray.600',
        icon: isActiveMenu(pathName) ? colors.primary[600] : colors.gray[600],
        bgColor: isActiveMenu(pathName) ? 'primary.50' : '',
      };
    },
    [isActiveMenu],
  );

  const menuItems = useMemo(() => {
    return getMenuItems({ isNFP });
  }, [isNFP]);

  return {
    menuItems,
    currentOrganization,
    modalSignOutRef,
    goToRoute,
    getStyles,
  };
};

export default useDrawerController;

import { AxiosResponse } from 'axios';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ImageZoneImageItem } from 'ui/components/Dropzone/ImageZoneMini/types';
import { ITabsRefProps } from 'ui/components/Tabs/types';
import {
  EActivityAttendanceTypes,
  EActivityLocationTypeEnum,
  ESpaceOptionsKeys,
  ETypeOfWorkKeys,
  LocationOptionsEnum,
} from 'ui/enums';
import { ActivityType } from 'ui/types/activities';
import { ValidationsRegex } from 'ui/utils/validations/validations';

import { DEFAULT_MEASUREMENT_UNIT_TIME_ID } from '~/config';
import { OPPORTUNITY_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { ActivityTypeEnum } from '~/enums';
import { useActivityCategoryOptions } from '~/hooks/useActivityCategoriesOptions';
import { useAppSelector } from '~/hooks/useAppSelector';
import { usePermissions } from '~/hooks/usePermissions';
import { useRouter } from '~/hooks/useRouter';
import ActivitiesService from '~/services/resources/activities';
import ActivityDefinitionService from '~/services/resources/activityDefinition';
import {
  IActivityCategoryDTO,
  IAddressDTO,
  ICreateActivityDTO,
} from '~/types/dtos';
import { IActivity } from '~/types/interfaces/activity';
import {
  generateCarouselImagesURLsArrayForFiles,
  handleActivityCoverImage,
} from '~/utils/activitiesImages';
import { transformHoursToSeconds } from '~/utils/functions';

import { OpprtunityFormTabPages } from './types';

export const useOpportunityFormController = () => {
  const tabsRef = useRef<ITabsRefProps>(null);
  const [formData, setFormData] = useState<ICreateActivityDTO>({
    spaceOptions: ESpaceOptionsKeys.Indoor,
  } as ICreateActivityDTO);

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  const { replaceRoute } = useRouter();
  const [currentTab, setCurrentTab] = useState(OpprtunityFormTabPages.About);
  const [isLoading, setIsLoading] = useState(false);

  const { filterAppsForActivityCreation } = usePermissions();

  const location = useLocation();
  const navigate = useNavigate();

  const { control } = useForm<ICreateActivityDTO>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });

  const {
    requirementOptions,
    causeOptions,
    requirementOptionsData,
    causeOptionsData,
  } = useActivityCategoryOptions();

  const filteredAppsByPermissions = filterAppsForActivityCreation(
    ActivityType.Ongoing,
    false,
  );

  const handleCreateOpportunityForSingleLocation = async (
    activityDefinitionId: string,
    data: ICreateActivityDTO,
  ) => {
    const { address, noVolunteerLimit, volunteerNumber, selectedApp } = data;
    try {
      let normalizedAddress = {};
      if (address?.lat) {
        normalizedAddress = {
          street: address.rawLocation || '',
          location: {
            type: 'Point',
            coordinates: [address.lng, address.lat],
          },
        };
      } else {
        normalizedAddress = {
          street: address?.street || '',
          location: {
            type: address?.location?.type || 'Point',
            coordinates:
              [
                address?.location?.coordinates[0],
                address?.location?.coordinates[1],
              ] || [],
          },
        };
      }
      if (!normalizedAddress) throw Error('Incorrect Address');
      if (data.externalApplyLink) {
        if (!ValidationsRegex.Url.test(encodeURI(data.externalApplyLink))) {
          toast.error(
            'External Apply Link should be a valid full url! Only https is allowed. E.g: https://example.com',
          );
          throw Error();
        }
      }

      const normalizedPayloadForActivity: Partial<ICreateActivityDTO> = {
        activityDefinition: activityDefinitionId,
        volunteerNumber: !noVolunteerLimit ? volunteerNumber : null,
        organization: organizationSelectedId || '',
        app: selectedApp?.[0],
        ecosystem: selectedEcosystem?._id,
        isVolunteerNumberLimited: !noVolunteerLimit,
        publishedApps: data.publishedApps,
        address: normalizedAddress,
        externalApplyLink: encodeURI(data.externalApplyLink || '') || undefined,
      };
      await ActivitiesService.createActivityOpportunity(
        normalizedPayloadForActivity,
      );
    } catch (error) {
      toast.error(OPPORTUNITY_MESSAGES.CREATE_OPPORTUNITY_ERROR);
    }
  };

  const handleCreateOpportunityForMultipleLocations = async (
    activityDefinitionId: string,
    data: ICreateActivityDTO,
  ) => {
    const { selectedApp } = data;
    const payloads: Promise<AxiosResponse<IActivity>>[] = [];
    if (data.locationsGroups) {
      for (const [index, date] of data.locationsGroups?.entries()) {
        if (data.locationsByGroup && data.locationsByGroup[index]) {
          for (const locationData of data.locationsByGroup[index]?.values()) {
            if (!locationData.location) return;
            try {
              const normalizedAddress: IAddressDTO = {
                street: locationData.returnedAddress,
                rawLocation: locationData.returnedAddress,
                location: {
                  type: 'Point',
                  coordinates: [
                    locationData.location.lng,
                    locationData.location.lat,
                  ],
                },
              };

              if (date.externalApplyLink) {
                if (
                  !ValidationsRegex.Url.test(encodeURI(date.externalApplyLink))
                ) {
                  toast.error(
                    'External Apply Link should be a valid full url! Only https is allowed. E.g: https://example.com',
                  );
                  throw Error();
                }
              }

              const normalizedPayloadForActivity = {
                organization: organizationSelectedId || '',
                ecosystem: selectedEcosystem?._id,
                activityDefinition: activityDefinitionId,
                address: normalizedAddress,
                volunteerNumber: date.volunteerNumber
                  ? Number(date.volunteerNumber)
                  : undefined,
                isVolunteerNumberLimited: !date.noVolunteerLimit,
                app: selectedApp?.[0],
                externalApplyLink:
                  encodeURI(date.externalApplyLink || '') || undefined,
              };
              payloads.push(
                ActivitiesService.createActivityOpportunity(
                  normalizedPayloadForActivity,
                ),
              );
            } catch (error) {
              toast.error(OPPORTUNITY_MESSAGES.CREATE_OPPORTUNITY_ERROR);
            }
          }
        }
      }
    } else {
      throw OPPORTUNITY_MESSAGES.CREATE_OPPORTUNITY_ERROR;
    }

    await Promise.all(payloads);
  };
  const handleCreateOpportunityForFromHome = async (
    acitivityDefinitionId: string,
    data: ICreateActivityDTO,
  ) => {
    const { selectedApp } = data;
    try {
      // TODO: this should be at a 'constants file' elsewhere
      const defaultUKCoordinates = {
        lat: 54.092806,
        lng: -1.994255,
      };
      // Can't have blank address for Ongoing Opportunity
      const normalizedAddress = {
        street: data.meetingLink ? data.meetingLink : 'From Home',
        location: {
          type: 'Point',
          coordinates: [
            data.regions?.[0].geocenterLocation.lon || defaultUKCoordinates.lng,
            data.regions?.[0].geocenterLocation.lat || defaultUKCoordinates.lat,
          ],
        },
      };

      if (data.externalApplyLink) {
        if (!ValidationsRegex.Url.test(encodeURI(data.externalApplyLink))) {
          toast.error(
            'External Apply Link should be a valid full url! Only https is allowed. E.g: https://example.com',
          );
          throw Error();
        }
      }

      const basePayload: Partial<ICreateActivityDTO> = {
        activityDefinition: acitivityDefinitionId,
        volunteerNumber: null,
        organization: organizationSelectedId || '',
        app: selectedApp?.[0],
        ecosystem: selectedEcosystem?._id,
        isVolunteerNumberLimited: false,
        publishedApps: data.publishedApps,
        address: normalizedAddress as IAddressDTO,
        regions: data.regions,
        isOnline: !!data.meetingLink,
        meetingLink: data.meetingLink || undefined,
        externalApplyLink: encodeURI(data.externalApplyLink || '') || undefined,
      };

      const normalizedPayloadForActivity = data.meetingLink
        ? { ...basePayload, meetingLink: data.meetingLink }
        : basePayload;

      await ActivitiesService.createActivityOpportunity(
        normalizedPayloadForActivity,
      );
    } catch (error) {
      toast.error(OPPORTUNITY_MESSAGES.CREATE_OPPORTUNITY_ERROR);
    }
  };

  const createOpportunity = async (data: ICreateActivityDTO) => {
    try {
      setIsLoading(true);
      const causeOptions = causeOptionsData?.data.filter(
        (cause: IActivityCategoryDTO) => data.cause?.includes(cause._id),
      );

      let requirementOptions: IActivityCategoryDTO[] | undefined;

      if (data.requirementOptions?.length) {
        requirementOptions = requirementOptionsData?.data?.filter(
          ({ _id }: { _id: string }) => {
            // @mycatdoitbetter TODO: Fix this, this is a really bad way to do this
            // the data.requirementOptions is a string[] and the _id is a string...
            const requirementOptions =
              data.requirementOptions as unknown as string[];

            return requirementOptions.find(
              (requirementOption) => requirementOption === _id,
            );
          },
        );
      }

      const { coverImageURL, thumbnailImageURL } =
        await handleActivityCoverImage(
          data.coverImage,
          data.thumbnailImage as string,
        );

      const carouselImagesURLs = await generateCarouselImagesURLsArrayForFiles(
        data.carouselImages as ImageZoneImageItem[],
      );

      const app = Array.isArray(data.selectedApp) ? data.selectedApp[0] : '';
      if (!app) {
        throw OPPORTUNITY_MESSAGES.CREATE_OPPORTUNITY_ERROR;
      }

      const activityDefinitionPayload: Partial<ICreateActivityDTO> = {
        ...data,
        title: data.title,
        description: data.description,
        eventType: EActivityLocationTypeEnum.National,
        coverImage: coverImageURL,
        carouselImages: carouselImagesURLs,
        app: app as string,
        ecosystem: selectedEcosystem?._id,
        volunteerRewards: data.volunteerRewards,
        volunteerRequirements: data.volunteerRequirements,
        type: ActivityTypeEnum.OngoingOpportunity,
        organization: organizationSelectedId,
        spaceOptions: data.spaceOptions,
        typeOfWork: ETypeOfWorkKeys.Both,
        causeOptions,
        requirementOptions: requirementOptions,
        meetingLink: data.meetingLink || undefined,
        publishedApps: data.publishedApps,
        locationOption: data.locationOption,
        //Attendance Type is required for Ongoing Opportunity for now
        attendanceType:
          data.locationOption === LocationOptionsEnum.FromHome
            ? EActivityAttendanceTypes.Remote
            : EActivityAttendanceTypes.InPerson,
        thumbnailImage: thumbnailImageURL,
      };

      if (data.targetAmount) {
        activityDefinitionPayload['targetAmount'] = transformHoursToSeconds(
          data.targetAmount as number,
        );
        activityDefinitionPayload['measurementUnit'] =
          DEFAULT_MEASUREMENT_UNIT_TIME_ID;
      }

      if (
        data.locationOption === LocationOptionsEnum.FromHome &&
        data.meetingLink
      ) {
        if (!ValidationsRegex.Url.test(encodeURI(data.meetingLink))) {
          toast.error(
            'Meeting link should be a valid full url! Only https is allowed. E.g: https://example.com',
          );
          throw Error();
        }
      }

      const { data: activityDefinitionData } =
        await ActivityDefinitionService.createOpportunityActivityDefininition({
          ...activityDefinitionPayload,
        });

      const { locationOption } = data;

      switch (locationOption) {
        case LocationOptionsEnum.SingleLocation: {
          await handleCreateOpportunityForSingleLocation(
            activityDefinitionData._id,
            data,
          );
          break;
        }
        case LocationOptionsEnum.MultipleLocations: {
          await handleCreateOpportunityForMultipleLocations(
            activityDefinitionData._id,
            data,
          );
          break;
        }
        case LocationOptionsEnum.FromHome: {
          await handleCreateOpportunityForFromHome(
            activityDefinitionData._id,
            data,
          );
          break;
        }
        default:
          break;
      }
      replaceRoute(`${PAGES.ACTIVITIES}`);
      setTimeout(() => {
        replaceRoute(`${PAGES.ADD_ONGOING_OPPORTUNITY_SUCCESS}`, {
          state: {
            locationForActivitySuccessful: location,
            activity: activityDefinitionData,
          },
        });
      }, 500);
    } catch (error) {
      toast.error(OPPORTUNITY_MESSAGES.CREATE_OPPORTUNITY_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleContinue = (data: ICreateActivityDTO) => {
    setFormData((prev: ICreateActivityDTO) => ({ ...prev, ...data }));

    if (tabsRef.current?.currentTabIndex !== 2) {
      tabsRef.current?.nextStep();
      setCurrentTab((prev) => prev + 1);
    } else {
      createOpportunity({ ...formData, ...data });
    }
  };

  const handleBack = () => {
    if (
      tabsRef.current?.currentTabIndex &&
      tabsRef.current?.currentTabIndex > 0
    ) {
      tabsRef.current?.backStep();
      setCurrentTab((prev) => prev - 1);
    } else {
      navigate(-1);
    }
  };

  const handleUpdateLocationOption = (locationOption: LocationOptionsEnum) => {
    setFormData((prev: ICreateActivityDTO) => ({ ...prev, locationOption }));
  };

  return {
    tabsRef,
    currentTab,
    causeOptions,
    requirementOptions,
    handleContinue,
    handleBack,
    isLoading,
    formData,
    control,
    filteredAppsByPermissions,
    handleUpdateLocationOption,
  };
};

import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { VStack } from 'native-base';
import {
  IColumn,
  TableOperatorNames,
  TableOperatorType,
} from 'ui/components/DataTable/types';
import DataTableActions from 'ui/components/DataTableActions';
import Tag from 'ui/components/Tags/Tag';
import { formattedDate } from 'ui/utils/formatter';

import { EImportFileType } from '~/pages/Authenticated/BulkUploads/types';
import { colors } from '~/theme/colors';

export const defaultFilterValue = [
  {
    name: 'name',
    operator: TableOperatorNames.StartsWith,
    type: TableOperatorType.String,
    value: '',
  },
  {
    name: 'type',
    operator: TableOperatorNames.Equals,
    type: TableOperatorType.Select,
    filterEditor: SelectFilter,
    value: '',
  },
];

export const makeColumns = ({
  onPressViewButton,
}: {
  onPressViewButton: any;
}): IColumn[] => [
  {
    name: 'importFileType',
    header: 'Type',
    defaultFlex: 1,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: false,
      wrapMultiple: false,
      dataSource: [
        EImportFileType.ActivityDefinition,
        EImportFileType.Activity,
      ].map((fileImportType) => {
        return { id: fileImportType, label: fileImportType };
      }),
    },
    render: ({ data: { importFileType } }) => (
      <VStack width="85px">
        <Tag
          text={
            importFileType === EImportFileType.ActivityDefinition
              ? 'Activities'
              : 'Places'
          }
          tagTextColor={colors.darkText}
          tagBgColor={
            importFileType === EImportFileType.ActivityDefinition
              ? colors.info['100']
              : colors.info['50']
          }
          showIcon
          icon={
            importFileType === EImportFileType.ActivityDefinition
              ? 'file-text'
              : 'map-pin'
          }
        />
      </VStack>
    ),
  },
  {
    name: 'key',
    defaultFlex: 2,
    header: 'File name',
    render: ({ data }) => data.key || '-',
  },
  {
    name: 'createdAt',
    header: 'Importation Date',
    defaultFlex: 3,
    filterEditor: DateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'YYYY-MM-DD',
        cancelButton: false,
        highlightWeekends: false,
      };
    },
    render: ({ data }) =>
      data.createdAt ? formattedDate(data.createdAt, 'dd/MM/yyyy') : '-',
  },
  {
    name: 'actions',
    header: 'Actions',
    defaultFlex: 0.8,
    render: ({ data }) => (
      <DataTableActions
        viewButton
        color={colors.primary['600']}
        onPressViewButton={() => onPressViewButton(data)}
      />
    ),
  },
];

import DataTableActions from 'ui/components/DataTableActions';
import { TableCellWithImage } from 'ui/components/TableCellWithImage';

import { colors } from '~/theme/colors';
import { TableColumnsFunctions } from '~/types';
import { PartnerOrganizationTable } from '~/types/interfaces/table';

export const tableColumns = (
  { onView }: TableColumnsFunctions<string>,
  organizationId: string,
) => [
  {
    name: 'organization',
    header: 'Organisation',
    defaultFlex: 1,
    render: ({ data }: PartnerOrganizationTable) => {
      return (
        <>
          {data?.organizationOne?._id === organizationId ? (
            <TableCellWithImage
              logo={data?.organizationTwo?.logo}
              name={data?.organizationTwo.name}
            />
          ) : (
            <TableCellWithImage
              logo={data?.organizationOne?.logo}
              name={data?.organizationOne.name}
            />
          )}
        </>
      );
    },
  },
  {
    name: 'location',
    header: 'Location',
    defaultFlex: 1,
    render: ({ data }: PartnerOrganizationTable) => {
      return data?.organizationOne?._id === organizationId
        ? data?.organizationTwo?.fullAddress?.street
        : data?.organizationOne?.fullAddress?.street;
    },
  },

  {
    name: 'actions',
    header: 'Actions',
    defaultFlex: 0.5,
    render: ({ data }: PartnerOrganizationTable) => {
      const popoverOptions = [
        {
          icon: {
            color: colors.gray[600],
            icon: 'eye',
            size: 22,
          },
          title: 'View partner',
          action: () => {
            if (typeof onView !== 'function') return;
            if (data?.organizationOne?._id === organizationId) {
              onView(data?.organizationTwo._id, data._id);
            } else {
              onView(data?.organizationOne._id, data._id);
            }
          },
        },
      ];

      return (
        <DataTableActions
          popoverActions
          popoverTextColor={colors.primary[400]}
          popoverIconColor={colors.primary[400]}
          popoverOptions={popoverOptions}
        />
      );
    },
  },
];

import { useEffect, useState } from 'react';
import { TitleNode } from 'ui/components/v2/Cards/NotificationCard/BaseNotificationCard/types';
import {
  getActivityRoute,
  makeOrganizationTitleNode,
} from 'ui/components/v2/Cards/NotificationCard/utils';

import { ActivityCreatedNotificationCardProps } from './types';

export const useActivityCreatedNotificationCardController = (
  props: ActivityCreatedNotificationCardProps,
) => {
  const {
    date,
    activityAppName,
    organizationId,
    organizationName,
    organizationLogoUrl,
    activityStartDate,
    activityType,
    activityDefinitionId,
  } = props;

  const [titleNodes, setTitleNodes] = useState<TitleNode[]>([]);

  useEffect(() => {
    const authorTileNode = makeOrganizationTitleNode(
      organizationId,
      organizationName,
    );

    setTitleNodes([
      authorTileNode,
      { text: 'published a new activity on' },
      { text: activityAppName, fontWeight: 500 },
    ]);
  }, [organizationId, organizationName, activityAppName]);

  const onActivityPress = () => {
    const route = getActivityRoute(activityDefinitionId, activityType);
    window.open(route, '_blank');
  };

  return {
    date,
    titleNodes,
    organizationName,
    activityStartDate,
    organizationLogoUrl,
    onActivityPress,
  };
};

import { HStack, Stack, Text } from 'native-base';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import { colors } from 'ui/theme/colors';

export const SwitchEcosystemFooter = () => {
  return (
    <Stack
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="auto"
      marginBottom={10}
    >
      <HStack mt={10} space={2} alignItems="center">
        <Text fontWeight={500} color="gray.400">
          Powered by
        </Text>
        <Illustration
          name={ILLUSTRATIONS_NAME.DOIT_TRANSPARENT_LOGO}
          width={50}
          height={25}
          tintColor={colors.gray[400]}
        />
      </HStack>
    </Stack>
  );
};

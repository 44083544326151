import './index.css';

import { VStack } from 'native-base';
import { useState } from 'react';

import { FavoriteCauseActivitiesDoneTab } from '~/pages/Authenticated/Insights/components/FavoriteCauses/FavoriteCauseActivitiesDoneTab';
import { FavoriteCauseHoursTab } from '~/pages/Authenticated/Insights/components/FavoriteCauses/FavoriteCauseHoursTab';
import {
  InsightScaffold,
  InsightScaffoldChildrenReactComponent,
} from '~/pages/Authenticated/Insights/components/InsightScaffold';
import {
  InsightTabOption,
  InsightTabs,
} from '~/pages/Authenticated/Insights/components/InsightTabs';

import { FavoriteCausesProps } from './types';
import { INSIGHT_TABS, insightTabList } from './utils';

export const FavoriteCausesTabs: InsightScaffoldChildrenReactComponent = ({
  onSetChart,
}) => {
  const [selectedTab, setSelectedTab] = useState<InsightTabOption>(
    insightTabList?.[0],
  );

  const renderTab = () => {
    switch (selectedTab.value) {
      case INSIGHT_TABS.HOURS:
        return <FavoriteCauseHoursTab onSetChart={onSetChart} />;
      case INSIGHT_TABS.ACTIVITIES_DONE:
        return <FavoriteCauseActivitiesDoneTab onSetChart={onSetChart} />;
      default:
        return null;
    }
  };

  return (
    <VStack space={4} mx={6} mb={6} flex={1}>
      <InsightTabs onChange={setSelectedTab} options={insightTabList} mb={6} />
      {renderTab()}
    </VStack>
  );
};

export const FavoriteCauses = ({ className }: FavoriteCausesProps) => {
  return (
    <InsightScaffold
      className={className}
      title={{
        icon: 'heart',
        name: 'Farovite Causes',
      }}
    >
      {FavoriteCausesTabs}
    </InsightScaffold>
  );
};

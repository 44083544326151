import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ImageZoneImageItem } from 'ui/components/Dropzone/ImageZoneMini/types';
import { ITabsRefProps } from 'ui/components/Tabs/types';
import { ActivityType } from 'ui/types/activities';
import { ValidationsRegex } from 'ui/utils/validations/validations';

import { ACTION_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { ActivityTypeEnum, MeasurementUnitsCategory } from '~/enums';
import { useActivityCategoryOptions } from '~/hooks/useActivityCategoriesOptions';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useGetMeasurementUnits } from '~/hooks/useGetMeasurementUnits';
import { usePermissions } from '~/hooks/usePermissions';
import { useRouter } from '~/hooks/useRouter';
import ActivitiesService from '~/services/resources/activities';
import ActivityDefinitionService from '~/services/resources/activityDefinition';
import MeasurementUnitsService from '~/services/resources/measurmentUnits';
import {
  CreateCustomUnitPayload,
  IActivityCategoryDTO,
  ICreateActivityDTO,
} from '~/types/dtos';
import {
  generateCarouselImagesURLsArrayForFiles,
  handleActivityCoverImage,
} from '~/utils/activitiesImages';
import { getMeasurementUnit, transformHoursToSeconds } from '~/utils/functions';

import { ActionFormTabsPages } from './types';

export const useActionFormController = () => {
  const tabsRef = useRef<ITabsRefProps>(null);

  const [formData, setFormData] = useState<ICreateActivityDTO>(
    {} as ICreateActivityDTO,
  );

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  const [currentTab, setCurrentTab] = useState(ActionFormTabsPages.About);
  const [isLoading, setIsLoading] = useState(false);

  const { filterAppsForActivityCreation } = usePermissions();

  const { replaceRoute } = useRouter();
  const location = useLocation();
  const navigate = useNavigate();

  const { control, setValue, setError } = useForm<ICreateActivityDTO>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });

  const { causeOptions, causeOptionsData } = useActivityCategoryOptions();

  const filteredAppsByPermissions = filterAppsForActivityCreation(
    ActivityType.Action,
    false,
  );

  const { measurementUnits, refetchCustomUnits } = useGetMeasurementUnits();

  const handleContinue = (data: ICreateActivityDTO) => {
    if (data.measurementUnit) {
      const measurementUnit = Object.values(measurementUnits)
        .flatMap((measurmentUnit) => measurmentUnit)
        .find(
          (measurementUnit) => measurementUnit._id === data.measurementUnit,
        );

      if (
        measurementUnit?.category !== MeasurementUnitsCategory.Completion &&
        !data.targetAmount
      ) {
        setError('targetAmount', {
          message: 'The field is required',
          type: 'required',
        });

        return;
      }

      setValue('measurementUnitPluralLabel', measurementUnit?.pluralLabel);
    }

    setFormData((prev: ICreateActivityDTO) => ({ ...prev, ...data }));
    if (tabsRef.current?.currentTabIndex !== 2) {
      tabsRef.current?.nextStep();
      setCurrentTab((prev) => prev + 1);
    } else {
      createAction({ ...formData, ...data });
    }
  };

  const handleBack = () => {
    if (
      tabsRef.current?.currentTabIndex &&
      tabsRef.current?.currentTabIndex > 0
    ) {
      tabsRef.current?.backStep();
      setCurrentTab((prev) => prev - 1);
    } else {
      navigate(-1);
    }
  };

  const handleCreateMeasurementUnitStep = async (
    payload: CreateCustomUnitPayload,
  ) => {
    try {
      const { data } = await MeasurementUnitsService.createCustomUnit(payload);

      return data;
    } catch (error) {
      console.log('error', error);
    }
  };

  const onAddedCustomUnit = () => {
    refetchCustomUnits();
  };

  const createAction = async (data: ICreateActivityDTO) => {
    try {
      setIsLoading(true);
      const causeOptions = causeOptionsData?.data.filter(
        (cause: IActivityCategoryDTO) => data.cause?.includes(cause._id),
      );

      if (data.measurementUnit) {
        const measurementUnit = getMeasurementUnit(
          measurementUnits,
          data.measurementUnit,
        );
        if (
          measurementUnit?.category === MeasurementUnitsCategory.Time &&
          data.targetAmount
        ) {
          data.targetAmount = transformHoursToSeconds(
            Number(data.targetAmount),
          );
        }

        data.measurementUnitPluralLabel = measurementUnit?.pluralLabel;

        if (
          measurementUnit?.category === MeasurementUnitsCategory.Completion &&
          organizationSelectedId &&
          data?.steps?.length
        ) {
          const measurementCreated = await handleCreateMeasurementUnitStep({
            organization: organizationSelectedId,
            pluralLabel: 'Steps',
            singularLabel: 'Step',
            steps: data.steps,
          });

          data.measurementUnit = measurementCreated._id;
        }
      }

      const { coverImageURL, thumbnailImageURL } =
        await handleActivityCoverImage(
          data.coverImage,
          data.thumbnailImage as string,
        );

      const carouselImagesURLs = await generateCarouselImagesURLsArrayForFiles(
        data.carouselImages as ImageZoneImageItem[],
      );

      const app = Array.isArray(data.selectedApp) ? data.selectedApp[0] : '';
      if (!app) {
        throw ACTION_MESSAGES.CREATE_ACTION_ERROR;
      }

      const activityDefinitionPayload: Partial<ICreateActivityDTO> = {
        ...data,
        title: data.title,
        description: data.description,
        coverImage: coverImageURL,
        carouselImages: carouselImagesURLs,
        app: app,
        ecosystem: selectedEcosystem?._id,
        type: ActivityTypeEnum.Action,
        organization: organizationSelectedId,
        causeOptions,
        meetingLink: encodeURI(data.meetingLink || ''),
        targetAmount: data.targetAmount || 0,
        externalApplyLink: undefined,
        thumbnailImage: thumbnailImageURL,
      };

      if (data.meetingLink) {
        if (!ValidationsRegex.Url.test(encodeURI(data.meetingLink))) {
          toast.error(
            'Meeting link should be a valid full url! Only https is allowed. E.g: https://example.com',
          );
          throw Error();
        }
      }

      if (data.externalApplyLink) {
        if (!ValidationsRegex.Url.test(encodeURI(data.externalApplyLink))) {
          toast.error(
            'External apply link should be a valid full url! Only https is allowed. E.g: https://example.com',
          );
          throw Error();
        }
      }

      const { data: activityDefinitionData } =
        await ActivityDefinitionService.createActionActivityDefinition({
          ...activityDefinitionPayload,
          app: app,
          ecosystem: selectedEcosystem?._id,
        });

      const payload: Partial<ICreateActivityDTO> = {
        activityDefinition: activityDefinitionData._id,
        organization: organizationSelectedId || '',
        app: app,
        ecosystem: selectedEcosystem?._id,
        regions: data.regions,
        isOnline: data.meetingLink ? true : false,
        meetingLink: data.meetingLink || undefined,
        externalApplyLink: encodeURI(data.externalApplyLink || '') || undefined,
      };

      await ActivitiesService.createActivityAction(payload);

      replaceRoute(`${PAGES.ACTIVITIES}`);
      setTimeout(() => {
        replaceRoute(`${PAGES.ADD_ACTION_SUCCESS}`, {
          state: {
            locationForActivitySuccessful: location,
            activity: activityDefinitionData,
          },
        });
      }, 500);
    } catch (error) {
      toast.error(ACTION_MESSAGES.CREATE_ACTION_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    tabsRef,
    currentTab,
    causeOptions,
    handleContinue,
    handleBack,
    isLoading,
    formData,
    control,
    onAddedCustomUnit,
    filteredAppsByPermissions,
  };
};

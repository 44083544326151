import { StatusCodes } from 'http-status-codes';
import { useClipboard } from 'native-base';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IFilter } from 'ui/components/DataTable/types';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { ITabsRefProps } from 'ui/components/Tabs/types';
import {
  AdvertiseRegionOptionsEnum,
  EEventApplicationType,
  GenericFormTabs,
  LocationOptionsEnum,
} from 'ui/enums';
import { ActivityRegion, ActivityType } from 'ui/types/activities';
import {
  getActivityAdvertiseOption,
  mapActivityRegionsForLocationDetails,
} from 'ui/utils/activityRegions';
import { getPublishPermissionForActivityType } from 'ui/utils/permissions';

import { VITE_DOIT_VOLUNTEER_URL } from '~/config';
import { COMMON_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { ActivityTypeEnum, MeasurementUnitsCategory } from '~/enums';
import { useActivityRegions } from '~/hooks/useActivityRegions';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useGetMeasurementUnits } from '~/hooks/useGetMeasurementUnits';
import { usePermissions } from '~/hooks/usePermissions';
import { useQuery } from '~/hooks/useQuery';
import { useRouter } from '~/hooks/useRouter';
import ActivityService from '~/services/resources/activity';
import ActivityDefinitionService from '~/services/resources/activityDefinition';
import { IActivity, IActivityDefinition } from '~/types/interfaces/activity';
import { MeasurementUnit } from '~/types/interfaces/measurementUnit';

import { tabElements } from './tabElements';
import { ActivityDetailsDetailsTabPages, ActivityDetailsProps } from './types';
import { returnActivityTypeTextWithEnumType } from './utils';

export const useActivityDetailsController = ({
  activityType,
}: ActivityDetailsProps) => {
  const tabsRef = useRef<ITabsRefProps>(null);
  const modalRef = useRef<IModalRefProps>(null);

  const [currentTab, setCurrentTab] = useState<ActivityDetailsDetailsTabPages>(
    ActivityDetailsDetailsTabPages.About,
  );

  const [isLoadingActivity, setIsLoadingActivity] = useState(true);
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);

  const [activityDefinition, setActivityDefinition] =
    useState<IActivityDefinition>({} as IActivityDefinition);
  const [activities, setActivities] = useState<IActivity[]>([]);
  const [regions, setRegions] = useState<ActivityRegion[] | null>();
  const [meetingLink, setMeetingLink] = useState('');
  const [measurementUnitId, setMeasurementUnitId] = useState<string>();

  const [advertiseRegionOption, setAdvertiseRegionOption] =
    useState<AdvertiseRegionOptionsEnum>(
      AdvertiseRegionOptionsEnum.AnywhereInUK,
    );

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);

  const { validateIfOrganizationIsAppOwner } = usePermissions();
  const isOrganizationOwnerOfActivity =
    organizationSelectedId === activityDefinition?.organization;

  const activityTypeText = returnActivityTypeTextWithEnumType(activityType);

  const appId = activityDefinition?.app;

  const locationOption = activityDefinition.locationOption;

  const activityPermissions = getPublishPermissionForActivityType({
    activityType: activityDefinition?.type as ActivityType,
    isTeamEvent:
      activityDefinition?.eventApplicationType === EEventApplicationType.Team,
  });
  const isAppAdmin = validateIfOrganizationIsAppOwner(appId);

  const {
    goToRoute,
    params: { id: activityId },
  } = useRouter();

  const activityUrl = `${VITE_DOIT_VOLUNTEER_URL}my-wallet/activities/${activityId}`;
  const { onCopy } = useClipboard();

  const { data: measurementUnitObject, isLoading: isLoadingUnit } =
    useQuery<MeasurementUnit>(`/measurement-units/${measurementUnitId}`, {
      queryOptions: {
        enabled: !!measurementUnitId,
      },
    });

  const { measurementUnits } = useGetMeasurementUnits();

  const { regionResponse, isLoadingRegions } = useActivityRegions();

  const hasMeasurements = useMemo(() => {
    if (Object.values(measurementUnits).length > 0) {
      return true;
    }

    return false;
  }, [measurementUnits]);

  const handleChangeTab = (tab: number) => {
    setCurrentTab(tab);
  };

  const handleEditActivity = (tabId?: GenericFormTabs) => {
    switch (activityType) {
      case ActivityTypeEnum.Event:
        if (tabId) {
          return goToRoute(`${PAGES.EDIT_EVENT}/${activityId}?tabId=${tabId}`);
        }
        return goToRoute(`${PAGES.EDIT_EVENT}/${activityId}`);
      case ActivityTypeEnum.OngoingOpportunity:
        if (tabId) {
          return goToRoute(
            `${PAGES.EDIT_ONGOING_OPPORTUNITY}/${activityId}?tabId=${tabId}`,
          );
        }
        return goToRoute(`${PAGES.EDIT_ONGOING_OPPORTUNITY}/${activityId}`);
      case ActivityTypeEnum.Action:
        if (tabId) {
          return goToRoute(`${PAGES.EDIT_ACTION}/${activityId}?tabId=${tabId}`);
        }
        return goToRoute(`${PAGES.EDIT_ACTION}/${activityId}`);
      default:
        break;
    }
  };

  const handleDelete = async () => {
    try {
      if (!activityId) return;
      setIsLoadingActivity(true);
      await ActivityDefinitionService.deleteOne(activityId);
      toast.success(COMMON_MESSAGES.CANCEL_ACTIVITY_SUCCESS);
      modalRef.current?.close();
      goToRoute(PAGES.ACTIVITIES);
    } catch (error) {
      toast.error(COMMON_MESSAGES.CANCEL_ACTIVITY_ERROR);
    } finally {
      setIsLoadingActivity(false);
    }
  };

  const handleShare = () => {
    onCopy(activityUrl);
    toast.success('Link copied to clipboard');
  };

  const handleCloseModal = () => {
    modalRef.current?.close();
  };

  const handleOpenModal = () => {
    modalRef.current?.open();
  };

  const loadActivitiesList = useCallback(
    async (skip: number, limit: number, filter: IFilter) => {
      if (activityId && hasMeasurements) {
        try {
          const {
            data: { data, count },
          } = await ActivityService.findAll({
            skip,
            limit: 5,
            filter: JSON.stringify({
              activityDefinition: activityId,
              ...filter,
            }),
            sort: JSON.stringify({ createdAt: -1 }),
          });
          setActivities(data);
          setRegions(data[0].regions);
          setMeetingLink(data[0].meetingLink);
          setIsLoadingActivities(false);

          return { data, count };
        } catch (err) {
          return [];
        }
      }
    },
    [activityId, hasMeasurements],
  );

  const loadData = useCallback(async () => {
    if (!activityId) return;

    setIsLoadingActivity(true);
    try {
      const { data: activityDefinitionData, status: activityDefinitionStatus } =
        await ActivityDefinitionService.get(activityId as string);

      if (activityDefinitionStatus === StatusCodes.OK) {
        if (activityType !== ActivityTypeEnum.Event) {
          if (
            activityType === ActivityTypeEnum.OngoingOpportunity &&
            activityDefinitionData.targetAmount
          ) {
            setMeasurementUnitId(
              measurementUnits[MeasurementUnitsCategory.Time][0]._id,
            );
          } else {
            setMeasurementUnitId(activityDefinitionData.measurementUnit);
          }
        }

        setActivityDefinition(activityDefinitionData);
      }
    } catch (error: any) {
      console.log({ error });
      throw Error(error);
    } finally {
      setIsLoadingActivity(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityId, measurementUnits]);

  const isLoadingScreen =
    isLoadingActivity || isLoadingActivities || isLoadingUnit;

  useEffect(() => {
    if (activityId && hasMeasurements) {
      loadData();
      if (activityType === ActivityTypeEnum.Action) {
        loadActivitiesList(0, 5, {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityId, hasMeasurements]);

  useEffect(() => {
    if (regionResponse?.data) {
      const advertiseOption = getActivityAdvertiseOption(
        regionResponse.data,
        regions,
      );
      setAdvertiseRegionOption(advertiseOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionResponse, regions]);

  const mappedRegionsForLocationDetails = useMemo(() => {
    if (regionResponse?.data) {
      return mapActivityRegionsForLocationDetails(regionResponse.data, regions);
    }
  }, [regionResponse, regions]);

  const shouldShowLocationInfo = () => {
    const isNotMultipleLocations =
      locationOption === LocationOptionsEnum.SingleLocation ||
      (LocationOptionsEnum.FromHome && meetingLink);

    return isNotMultipleLocations && activities.length > 0;
  };

  const hasExternalLink = useMemo(() => {
    return !!activities.find((activity) => activity.externalApplyLink);
  }, [activities]);

  return {
    tabsRef,
    handleChangeTab,
    tabElements,
    currentTab,
    isLoadingScreen,
    isLoadingActivity,
    activityDefinition,
    activities,
    handleEditActivity,
    loadActivitiesList,
    measurementUnitObject,
    activityId,
    handleDelete,
    modalRef,
    handleCloseModal,
    handleOpenModal,
    activityTypeText,
    handleShare,
    isOrganizationOwnerOfActivity,
    activityUrl,
    appId,
    activityPermissions,
    isAppAdmin,
    locationOption,
    regions,
    mappedRegionsForLocationDetails,
    meetingLink,
    shouldShowLocationInfo,
    advertiseRegionOption,
    hasExternalLink,
  };
};

import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { Stack } from 'native-base';
import { TableCellWithImage } from 'ui/components/TableCellWithImage';
import Tag from 'ui/components/Tags/Tag';
import { ActivityType } from 'ui/types/activities';
import { formattedDate } from 'ui/utils/formatter';

import { ActivityTypeEnum } from '~/enums';
import { colors } from '~/theme/colors';
import {
  iconToShowByActivityType,
  labelTypeEventBgColorByActivityType,
  showCustomIconByActivityTypeAction,
  textToShowByActivityType,
} from '~/utils/functions';

import { EcosystemMemberApplicationTable } from '../../../../../../types/interfaces/table';

// const ActionsColumn = ({ activityId }: { activityId: string }) => {
//   const { goToRoute } = useRouter();

//   return (
//     <DataTableActions
//       viewButton
//       color={colors.primary['600']}
//       onPressViewButton={() => goToRoute(`/activities/${activityId}`)}
//     />
//   );
// };

export const tableActivitiesColumns = [
  {
    filterable: true,
    name: 'applicationDate',
    header: 'Appliation Date',
    defaultFlex: 0.7,
    filterEditor: DateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'YYYY-MM-DD',
        cancelButton: false,
        highlightWeekends: false,
      };
    },
    render: ({ data }: EcosystemMemberApplicationTable) =>
      formattedDate(data.applicationDate, 'dd/MM/yyyy'),
  },
  {
    filterable: true,
    name: 'activitySubDocument.activityDefinitionSubDocument.title',
    header: 'Activity',
    defaultFlex: 1,
    render: ({ data }: EcosystemMemberApplicationTable) =>
      data.activitySubDocument.activityDefinitionSubDocument.title,
  },

  {
    name: 'activitySubDocument.address.street',
    header: 'Activity Location',
    defaultFlex: 1,

    render: ({ data }: EcosystemMemberApplicationTable) => {
      const typesWithAddress = [ActivityType.Event, ActivityType.Ongoing];
      if (
        typesWithAddress.includes(
          data.activitySubDocument.activityDefinitionSubDocument
            .type as ActivityType,
        )
      ) {
        return (
          data.activitySubDocument.address?.street ||
          data.activitySubDocument.meetingLink
        );
      } else {
        return 'From Home';
      }
    },
  },
  {
    name: 'activitySubDocument.startDate',
    header: 'Start Date',
    defaultFlex: 0.7,

    render: ({ data }: EcosystemMemberApplicationTable) =>
      data.activitySubDocument.startDate
        ? formattedDate(data.activitySubDocument.startDate)
        : 'N/A',
  },
  {
    filterable: true,
    name: 'activitySubDocument.activityDefinitionSubDocument.organizationSubDocument.name',
    header: 'Organisation',
    defaultFlex: 1,
    render: ({ data }: EcosystemMemberApplicationTable) => (
      <TableCellWithImage
        name={
          data.activitySubDocument.activityDefinitionSubDocument
            .organizationSubDocument.name || '-'
        }
        logo={
          data.activitySubDocument.activityDefinitionSubDocument
            .organizationSubDocument.logo
        }
      />
    ),
  },
  {
    filterable: true,
    name: 'activitySubDocument.activityDefinitionSubDocument.type',
    header: 'Type',
    defaultFlex: 0.6,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: false,
      wrapMultiple: false,
      dataSource: Object.values(ActivityTypeEnum).map((activityType) => {
        return {
          id: activityType,
          label:
            activityType === ActivityTypeEnum.OngoingOpportunity
              ? 'Ongoing'
              : activityType,
        };
      }),
    },
    render: ({ data }: EcosystemMemberApplicationTable) => (
      // Stack is used to avoid full width tag.
      <Stack>
        <Tag
          text={textToShowByActivityType(
            data.activitySubDocument.activityDefinitionSubDocument
              .type as ActivityTypeEnum,
          )}
          type="actions"
          icon={iconToShowByActivityType(
            data.activitySubDocument.activityDefinitionSubDocument
              .type as ActivityTypeEnum,
          )}
          tagTextColor={colors.darkText}
          tagBgColor={labelTypeEventBgColorByActivityType(
            data.activitySubDocument.activityDefinitionSubDocument
              .type as ActivityTypeEnum,
          )}
          showCustomIcon={showCustomIconByActivityTypeAction(
            data.activitySubDocument.activityDefinitionSubDocument
              .type as ActivityTypeEnum,
          )}
          showIcon
        />
      </Stack>
    ),
  },
  // {
  //   name: 'amount',
  //   header: 'Total log',
  //   defaultFlex: 1,
  // },
  // {
  //   name: 'actions',
  //   header: 'Actions',
  //   defaultFlex: 1,
  //   render: ({ data }: any) => <ActionsColumn activityId={data.activity} />,
  // },
];

export const FAKE_APPS = [
  {
    deleted: false,
    _id: '659351fbea6975704c8df58c',
    name: 'The Big Help Out App',
    description: 'The Big Help Out App',
    visibility: 'public',
    termsWebpage: 'https://thebighelpout.org.uk/terms-and-conditions/',
    ecosystem: '659351f9ea6975704c8df584',
    organization: '659351f8ea6975704c8df57d',
    featureSettings: {},
    features: ['659351faea6975704c8df586'],
    logo: 'https://s3.eu-west-2.amazonaws.com/doit2.0-staging-static/bhoLogo.png',
    defaultAppPermissionGroups: [
      '659351fbea6975704c8df58e',
      '659351feea6975704c8df5ab',
    ],
    createdAt: '2024-01-01T23:59:55.019Z',
    updatedAt: '2024-01-02T00:00:00.649Z',
    __v: 1,
    brandColor: '#0000FF',
    headLine: 'Lend a hand. Make a change.',
  },
  {
    deleted: false,
    _id: '6597092b00be94ae7da7aedc',
    name: 'BHO Business',
    description:
      'Businesses large and small have a crucial part to play in making The Big Help Out a success. Imagine what a difference you could make if all your customers, employees, and partners joined in and lent a hand?',
    visibility: 'public',
    termsWebpage: '',
    ecosystem: '6596e3385cb0dae2c8c4ab2b',
    organization: '6596e07758b0c74e115a15ac',
    featureSettings: {},
    features: [],
    logo: 'https://s3.eu-west-2.amazonaws.com/doit2.0-staging-static/Big_Help_Out_Logo.png',
    defaultAppPermissionGroups: [
      '65bad479bc3b47c7cabb965c',
      '65bad43dbc3b47c7cabb965b',
    ],
    createdAt: '2024-01-04T19:38:19.009Z',
    updatedAt: '2024-01-04T19:38:19.009Z',
    __v: 0,
  },
  {
    deleted: false,
    _id: '65970a7de36460fd4b6583a3',
    name: 'BHO Youth',
    description: 'Come and get your students and staff involved in BHO 24!',
    visibility: 'private',
    termsWebpage: '',
    ecosystem: '6596e3d17670d1dce12472ab',
    organization: '6596e07758b0c74e115a15ac',
    featureSettings: {},
    features: [],
    logo: 'https://s3.eu-west-2.amazonaws.com/doit2.0-staging-static/Big_Help_Out_Logo.png',
    defaultAppPermissionGroups: [],
    createdAt: '2024-01-04T19:43:57.961Z',
    updatedAt: '2024-01-04T19:43:57.961Z',
    __v: 0,
  },
  {
    deleted: false,
    _id: '65970a80e36460fd4b6583b2',
    name: 'Royal Voluntary Service',
    description:
      'Start your volunteering journey today. Join us and help the most vulnerable people in your community or support the NHS.',
    visibility: 'public',
    termsWebpage: '',
    ecosystem: '6596e55db3eb5a234e82dc45',
    organization: '6596e55db3eb5a234e82dc3a',
    featureSettings: {},
    features: [],
    logo: 'https://s3.eu-west-2.amazonaws.com/doit2.0-staging-static/royal_volunteer_service.png',
    defaultAppPermissionGroups: [],
    createdAt: '2024-01-04T19:44:00.399Z',
    updatedAt: '2024-01-04T19:44:00.399Z',
    __v: 0,
  },
  {
    deleted: false,
    _id: '65970a82e36460fd4b6583c1',
    name: 'Royal Voluntary Service Business',
    description:
      'Our corporate partners play a crucial role in supporting the health and wellbeing of vulnerable people in communities across Great Britain; from raising vital funds to building awareness of our charity and employee volunteering.',
    visibility: 'private',
    termsWebpage: '',
    ecosystem: '6596ea6d874b8c87349a096b',
    organization: '6596e55db3eb5a234e82dc3a',
    featureSettings: {},
    features: [],
    logo: 'https://s3.eu-west-2.amazonaws.com/doit2.0-staging-static/royal_volunteer_service.png',
    defaultAppPermissionGroups: [],
    createdAt: '2024-01-04T19:44:02.792Z',
    updatedAt: '2024-01-04T19:44:02.792Z',
    __v: 0,
  },
];

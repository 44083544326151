import { useMemo } from 'react';

import { OrganizationType } from '~/enums';
import { useAppSelector } from '~/hooks/useAppSelector';

export const useCurrentOrganization = () => {
  const { organizations, organizationSelectedId } = useAppSelector(
    ({ auth }) => auth,
  );

  const currentOrganization = useMemo(() => {
    if (!organizationSelectedId || !organizations.length) return null;

    const organization = organizations?.find(
      (org) => org._id === organizationSelectedId,
    );

    return organization || null;
  }, [organizations, organizationSelectedId]);

  const isNFP = currentOrganization?.type === OrganizationType.NFP;

  return { currentOrganization, isNFP };
};

import axios from 'axios';
import { toast } from 'react-toastify';
import { ImageZoneImageItem } from 'ui/components/Dropzone/ImageZoneMini/types';

import { ORGANIZATION_MESSAGES } from '~/constants/messages.constants';

import { uploadImageToS3 } from './uploadImageToS3';
import { uploadThumbnailToS3 } from './uploadThumbnailToS3';

export async function generateCarouselImagesURLsArrayForFiles(
  carouselImages: ImageZoneImageItem[],
) {
  const carouselImagesURLs: string[] = [];

  if (carouselImages?.length) {
    try {
      for (const carouselImage of carouselImages) {
        let imageUrl = '';

        if (carouselImage?.isFile) {
          imageUrl = await uploadImageToS3(
            [carouselImage.imageFile as File],
            'Extra images',
          );

          carouselImagesURLs.push(imageUrl);
        }
      }
      return carouselImagesURLs;
    } catch {
      toast.error(ORGANIZATION_MESSAGES.ERROR_ON_UPLOAD);
      return [];
    }
  } else {
    return [];
  }
}

export const handleCarouselImagesForActivityEdit = async (
  carouselImages: ImageZoneImageItem[],
) => {
  if (carouselImages.length === 0) {
    return [];
  }
  const carouselImageHasNotChanged = carouselImages?.every(
    (carouselImage) => carouselImage.imageUrl,
  );

  if (!carouselImageHasNotChanged) {
    const onlyImagesWithFile = carouselImages.filter(
      (carouselImage) => carouselImage.isFile,
    );

    const onlyImagesWithUrl = carouselImages.filter(
      (carouselImage) => !carouselImage.isFile,
    );

    const existingUrls = onlyImagesWithUrl.map(
      (carouselImage) => carouselImage.imageUrl,
    );

    const newImagesUrls = await generateCarouselImagesURLsArrayForFiles(
      onlyImagesWithFile,
    );

    const carouselImagesUrl = [...existingUrls, ...newImagesUrls];

    if (carouselImagesUrl.length > 0) {
      return carouselImagesUrl;
    }

    return undefined;
  }

  const carouselImagesUrl = carouselImages.map(
    (carouselImage) => carouselImage.imageUrl,
  );

  if (carouselImagesUrl.length > 0) {
    return carouselImagesUrl;
  }

  return undefined;
};

export const handleActivityCoverImage = async (
  coverImage: File[] | string | undefined,
  thumbnailImage: string,
) => {
  const coverImageHasChange = typeof coverImage !== 'string';

  let coverImageURL = coverImage;
  let thumbnailImageURL = thumbnailImage;

  if (coverImageHasChange) {
    const coverImageFile = coverImage as File[];
    coverImageURL = await uploadImageToS3(coverImageFile, 'Cover image');
    thumbnailImageURL = await uploadThumbnailToS3(
      coverImageFile,
      'Cover image',
    );
  } else {
    if (!thumbnailImageURL && coverImage) {
      const url = coverImageURL as string;

      const imageName = url.slice(
        url.lastIndexOf('/') + 1,
        url.lastIndexOf('.'),
      );

      const imageExtension = url.slice(url.lastIndexOf('.') + 1);
      const axiosInstance = axios.create({ responseType: 'blob' });
      const imageBlob = await axiosInstance.get(url);

      const imageFile = new File([imageBlob.data], imageName, {
        type: `image/${imageExtension}`,
      });

      thumbnailImageURL = await uploadThumbnailToS3(
        [imageFile] as File[],
        'Thumbnail image',
      );
    } else {
      coverImageURL = undefined;
    }
  }

  return { coverImageURL, thumbnailImageURL };
};

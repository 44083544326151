import {
  Location,
  NavigateOptions,
  Params,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

interface IHistoryController {
  goBack: () => void;
  goToRoute: (path: string, options?: NavigateOptions) => void;
  replaceRoute: (path: string, options?: NavigateOptions) => void;
  location: Location;
  params: Readonly<Params<string>>;
  searchParams: URLSearchParams;
}

export const useRouter = (): IHistoryController => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const goToRoute = (path: string, options?: NavigateOptions) => {
    navigate(path, options);
  };
  const [searchParams] = useSearchParams();

  const replaceRoute = (path: string, options?: NavigateOptions) => {
    navigate(path, { replace: true, ...options });
  };

  const goBack = () => {
    navigate(-1);
  };

  return { goBack, goToRoute, replaceRoute, location, params, searchParams };
};

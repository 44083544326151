import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IFilterValue } from 'ui/components/DataTable/types';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { ORGANIZATION_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { EcosystemService } from '~/services/resources/ecosystem';

export const usePartnersListController = () => {
  const [partnerEcosystemToRemove, setPartnerEcosystemToRemove] = useState('');
  const [partnersCount, setPartnersCount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refetchQuantity, setRefetchQuantity] = useState(0);
  const modalRef = useRef<IModalRefProps>(null);

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  const { goToRoute } = useRouter();

  const handleOpenModalRemovePartner = (partnerEcosystemToRemove: string) => {
    modalRef.current?.open();
    setPartnerEcosystemToRemove(partnerEcosystemToRemove);
  };

  const handleCloseModal = () => {
    modalRef.current?.close();
  };

  const handleViewPartner = (organizationId: string, connectionId: string) => {
    goToRoute(`${PAGES.PARTNERS}/${organizationId}/${connectionId}`);
  };

  const handleResendInvitation = (partnerId: string) => {
    console.log('resend invitation');
  };

  const handleRemovePartner = async () => {
    try {
      if (!selectedEcosystem) return;
      setIsSubmitting(true);
      await EcosystemService.disconnect({
        ecosystemOne: selectedEcosystem._id,
        ecosystemTwo: partnerEcosystemToRemove,
      });
      toast.success(ORGANIZATION_MESSAGES.REMOVE_PARTNER_SUCCESS);
      handleCloseModal();
      setRefetchQuantity(refetchQuantity + 1);
    } catch (error) {
      toast.error(ORGANIZATION_MESSAGES.ERROR_ON_REMOVE_PARTNER);
    } finally {
      setIsSubmitting(false);
    }
  };

  const showPartnersCountText = () => {
    if (partnersCount < 1) {
      return 'No partners';
    }
    if (partnersCount === 1) {
      return '1 partner';
    }
    return `${partnersCount} partners`;
  };

  const loadPartners = useCallback(
    async (skip: number, limit: number, filter: IFilterValue | null) => {
      try {
        if (!selectedEcosystem) return { data: [], count: 0 };
        const {
          data: { data, count },
        } = await EcosystemService.findPartners(selectedEcosystem._id, {
          skip,
          limit,
          filter: JSON.stringify({
            ...filter,
            parentOrganization: { $exists: false },
          }),
        });
        setPartnersCount(count);
        return { data, count };
      } catch (error) {
        toast.error(ORGANIZATION_MESSAGES.ERROR_ON_LOAD_PARTNERS);
        return { data: [], count: 0 };
      }
    },
    [selectedEcosystem],
  );

  return {
    modalRef,
    organizationSelectedId,
    partnersCount,
    isSubmitting,
    refetchQuantity,
    showPartnersCountText,
    loadPartners,
    handleCloseModal,
    handleOpenModalRemovePartner,
    handleViewPartner,
    handleRemovePartner,
  };
};

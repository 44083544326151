import { HStack, VStack } from 'native-base';
import { CorpCompanyProfileCard } from 'ui/components/v2/Cards/CorpCompanyProfileCard';
import { CorpSideCard } from 'ui/components/v2/Cards/CorpSideCard';

import FeaturedActivitySelectionModal from '~/components/ModalTemplates/FeaturedActivitySelectionModal';
import { UpdatesFeed } from '~/pages/Authenticated/Dashboard/components/UpdatesFeed';
import { useDashboardController } from '~/pages/Authenticated/Dashboard/controller';
import { useFeaturedActivityController } from '~/pages/Authenticated/Dashboard/controller/featured-activity.controller';

export const Dashboard = () => {
  const {
    apps,
    userProfilesCount,
    isUserProfilesCountLoading,
    handleAppPress,
    handleInviteButtonPress,
    handleViewAppsButtonPress,
    handleConnectNewAppButtonPress,
    handleUpdateCompanyProfileButtonPress,
  } = useDashboardController();

  const featuredActivityController = useFeaturedActivityController();

  return (
    <VStack
      w={'full'}
      minH={'full'}
      alignItems={'center'}
      pt={8}
      px={{ base: 4, md: 6, xl: 12 }}
    >
      {/* Main */}
      <HStack
        flexDirection={{ base: 'column-reverse', md: 'row' }}
        w={'full'}
        width="100%"
        maxW="1440px"
        space={{ base: 6, xl: 12 }}
        style={{ rowGap: 4 }}
      >
        {/* Article */}
        <VStack flex={1} width={'full'}>
          <UpdatesFeed apps={apps} key={apps.length} />
        </VStack>

        {/* Aside */}
        <VStack
          w={{ base: 'full', md: '320px', xl: '384px' }}
          space={6}
          style={{ gap: 6 }}
        >
          <CorpSideCard.Employees
            isLoading={isUserProfilesCountLoading}
            employeesCount={userProfilesCount || 0}
            onInviteButtonPress={handleInviteButtonPress}
            onViewInsightsButtonPress={undefined}
          />

          <CorpSideCard.FeaturedActivity
            isLoading={featuredActivityController.isLoading}
            applicantsCount={featuredActivityController.applicationsCount}
            activityDefinition={featuredActivityController.activityDefinition}
            measurementUnit={
              featuredActivityController?.measurementUnit || undefined
            }
            onChangeFeaturedActivityButtonPress={
              featuredActivityController.openFeaturedActivityModal
            }
            onSetFeaturedActivityButtonPress={
              featuredActivityController.openFeaturedActivityModal
            }
          />

          <CorpSideCard.Apps
            apps={apps}
            onAppPress={handleAppPress}
            onViewAppsButtonPress={handleViewAppsButtonPress}
            onConnectNewAppButtonPress={handleConnectNewAppButtonPress}
          />

          <CorpCompanyProfileCard
            onAction={handleUpdateCompanyProfileButtonPress}
          />
        </VStack>
      </HStack>

      <FeaturedActivitySelectionModal
        ref={featuredActivityController.featuredActivityModalRef}
        onSuccess={featuredActivityController.refetch}
      />
    </VStack>
  );
};

import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { ActivityType } from 'ui/types/activities';
import { getDayOfWeekFromNumber } from 'ui/utils/formatDates';

import {
  InsightPageFilters,
  useInsightFilters,
} from '~/pages/Authenticated/Insights/useInsightFilters';
import { InsightCacheConfig } from '~/pages/Authenticated/Insights/utils';
import { INSIGHT_TRENDING_BASE_URL } from '~/services/resources/insights/constants';
import InsightsService from '~/services/resources/insights/insightsService';
import { InsightByPeriodTypeQueryParams } from '~/services/resources/types';

import { activityTypeConfigMap, DEFAULT_ACTIVITY_TYPE_CONFIG } from './utils';

const buildQueryKey = (filters: InsightPageFilters) => [
  INSIGHT_TRENDING_BASE_URL,
  {
    apps: filters.apps,
    ecosystem: filters.selectedEcosystemId,
    organization: filters.selectedOrganizationId,
    startDate: filters.from!,
    endDate: filters.to!,
    granularity: 'Daily',
    periodType: filters.periodType,
  },
];

export const useTrendingTabController = () => {
  const { filters, hasFilterSetted: hasFilterSet } = useInsightFilters();

  const {
    data: response,
    isLoading,
    isFetched,
  } = useQuery({
    queryKey: buildQueryKey(filters),
    queryFn: async ({ queryKey }) => {
      const [, params] = queryKey;
      return InsightsService.getTrendingSummaries(
        params as InsightByPeriodTypeQueryParams,
      );
    },
    enabled: !!hasFilterSet,
    ...InsightCacheConfig,
  });

  const trendingActivityTypeSummary = useMemo(() => {
    if (!response?.trendingActivityType?.activityType)
      return DEFAULT_ACTIVITY_TYPE_CONFIG;
    return activityTypeConfigMap.getOrDefault(
      response?.trendingActivityType.activityType as ActivityType,
    );
  }, [response]);

  const trendingDayToVolunteerSummary = useMemo(() => {
    const dayOfWeek = response?.trendingDayToVolunteer?.dayOfWeek || null;
    if (!dayOfWeek) return { label: '' };
    return { label: getDayOfWeekFromNumber(Number(dayOfWeek)) || '' };
  }, [response]);

  const trendingCauseSummary = {
    label: response?.trendingCause?.causeOption || '',
  };

  return {
    isLoading,
    isFetched,
    trendingCauseSummary,
    trendingActivityTypeSummary,
    trendingDayToVolunteerSummary,
  };
};

export const FAKE_ACTIVITY_DEFINITION = {
  _id: '6657492a6d1854298554f34a',
  title: 'National Clean-Up Day',
  description:
    'Join us for National Clean-Up Day, a nationwide event aimed at bringing communities together to clean and beautify our cities. Volunteers will gather to remove litter, plant trees, and create cleaner, greener spaces. This event will take place in multiple cities across the country on different days, allowing everyone the opportunity to participate and make a tangible impact in their local area. Together, we can foster a sense of community pride and environmental stewardship.',
  type: 'Event',
  eventType: 'National',
  causeOptions: [
    {
      displayName: 'Community',
      relatedTo: 'causeOptions',
      icon: 'https://s3.eu-west-2.amazonaws.com/doit2.0-production-static/community.png',
      images: [],
      app: '659351fbea6975704c8df58c',
      _id: '65935202ea6975704c8df5d0',
      createdAt: '2024-05-31T19:52:22.265Z',
      updatedAt: '2024-05-31T19:52:22.265Z',
    },
  ],
  requirementOptions: [
    {
      displayName: 'Must live or work locally',
      relatedTo: 'requirementOptions',
      images: [],
      app: '659351fbea6975704c8df58c',
      _id: '65935205ea6975704c8df5e7',
      createdAt: '2024-05-31T19:52:22.265Z',
      updatedAt: '2024-05-31T19:52:22.265Z',
    },
    {
      displayName: 'Own Transport',
      relatedTo: 'requirementOptions',
      images: [],
      app: '659351fbea6975704c8df58c',
      _id: '65935205ea6975704c8df5e9',
      createdAt: '2024-05-31T19:52:22.265Z',
      updatedAt: '2024-05-31T19:52:22.265Z',
    },
  ],
  locationOption: 'Multiple Locations',
  coverImage:
    'https://s3.eu-west-2.amazonaws.com/doit2.0-staging-uploads/OrganizationPicture/65b7b8c2-07b3-4872-932c-16ef307fb9a0_2024-05-29T15:26:20.032Z.png',
  thumbnailImage:
    'https://s3.eu-west-2.amazonaws.com/doit2.0-staging-uploads/OrganizationPicture/65b7b8c2-07b3-4872-932c-16ef307fb9a0_2024-05-29T15:26:27.950Z.png',
  carouselImages: [
    'https://s3.eu-west-2.amazonaws.com/doit2.0-staging-uploads/OrganizationPicture/65b7b8c2-07b3-4872-932c-16ef307fb9a0_2024-05-29T15:26:29.900Z.jpeg',
    'https://s3.eu-west-2.amazonaws.com/doit2.0-staging-uploads/OrganizationPicture/65b7b8c2-07b3-4872-932c-16ef307fb9a0_2024-05-29T15:26:32.036Z.jpeg',
  ],
  isApprovalRequired: false,
  organizationSubDocument: {
    name: 'NCVO',
    description:
      'We’re the membership community for charities, voluntary organisations and community groups in England. Together we champion voluntary action.\n\nWe’ve been here for over 100 years. We have over 17,000 members, made up of all causes, shapes and sizes. From big charities to local sports clubs.\n\nOur members are at the heart of everything we do. We exist to make your life easier – so you can focus on changing people’s lives and making our communities stronger and more resilient.',
    purpose:
      'Together, we champion charities and volunteers. The compassionate people who make a daily difference. The collective impact made across the country. For stronger communities. For everyone. Everywhere.\n',
    type: 'NFP',
    websiteLink: 'https://www.ncvo.org.uk/about-us/who-we-are/',
    termsOfServicesLink: '',
    logo: 'https://s3.eu-west-2.amazonaws.com/doit2.0-staging-uploads/OrganizationPicture/65b7b8c2-07b3-4872-932c-16ef307fb9a0_2024-05-27T21:37:06.939Z.png',
    fullAddress: {
      location: {
        type: 'Point',
        coordinates: [-0.1275862, 51.5072178],
      },
      street: 'London',
      _id: '665553b90459bb740a53d143',
      createdAt: '2024-05-28T03:47:05.069Z',
      updatedAt: '2024-05-29T15:26:34.635Z',
    },
    admins: ['6654f06d3ce5cc4e327c839a'],
    causeOptions: [
      {
        displayName: 'Animal welfare',
        relatedTo: 'causeOptions',
        icon: 'https://s3.eu-west-2.amazonaws.com/doit2.0-production-static/animal_welfare.png',
        images: [],
        app: '659351fbea6975704c8df58c',
        _id: '65935202ea6975704c8df5cc',
        createdAt: '2024-05-28T03:47:05.069Z',
        updatedAt: '2024-05-29T15:26:34.635Z',
      },
      {
        displayName: 'Community',
        relatedTo: 'causeOptions',
        icon: 'https://s3.eu-west-2.amazonaws.com/doit2.0-production-static/community.png',
        images: [],
        app: '659351fbea6975704c8df58c',
        _id: '65935202ea6975704c8df5d0',
        createdAt: '2024-05-28T03:47:05.069Z',
        updatedAt: '2024-05-29T15:26:34.635Z',
      },
      {
        displayName: 'Crisis and Welfare',
        relatedTo: 'causeOptions',
        icon: 'https://s3.eu-west-2.amazonaws.com/doit2.0-production-static/crisis.png',
        images: [],
        app: '659351fbea6975704c8df58c',
        _id: '65935202ea6975704c8df5d3',
        createdAt: '2024-05-28T03:47:05.069Z',
        updatedAt: '2024-05-29T15:26:34.635Z',
      },
      {
        displayName: 'Health and social care',
        relatedTo: 'causeOptions',
        icon: 'https://s3.eu-west-2.amazonaws.com/doit2.0-production-static/health.png',
        images: [],
        app: '659351fbea6975704c8df58c',
        _id: '65935203ea6975704c8df5d7',
        createdAt: '2024-05-28T03:47:05.069Z',
        updatedAt: '2024-05-29T15:26:34.635Z',
      },
      {
        displayName: 'Older people',
        relatedTo: 'causeOptions',
        icon: 'https://s3.eu-west-2.amazonaws.com/doit2.0-production-static/older_people.png',
        images: [],
        app: '659351fbea6975704c8df58c',
        _id: '65935203ea6975704c8df5d9',
        createdAt: '2024-05-28T03:47:05.069Z',
        updatedAt: '2024-05-29T15:26:34.635Z',
      },
      {
        displayName: 'Art and culture',
        relatedTo: 'causeOptions',
        icon: 'https://s3.eu-west-2.amazonaws.com/doit2.0-production-static/sports_arts.png',
        images: [],
        app: '659351fbea6975704c8df58c',
        _id: '65935203ea6975704c8df5db',
        createdAt: '2024-05-28T03:47:05.069Z',
        updatedAt: '2024-05-29T15:26:34.635Z',
      },
      {
        displayName: 'Sustainability, heritage and environment',
        relatedTo: 'causeOptions',
        icon: 'https://s3.eu-west-2.amazonaws.com/doit2.0-production-static/sustainability.png',
        images: [],
        app: '659351fbea6975704c8df58c',
        _id: '65935203ea6975704c8df5dd',
        createdAt: '2024-05-28T03:47:05.069Z',
        updatedAt: '2024-05-29T15:26:34.635Z',
      },
      {
        displayName: 'Young People & Children',
        relatedTo: 'causeOptions',
        icon: 'https://s3.eu-west-2.amazonaws.com/doit2.0-production-static/young_people.png',
        images: [],
        app: '659351fbea6975704c8df58c',
        _id: '65935203ea6975704c8df5df',
        createdAt: '2024-05-28T03:47:05.069Z',
        updatedAt: '2024-05-29T15:26:34.635Z',
      },
      {
        displayName: 'Sports',
        relatedTo: 'causeOptions',
        icon: 'https://s3.eu-west-2.amazonaws.com/doit2.0-production-static/sports_arts.png',
        images: [],
        app: '659351fbea6975704c8df58c',
        _id: '65b82e8bf17a9b0ea89c5039',
        createdAt: '2024-05-28T03:47:05.069Z',
        updatedAt: '2024-05-29T15:26:34.635Z',
      },
      {
        displayName: 'Animal welfare',
        relatedTo: 'causeOptions',
        icon: 'https://s3.eu-west-2.amazonaws.com/doit2.0-production-static/animal_welfare.png',
        images: [],
        _id: '65d35c02cc7d82ed85df7116',
        createdAt: '2024-05-28T03:47:05.069Z',
        updatedAt: '2024-05-29T15:26:34.635Z',
      },
    ],
    domainSlug: 'ncvo-uk',
    contactEmail: 'barbara+adminncvo@doit.life',
    contactPhoneNumber: '+44123456785',
    organizationSettings: {
      hasExternalId: true,
      _id: '665553b90459bb740a53d144',
      createdAt: '2024-05-28T03:47:05.069Z',
      updatedAt: '2024-05-29T15:26:34.635Z',
    },
    _id: '6654f1c123d7a6952085123d',
    createdAt: '2024-05-27T20:49:05.547Z',
    updatedAt: '2024-05-29T15:26:34.635Z',
    deleted: false,
    __v: 0,
  },
  ecosystem: '6654f1c123d7a69520851253',
  organization: '6654f1c123d7a6952085123d',
  app: {
    deleted: false,
    _id: '659351fbea6975704c8df58c',
    name: 'The Big Help Out App',
    description: 'The Big Help Out App',
    visibility: 'public',
    termsWebpage: 'https://thebighelpout.org.uk/terms-and-conditions/',
    ecosystem: '659351f9ea6975704c8df584',
    organization: {
      _id: '659351f8ea6975704c8df57d',
      name: 'NCVO ',
    },
    featureSettings: {},
    features: ['659351faea6975704c8df586'],
    logo: 'https://s3.eu-west-2.amazonaws.com/doit2.0-staging-static/bhoLogo.png',
    defaultAppPermissionGroups: [
      '659351fbea6975704c8df58e',
      '659351feea6975704c8df5ab',
    ],
    createdAt: '2024-01-01T23:59:55.019Z',
    updatedAt: '2024-01-02T00:00:00.649Z',
    __v: 1,
    brandColor: '#0000FF',
    headLine: 'Lend a hand. Make a change.',
  },
  publishedApps: ['659351fbea6975704c8df58c'],
  volunteerRewards: ['a clean and beautiful city'],
  volunteerRequirements: ['gloves', 'water bottle'],
  spaceOptions: 'indoor',
  eventApplicationType: 'Individual',
  measurementUnit: {
    deleted: false,
    _id: '65941cfefd5d562cb3083a25',
    singularLabel: 'Hour',
    pluralLabel: 'Hours',
    steps: [],
    category: 'Time',
    createdAt: '2023-01-02T12:17:22.635Z',
    updatedAt: '2023-01-02T12:17:22.635Z',
  },
  targetAmount: 100,
  measurementUnitPluralLabel: 'Hours',
  externalProvider: null,
  externalId: null,
  deleted: false,
  periodOptions: [],
  activitiesSummary: [
    {
      _id: '6657492b83eef4f848d23467',
      startDate: '2024-08-10T03:00:00.000Z',
      endDate: '2024-08-10T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-2.9915726, 53.4083714],
        },
        street: 'Liverpool, UK',
        _id: '665a2a8038fb74cf43003e77',
        createdAt: '2024-05-31T19:52:32.410Z',
        updatedAt: '2024-05-31T19:52:32.410Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '6657492b6d1854298554f36c',
      startDate: '2024-08-10T03:00:00.000Z',
      endDate: '2024-08-10T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-1.470085, 53.38112899999999],
        },
        street: 'Sheffield, UK',
        _id: '665a2a78365eb8a7a965e08b',
        createdAt: '2024-05-31T19:52:24.825Z',
        updatedAt: '2024-05-31T19:52:24.825Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '6657492c83eef4f848d2348d',
      startDate: '2024-08-17T03:00:00.000Z',
      endDate: '2024-08-17T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-1.332134, 52.82937399999999],
        },
        street:
          'East Midlands Airport (EMA), Castle Donington, Derby DE74 2SA, UK',
        _id: '665a2a785be3291d9eb9de69',
        createdAt: '2024-05-31T19:52:24.024Z',
        updatedAt: '2024-05-31T19:52:24.024Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '6657492c6d1854298554f392',
      startDate: '2024-08-10T03:00:00.000Z',
      endDate: '2024-08-10T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-3.188267, 55.953252],
        },
        street: 'Edinburgh, UK',
        _id: '665a2a7884b7df1a5174d953',
        createdAt: '2024-05-31T19:52:24.446Z',
        updatedAt: '2024-05-31T19:52:24.446Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '6657492c83eef4f848d234b3',
      startDate: '2024-08-03T03:00:00.000Z',
      endDate: '2024-08-03T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-1.5490774, 53.8007554],
        },
        street: 'Leeds, UK',
        _id: '665a2a7835f9bea93886c217',
        createdAt: '2024-05-31T19:52:24.338Z',
        updatedAt: '2024-05-31T19:52:24.338Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '6657492d83eef4f848d234d9',
      startDate: '2024-08-03T03:00:00.000Z',
      endDate: '2024-08-03T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-4.2583345, 55.86167039999999],
        },
        street: 'Glasgow, UK',
        _id: '665a2a8013cc2f1f0390bfc6',
        createdAt: '2024-05-31T19:52:32.108Z',
        updatedAt: '2024-05-31T19:52:32.108Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '6657492ddca70236acdef557',
      startDate: '2024-08-10T03:00:00.000Z',
      endDate: '2024-08-10T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-2.58791, 51.454513],
        },
        street: 'Bristol, UK',
        _id: '665a2a80a2bd945fc230e715',
        createdAt: '2024-05-31T19:52:32.495Z',
        updatedAt: '2024-05-31T19:52:32.495Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '665749338703e9b6f285f5a3',
      startDate: '2024-08-10T03:00:00.000Z',
      endDate: '2024-08-10T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-1.61778, 54.978252],
        },
        street: 'Newcastle upon Tyne, UK',
        _id: '665a2a78f341952c0cd0f795',
        createdAt: '2024-05-31T19:52:24.655Z',
        updatedAt: '2024-05-31T19:52:24.655Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '665749331643c4bd1680c5f6',
      startDate: '2024-08-24T03:00:00.000Z',
      endDate: '2024-08-24T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [0.1312729, 52.1950788],
        },
        street: 'Cambridge, UK',
        _id: '665a2a804718347138dccb60',
        createdAt: '2024-05-31T19:52:32.335Z',
        updatedAt: '2024-05-31T19:52:32.335Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '665749341473ab10d101f528',
      startDate: '2024-08-17T03:00:00.000Z',
      endDate: '2024-08-17T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-1.5089521, 52.4128163],
        },
        street: 'Coventry, UK',
        _id: '665a2a7784b7df1a5174d911',
        createdAt: '2024-05-31T19:52:23.846Z',
        updatedAt: '2024-05-31T19:52:23.846Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '665749347b19da4c6034992b',
      startDate: '2024-08-17T03:00:00.000Z',
      endDate: '2024-08-17T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-0.1275862, 51.5072178],
        },
        street: 'London, UK',
        _id: '665a2a7884b7df1a5174d932',
        createdAt: '2024-05-31T19:52:24.166Z',
        updatedAt: '2024-05-31T19:52:24.166Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '665749344e88344345bb78cc',
      startDate: '2024-08-10T03:00:00.000Z',
      endDate: '2024-08-10T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-0.1275862, 51.5072178],
        },
        street: 'London, UK',
        _id: '665a2a785be3291d9eb9de8a',
        createdAt: '2024-05-31T19:52:24.283Z',
        updatedAt: '2024-05-31T19:52:24.283Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '66574934672fac196e7f4058',
      startDate: '2024-08-24T03:00:00.000Z',
      endDate: '2024-08-24T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-2.0937528, 57.1498891],
        },
        street: 'Aberdeen, UK',
        _id: '665a2a7f95c25e7c67e4fbce',
        createdAt: '2024-05-31T19:52:31.855Z',
        updatedAt: '2024-05-31T19:52:31.855Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '665749349f6ac84b274a762c',
      startDate: '2024-08-24T03:00:00.000Z',
      endDate: '2024-08-24T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-0.1275862, 51.5072178],
        },
        street: 'London, UK',
        _id: '665a2a7fd34cf4608ba8cb30',
        createdAt: '2024-05-31T19:52:31.591Z',
        updatedAt: '2024-05-31T19:52:31.591Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '665749341763e530da4be9e4',
      startDate: '2024-08-17T03:00:00.000Z',
      endDate: '2024-08-17T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-1.0879769, 50.8197675],
        },
        street: 'Portsmouth, UK',
        _id: '665a2a80964c85df1f8f705d',
        createdAt: '2024-05-31T19:52:32.282Z',
        updatedAt: '2024-05-31T19:52:32.282Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '665749342c5afc435c4960c3',
      startDate: '2024-08-03T03:00:00.000Z',
      endDate: '2024-08-03T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-1.8900078, 52.4822694],
        },
        street: 'Birmingham, UK',
        _id: '665a2a8081350fc859fa8a8f',
        createdAt: '2024-05-31T19:52:32.301Z',
        updatedAt: '2024-05-31T19:52:32.301Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '6657493435007040e1304471',
      startDate: '2024-08-03T03:00:00.000Z',
      endDate: '2024-08-03T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-0.1275862, 51.5072178],
        },
        street: 'London, UK',
        _id: '665a2a805d19558ed626637c',
        createdAt: '2024-05-31T19:52:32.370Z',
        updatedAt: '2024-05-31T19:52:32.370Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '66574934898b0cd8dad5d5d7',
      startDate: '2024-08-17T03:00:00.000Z',
      endDate: '2024-08-17T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-1.4049018, 50.9105468],
        },
        street: 'Southampton, UK',
        _id: '665a2a80e50400f56d002057',
        createdAt: '2024-05-31T19:52:32.076Z',
        updatedAt: '2024-05-31T19:52:32.076Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '66574934c15aa3fb50f64fce',
      startDate: '2024-08-24T03:00:00.000Z',
      endDate: '2024-08-24T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [1.2978802, 52.6292567],
        },
        street: 'Norwich, UK',
        _id: '665a2a80a399bf982f17a049',
        createdAt: '2024-05-31T19:52:32.725Z',
        updatedAt: '2024-05-31T19:52:32.725Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '665749347bf7091cd3b47d6c',
      startDate: '2024-08-24T03:00:00.000Z',
      endDate: '2024-08-24T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-5.93012, 54.59728500000001],
        },
        street: 'Belfast, UK',
        _id: '665a2a7f96b8e2325e06c6a9',
        createdAt: '2024-05-31T19:52:31.842Z',
        updatedAt: '2024-05-31T19:52:31.842Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '665749348fa484b8bff55348',
      startDate: '2024-08-03T03:00:00.000Z',
      endDate: '2024-08-03T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-2.1578377, 53.4575955],
        },
        street: 'Greater Manchester, UK',
        _id: '665a2a7fbd9c74ba7179ad72',
        createdAt: '2024-05-31T19:52:31.917Z',
        updatedAt: '2024-05-31T19:52:31.917Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '665749353b84454c8617c322',
      startDate: '2024-08-17T03:00:00.000Z',
      endDate: '2024-08-17T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-1.2052126, 52.772571],
        },
        street: 'Leicestershire, UK',
        _id: '665a2a7f1c18c04737385f08',
        createdAt: '2024-05-31T19:52:31.395Z',
        updatedAt: '2024-05-31T19:52:31.395Z',
      },
      isOnline: false,
      regions: [],
    },
    {
      _id: '665a2a80f60f4a1be1490817',
      startDate: '2024-07-20T03:00:00.000Z',
      endDate: '2024-07-21T03:00:00.000Z',
      address: {
        location: {
          type: 'Point',
          coordinates: [-0.1275862, 51.5072178],
        },
        street: 'London, UK',
        _id: '665a2a81f60f4a1be149082e',
      },
      isOnline: false,
      regions: [],
    },
  ],
  createdAt: '2024-05-29T15:26:34.636Z',
  updatedAt: '2024-05-31T19:52:33.209Z',
  __v: 0,
};

import { HStack, Progress, Text, VStack } from 'native-base';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { formatMeasurementAmount } from 'ui/utils/measurements/format';

import { InsightsGenericEmptyState } from '~/pages/Authenticated/Insights/components/EmptyState';
import { InsightScaffold } from '~/pages/Authenticated/Insights/components/InsightScaffold';
import { PercentageChangeIndicator } from '~/pages/Authenticated/Insights/components/PercentageChangeIndicator';
import {
  InsightPageFilters,
  useInsightFilters,
} from '~/pages/Authenticated/Insights/useInsightFilters';
import { InsightCacheConfig } from '~/pages/Authenticated/Insights/utils';
import InsightMeasurementService from '~/services/resources/insightMeasurement';
import { InsightByPeriodTypeQueryParams } from '~/services/resources/types';

import { TotalHoursByEmployeesSkeleton } from './TotalHoursByEmployeesSkeleton';

type TotalHoursByEmployeesProps = {
  className: string;
};

const buildQueryKey = (filters: InsightPageFilters) => [
  InsightMeasurementService.MEASUREMENT_GOALS_KEY,
  {
    apps: filters.apps,
    ecosystem: filters.selectedEcosystemId,
    organization: filters.selectedOrganizationId,
    startDate: filters.from!,
    endDate: filters.to!,
    granularity: 'Daily',
    periodType: filters.periodType,
  } as InsightByPeriodTypeQueryParams,
];

export const TotalHoursByEmployees = ({
  className,
}: TotalHoursByEmployeesProps) => {
  const { filters, hasFilterSetted } = useInsightFilters();

  const {
    data: response,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: buildQueryKey(filters),
    queryFn: async ({ queryKey }) => {
      const [, params] = queryKey;

      return await InsightMeasurementService.getMeasurementGoals(
        params as InsightByPeriodTypeQueryParams,
      );
    },
    enabled: hasFilterSetted,
    ...InsightCacheConfig,
  });

  const renderChartContent = () => {
    if (
      !response ||
      (!response?.organizationGoal && !response?.totalCurrentPeriod)
    ) {
      return (
        <VStack flex={1}>
          <InsightsGenericEmptyState />;
        </VStack>
      );
    }

    return (
      <VStack px={6} pb={6}>
        <HStack space={4}>
          <Text fontSize={'3xl'} fontWeight={500} color="gray.800">
            {formatMeasurementAmount(response.totalCurrentPeriod)}
          </Text>
          <PercentageChangeIndicator
            percentage={response.percentageVariation}
            isPositive={response.percentageVariation > 0}
          />
        </HStack>
        <HStack
          mt={5}
          mb={2}
          space={2}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <VStack>
            <Text fontSize={'xl'} fontWeight={500} color={'gray.500'}>
              <Text color={'warning.600'}>
                {formatMeasurementAmount(response.totalThisYearPeriod)}
              </Text>{' '}
              this year
            </Text>
            <Text fontSize={'xs'} color={'gray.600'}>
              {(response.completedGoalPercentage ?? 0).toFixed(2)}% of company
              goal
            </Text>
          </VStack>
          <VStack>
            <Text fontSize={'xs'} color={'gray.600'}>
              Company goal
            </Text>
            <Text fontSize={'2xl'} fontWeight={500} color={'gray.800'}>
              {formatMeasurementAmount(response.organizationGoal)}
            </Text>
          </VStack>
        </HStack>
        <Progress
          colorScheme="warning"
          value={response.completedGoalPercentage ?? 0}
          bg={'gray.300'}
          mt={2}
        />
      </VStack>
    );
  };

  return (
    <InsightScaffold
      className={className}
      title={{
        icon: 'clock',
        name: 'Total hours logged by employees',
      }}
      chartData={response ? [response] : []}
    >
      {isLoading || isFetching ? (
        <TotalHoursByEmployeesSkeleton />
      ) : (
        renderChartContent()
      )}
    </InsightScaffold>
  );
};

import { useRef } from 'react';
import { useIntersection } from 'ui/hooks/useIntersection';

import { FeedScaffoldProps } from './types';

export const useFeedScaffoldController = ({
  isLoading,
  hasNextPage,
  isRefetching,
  futureDataCount,
  isFetchingNextPage,
  dataCount,
  fetchNextPage,
  refetch,
}: FeedScaffoldProps) => {
  // States
  const topRef = useRef<HTMLElement | null>(null);

  // Hooks
  const handleOnScrollEndReached = () => {
    if (!hasNextPage || isFetchingNextPage || isRefetching || isLoading) return;
    fetchNextPage?.();
  };

  const bottomRef = useIntersection(handleOnScrollEndReached);

  // Handlers
  const handleScrollToBottom = () => {
    topRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  const handleRefetchButtonPress = () => {
    handleScrollToBottom();
    refetch?.();
  };

  // Render Statements
  const isEmpty = !isLoading && !isRefetching && dataCount == 0;

  const isOutData = dataCount > 0 && !hasNextPage;

  const isNextPageLoading = isLoading || isFetchingNextPage;

  const showRefetchButton = futureDataCount || 0 > 0;

  const isRefetchingLoading = isRefetching;

  return {
    topRef,
    dataCount,
    futureDataCount,
    bottomRef,
    isEmpty,
    isOutData,
    showRefetchButton,
    isNextPageLoading,
    isRefetchingLoading,
    handleRefetchButtonPress,
  };
};

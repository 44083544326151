import { HStack, Input, Stack, Text, VStack } from 'native-base';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import CircularUser from 'ui/assets/icons/circular-user.svg';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import Modal from 'ui/components/Modals/Modal';

import { InviteNewAdminControllerAttributes } from '~/pages/Authenticated/Settings/AdminsSettings/types';
import { isValidEmail } from '~/pages/Unauthenticated/SignUpPage/RegisterOrganisation/InviteAdminForm/utils';
import { colors } from '~/theme/colors';

export const InviteNewAdminModal = ({
  modalInviteNewAdminRef,
  isSendingNewAdminInvites,
  onSendNewAdminInvites,
  closeInviteNewAdminModal,
  adminEmailInput,
  setAdminEmailInput,
  adminEmailsList,
  addAdminEmail,
  removeAdminEmail,
}: Pick<
  InviteNewAdminControllerAttributes,
  | 'modalInviteNewAdminRef'
  | 'isSendingNewAdminInvites'
  | 'onSendNewAdminInvites'
  | 'closeInviteNewAdminModal'
  | 'adminEmailInput'
  | 'setAdminEmailInput'
  | 'onChangeAdminEmailInput'
  | 'adminEmailsList'
  | 'addAdminEmail'
  | 'removeAdminEmail'
>) => {
  const isAddDisabled = useMemo(
    () => !adminEmailInput || !isValidEmail(adminEmailInput),
    [adminEmailInput],
  );
  const onPressAdd = useCallback(() => {
    addAdminEmail(adminEmailInput);
    if (inputRef.current?.value) {
      inputRef.current.value = '';
    }
  }, [addAdminEmail, adminEmailInput]);
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const input = inputRef.current;
    const setText = () => {
      input?.value && setAdminEmailInput(input?.value);
    };
    inputRef.current?.addEventListener('input', setText);
    return () => input?.removeEventListener('input', setText);
  });
  useEffect(() => {
    console.log('adminEmailInput', adminEmailInput);
  }, [adminEmailInput]);

  return (
    <Modal
      ref={modalInviteNewAdminRef}
      rightBtnText="Send invitations"
      leftBtnText="Cancel"
      rightBtnAction={onSendNewAdminInvites}
      leftBtnAction={closeInviteNewAdminModal}
      rightBtnLoading={isSendingNewAdminInvites}
      onClose={closeInviteNewAdminModal}
      size="xl"
      headerText="Invite new administrators"
    >
      <Stack mt={2} space="5">
        <Text color="gray.600">
          Invite new or existing users to join you as administrators of your
          organisation
        </Text>
        <HStack alignItems="center" space={3} w="full">
          <RenderEmailInput inputRef={inputRef} />
          <Button
            flex="1"
            py="6"
            borderWidth="1"
            borderColor="primary.600"
            leftIconName="plus-circle"
            variant="outline"
            leftIconColor={colors.primary[600]}
            isDisabled={isAddDisabled}
            onPress={onPressAdd}
          >
            <Text color="primary.600" fontSize={16} fontWeight="500">
              Add
            </Text>
          </Button>
        </HStack>
        {adminEmailsList.length > 0 ? (
          <VStack w="100%" space="4" px={2} maxH="256px" overflowY="scroll">
            <RenderEmailsList
              adminEmailsList={adminEmailsList}
              removeAdminEmail={removeAdminEmail}
            />
          </VStack>
        ) : null}
      </Stack>
    </Modal>
  );
};

const RenderEmailInput = React.memo(
  function RenderEmailInput({
    inputRef,
  }: {
    inputRef: React.RefObject<HTMLInputElement>;
  }) {
    return (
      <Input
        w="full"
        flexShrink="1"
        placeholder="Enter admin's email address"
        ref={inputRef}
      />
    );
  },
  () => true,
);

const RenderEmailsList = React.memo(
  function RenderEmailsList({
    adminEmailsList,
    removeAdminEmail,
  }: Pick<
    InviteNewAdminControllerAttributes,
    'adminEmailsList' | 'removeAdminEmail'
  >) {
    return (
      <>
        {adminEmailsList.map((email) => (
          <HStack
            key={email}
            py="4"
            borderBottomWidth="1"
            borderBottomColor="gray.300"
            justifyContent="space-between"
            alignItems="center"
          >
            <HStack alignItems="center" space="3">
              <Text color="gray.600">{email}</Text>
            </HStack>
            <HStack space={3} alignItems="center">
              <Icon icon="x-circle" color={colors.error[600]} size="18" />
              <Text
                color="red.600"
                fontWeight="500"
                onPress={() => removeAdminEmail(email)}
              >
                Remove
              </Text>
            </HStack>
          </HStack>
        ))}
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.adminEmailsList.length === nextProps.adminEmailsList.length &&
    prevProps.adminEmailsList.every(
      (email, index) => nextProps.adminEmailsList[index] === email,
    ),
);

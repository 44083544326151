import { HStack, Link, Text, VStack } from 'native-base';
import { ReactNode } from 'react';
import Icon from 'ui/components/Icon';

export const DesignBookSection = ({
  title,
  children,
  figmaUrl,
}: {
  title: string;
  children: ReactNode;
  figmaUrl?: string;
}) => {
  return (
    <VStack width={'full'} space={4} mb={6} background={'#00000022'}>
      <VStack mb={2} w={'full'}>
        <HStack
          w="full"
          space={2}
          background={'#33333399'}
          px={2}
          py={1}
          borderRadius={4}
          alignItems={'center'}
        >
          <Icon icon="chevron-right" size="14px" color={'white'}></Icon>
          <Text color={'white'} fontWeight={500}>
            {title}
          </Text>
        </HStack>
        {figmaUrl && (
          <Text w="full" fontSize={'xs'} mx={4}>
            <Link href={figmaUrl} isExternal>
              {figmaUrl}
            </Link>
          </Text>
        )}
      </VStack>

      <VStack
        flex={1}
        width={'full'}
        justifyContent={'center'}
        alignItems={'center'}
        px={3}
        pb={8}
      >
        {children}
      </VStack>
    </VStack>
  );
};

export default DesignBookSection;

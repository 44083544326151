import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useJoinAsAdmin } from '~/hooks/useJoinAsAdmin';
import { useQuery } from '~/hooks/useQuery';
import { useRouter } from '~/hooks/useRouter';
import { joinAsAdminSliceActions } from '~/store/slices/joinAsAdmin';
import { InviteCode } from '~/types/interfaces/inviteCode';
import { IOrganization } from '~/types/interfaces/organization';

import { defaultValues, schema } from './constants';

export const useJoinAsAdminInviteController = () => {
  const modalTermsOfServiceRef = useRef<IModalRefProps>(null);

  const { isAuthenticated } = useAppSelector(({ auth }) => auth);

  const dispatch = useAppDispatch();
  const { handleJoinAsAdminAuthenticated } = useJoinAsAdmin();

  const {
    searchParams,
    goToRoute,
    params: { code },
  } = useRouter();
  const organizationId = searchParams.get('organization');

  const organizationEndPoint = `/organization/public/${organizationId}`;
  const queryOptions = useMemo(() => {
    return { queryOptions: { enabled: !!organizationId }, isOpenApi: true };
  }, [organizationId]);

  const { data: organization, isLoading: isLoadingOrganization } =
    useQuery<IOrganization>(organizationEndPoint, queryOptions);

  const {
    data: inviteCode,
    isLoading: isLoadingInviteCode,
    status: inviteCodeStatus,
  } = useQuery<InviteCode>(
    `invite-code/public/code/${code}/organization/${organizationId}`,
    {
      queryOptions: { enabled: !!organizationId && !!code },
      isOpenApi: true,
    },
  );

  useEffect(() => {
    dispatch(
      joinAsAdminSliceActions.update({
        code,
        isJoiningAsAdmin: true,
        organizationId,
      }),
    );
    if (!isAuthenticated) {
      goToRoute(PAGES.SIGN_UP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, code, organizationId]);

  useEffect(() => {
    if (inviteCodeStatus === 'error') {
      goToRoute(PAGES.SIGNIN);
      toast.info('Unable to load invite link.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteCodeStatus]);

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<{ termsOfServiceAccepted: boolean }>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleOpenTerms = () => modalTermsOfServiceRef.current?.open();

  const handleDeclineInvitation = () => {
    dispatch(joinAsAdminSliceActions.clear());
    goToRoute(PAGES.INVITE_ADMIN_DECLINED);
  };

  const onSubmit = handleSubmit(async ({ termsOfServiceAccepted }) => {
    if (!termsOfServiceAccepted || !organization) return;
    await handleJoinAsAdminAuthenticated();
  });

  const isLoading = useMemo(() => {
    return isLoadingOrganization || isLoadingInviteCode;
  }, [isLoadingOrganization, isLoadingInviteCode]);

  return {
    control,
    organization,
    isValid,
    isSubmitting,
    isLoading,
    isLoadingInviteCode,
    modalTermsOfServiceRef,
    inviteCode,
    onSubmit,
    handleDeclineInvitation,
    handleOpenTerms,
  };
};

import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { Avatar, HStack, Text } from 'native-base';
import DataTableActions from 'ui/components/DataTableActions';
import { TableCellToolTip } from 'ui/components/TableCellToolTip';
import { InviteCodeVisibilityEnum } from 'ui/enums';
import { formattedDate } from 'ui/utils/formatter';

import { MemberTypeEnum } from '~/enums';
import { colors } from '~/theme/colors';
import { App } from '~/types/interfaces/app';
import { InvitePartnersPopulate } from '~/types/interfaces/invite-partner';
import { InviteTablePartners } from '~/types/interfaces/table';

interface TablePendingPartnerInvitesProps {
  openResendInviteModal: (inviteId: string, inviteType: string) => void;
  copyInviteLink: (link: string) => void;
  openModalInviteDetails: (data: Partial<InvitePartnersPopulate>) => void;
}

export const tablePendingPartnerInviteColumns = ({
  openResendInviteModal,
  copyInviteLink,
  openModalInviteDetails,
}: TablePendingPartnerInvitesProps) => [
  {
    name: 'receiverEmail',
    header: 'E-mail',
    defaultFlex: 1,
    filterable: true,
    render: ({ data }: InviteTablePartners) => {
      return data.receiverEmail || '-';
    },
  },
  {
    name: 'createdAt',
    header: 'Date of invite',
    filterable: true,
    filterEditor: DateFilter,
    filterEditorProps: () => ({
      dateFormat: 'YYYY-MM-DD',
      cancelButton: false,
      highlightWeekends: false,
    }),
    defaultFlex: 0.6,
    render: ({ data }: InviteTablePartners) => {
      return formattedDate(data.createdAt, 'dd/MM/yyyy') || '-';
    },
  },
  {
    name: 'visibility',
    header: 'Type',
    defaultFlex: 0.5,
    render: ({ data }: InviteTablePartners) => {
      return data.visibility ?? InviteCodeVisibilityEnum.Private;
    },
  },
  {
    name: 'configurations.ecosystemId',
    header: 'Ecosystems',
    defaultFlex: 1,

    render: ({ data }: InviteTablePartners) => {
      const text = `${data.configurations
        .map((configuration) => configuration.ecosystemId.name)
        .join(', ')}`;
      return <TableCellToolTip text={text || ''} />;
    },
  },
  {
    name: 'configurations.appsData',
    header: 'Apps',
    defaultFlex: 0.5,
    cellProps: {
      style: { flexDirection: 'row' },
    },
    render: ({ data }: InviteTablePartners) => {
      const uniqueApps: App[] = [];

      data.configurations.forEach((configuration) => {
        configuration.appsData.forEach((appData) => {
          const hasApp = uniqueApps.some(
            (uniqueApp) => uniqueApp._id === appData.appId._id,
          );

          if (!hasApp) uniqueApps.push(appData.appId as App);
        });
      });

      return (
        <HStack space={1}>
          {uniqueApps.map((app) => (
            <Avatar
              size={'sm'}
              borderRadius={'sm'}
              bg={'white'}
              key={app._id}
              source={{ uri: app.logo }}
            />
          ))}
        </HStack>
      );
    },
  },
  {
    name: 'actions',
    header: 'Actions',
    defaultFlex: 0.6,
    render: ({ data }: InviteTablePartners) => {
      const popoverOptions = [
        {
          icon: {
            color: colors.gray[600],
            icon: 'send',
            size: 22,
          },
          title: 'Resend invite',
          action: () => openResendInviteModal(data._id, MemberTypeEnum.Partner),
        },
        {
          icon: {
            color: colors.gray[600],
            icon: 'copy',
            size: 22,
          },
          title: 'Copy link',
          action: () => copyInviteLink(data.inviteURL || ''),
        },
        {
          icon: {
            color: colors.gray[600],
            icon: 'eye',
            size: 22,
          },
          title: 'Details',
          action: () => openModalInviteDetails(data),
        },
      ];
      return (
        <DataTableActions
          popoverActions
          popoverTextColor={colors.primary[400]}
          popoverIconColor={colors.primary[400]}
          popoverOptions={popoverOptions}
        />
      );
    },
  },
];

import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { AppVisibility } from 'ui/enums/index';

import { ECOSYSTEM_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { IEcosystem } from '~/types/interfaces/ecosystem';
import { uploadLogoIfProvided } from '~/utils/uploadLogoIfProvided';

import { EcosystemService } from '../../../../services/resources/ecosystem';
import { ICreateEcosystemForm } from '../types';

export const useCreateEcosystemController = () => {
  const modalRef = useRef<IModalRefProps>(null);

  const [newEcosystem, setNewEcosystem] = useState<IEcosystem | null>(null);

  const { goToRoute } = useRouter();
  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);

  const handleCloseModal = () => {
    modalRef?.current?.close();
    goToRoute(PAGES.ECOSYSTEM_SETTINGS);
  };

  const handleRightBtnAction = () =>
    goToRoute(`${PAGES.ECOSYSTEM_SETTINGS}/${newEcosystem?._id}`);

  const handleOnSubmit = async (ecosystemData: ICreateEcosystemForm) => {
    if (!organizationSelectedId) return;

    const logo = ecosystemData.logo as File[];
    let logoURL = undefined;
    if (logo[0]?.type) {
      logoURL = await uploadLogoIfProvided(logo[0]);
    }

    try {
      const { data: ecosystem } = await EcosystemService.createEcosystem({
        organization: organizationSelectedId,
        visibility: ecosystemData.isPrivate
          ? AppVisibility.Private
          : AppVisibility.Public,
        appConnections: ecosystemData.apps.map((app) => app._id),
        ...ecosystemData,
        logo: logoURL || '',
      });
      setNewEcosystem(ecosystem);
      toast.success(ECOSYSTEM_MESSAGES.SUCCESS_ON_CREATE);
      modalRef?.current?.open();
    } catch {
      toast.error(ECOSYSTEM_MESSAGES.ERROR_ON_CREATE);
    }
  };

  return {
    modalRef,
    newEcosystem,
    handleRightBtnAction,
    handleCloseModal,
    handleOnSubmit,
  };
};

import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
} from 'date-fns';

import { InsightsPeriodType } from '~/services/resources/types';

export type InsightFiltersSearchParams = ReturnType<
  typeof getInsightFiltersSearchParams
>;

const getInsightFiltersSearchParams = (searchParams: URLSearchParams) => {
  return {
    periodType: searchParams.get('periodType') as InsightsPeriodType,
    from: searchParams.get('from'),
    to: searchParams.get('to'),
    apps: (searchParams.get('apps')?.split(',') ?? []).filter((_id) => _id),
  };
};

const setInsightFiltersSearchParams = (
  searchParams: URLSearchParams,
  filters: Partial<InsightFiltersSearchParams>,
) => {
  for (const key in filters) {
    const castedFilter = filters[key as keyof typeof filters];

    if (castedFilter !== undefined) {
      if (castedFilter === null) {
        searchParams.delete(key);
        continue;
      }

      if (Array.isArray(castedFilter)) {
        searchParams.set(key, (castedFilter as string[]).join(','));
        continue;
      }

      searchParams.set(key, castedFilter as string);
    }
  }
};

export const InsightFilters = {
  get: getInsightFiltersSearchParams,
  set: setInsightFiltersSearchParams,
};

// CSV utils
const convertObjectToCSV = (objArray: Record<string, unknown>[]) => {
  if (!Array.isArray(objArray) || objArray.length === 0) {
    return '';
  }
  const headers = Object.keys(objArray[0]);
  const csvHeader = headers.join(',') + '\n';

  const csvRows = objArray.map((obj) => {
    return headers
      .map((header) => {
        // Verifica se o valor é nulo ou indefinido e o substitui por uma string vazia
        const value =
          obj[header] === null || obj[header] === undefined ? '' : obj[header];
        return typeof value === 'string' && value.includes(',')
          ? `"${value}"`
          : value;
      })
      .join(',');
  });

  return csvHeader + csvRows.join('\n');
};

const downloadCSV = (csvContent: string, fileName: string) => {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);

  link.style.visibility = 'hidden';
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
};

export const InsightCSV = {
  convertObjectToCSV,
  downloadCSV,
};

export const randomizeArray = function (arg: any) {
  const array = arg.slice();
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const dateFilterBuilder: Record<
  InsightsPeriodType,
  (startDate: Date, endDate?: Date) => [Date, Date]
> = {
  [InsightsPeriodType.Today]: (currentDate: Date) => [
    startOfDay(currentDate),
    endOfDay(currentDate),
  ],
  [InsightsPeriodType.ThisWeek]: (currentDate: Date) => [
    startOfDay(startOfWeek(currentDate)),
    endOfDay(endOfWeek(currentDate)),
  ],
  [InsightsPeriodType.ThisMonth]: (currentDate: Date) => [
    startOfDay(startOfMonth(currentDate)),
    endOfDay(endOfMonth(currentDate)),
  ],
  [InsightsPeriodType.Last7Days]: (currentDate: Date) => [
    startOfDay(subDays(currentDate, 7)),
    endOfDay(currentDate),
  ],
  [InsightsPeriodType.Last30Days]: (currentDate: Date) => [
    startOfDay(subDays(currentDate, 30)),
    endOfDay(currentDate),
  ],
  [InsightsPeriodType.Last3Months]: (currentDate: Date) => [
    startOfDay(subMonths(currentDate, 3)),
    endOfDay(currentDate),
  ],
  [InsightsPeriodType.Last6Months]: (currentDate: Date) => [
    startOfDay(subMonths(currentDate, 6)),
    endOfDay(currentDate),
  ],
  [InsightsPeriodType.Last12Months]: (currentDate: Date) => [
    startOfDay(subMonths(currentDate, 12)),
    endOfDay(currentDate),
  ],
  [InsightsPeriodType.Custom]: (startDate: Date, endDate?: Date) => [
    startOfDay(startDate),
    endOfDay(endDate!),
  ],
};

export const InsightCacheConfig = {
  staleTime: 1000 * 60 * 30, // Durante 30min apenas os dados em cache serão usados
  cacheTime: 1000 * 60 * 10, // 10 minutos no cache após ficar obsoletos/stale
  refetchOnWindowFocus: false, // Não refazer requisição ao focar na janela
  refetchOnMount: false, // Não refazer a requisição ao montar o componente
  refetchOnReconnect: false, // Não refazer a requisição ao reconectar à internet
  refetchIntervalInBackground: false, // Desativa refetch em intervalos quando em segundo plano
};

export function sanitizeQueryParams(props: Record<string, unknown>) {
  const params: Record<string, string | number> = {};

  Object.keys(props).forEach((key: string) => {
    const value = props[key];

    if (value === undefined || value === '') return;

    if (value instanceof Date) {
      params[key] = value.toISOString();
      return;
    }

    if (Array.isArray(value) || typeof value === 'object') {
      params[key] = JSON.stringify(value);
      return;
    }
    params[key] = String(value);
  });
  return params;
}

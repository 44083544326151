import { getEnvironment } from 'ui/components/v2/FeatureSwitch';

import { MenuItem } from '~/components/Layout/Drawer/types';
import { PAGES } from '~/constants/pages.constants';

export const getMenuItems = ({
  isNFP = false,
}: {
  isNFP?: boolean;
}): MenuItem[] => {
  const menuItems = [
    {
      icon: 'calendar',
      label: 'Activities',
      path: PAGES.ACTIVITIES,
    },
    {
      icon: 'users',
      label: 'Members',
      path: PAGES.MEMBERS,
    },
  ];

  if (!isNFP) {
    menuItems.unshift({
      icon: 'home',
      label: 'Home',
      path: PAGES.HOME,
    });
  }

  if (getEnvironment().isDevelopment) {
    menuItems.push({
      icon: 'bar-chart-2',
      label: 'Insights',
      path: PAGES.INSIGHTS,
    });
  }

  return menuItems;
};

import { ScrollView, Text, VStack } from 'native-base';
import { useEffect, useRef, useState } from 'react';
import { AppSelector } from 'ui/components/v2/AppInputs/AppSelector';
import { CorpCompanyProfileCard } from 'ui/components/v2/Cards/CorpCompanyProfileCard';
import { CorpSideCard } from 'ui/components/v2/Cards/CorpSideCard';
import { ActivityDefinitionTile } from 'ui/components/v2/Tiles/ActivityDefinitionTile';
import { ActivityDefinition } from 'ui/types/interfaces/activity-definition';
import { App } from 'ui/types/interfaces/app';

import Section from './components/DesignBookSection';
import { FAKE_ACTIVITY_DEFINITION } from './mocks/fake-activity-definition';
import { FAKE_APPS } from './mocks/fake-apps';

/* This page is used only for components testing */

export const DesignBook = () => {
  const STYLE_OBJECT = {
    backgroundColor: '#e5e5f7',
    backgroundSize: '50px 50px, 50px 50px, 10px 10px, 10px 10px',
    backgroundPosition: '-1px -1px, -1px -2px, -1px -1px, -1px -1px',
    backgroundImage:
      'linear-gradient(#b1aeae 1px, transparent 1px), linear-gradient(90deg, #b1aeae 1px, transparent 1px), linear-gradient(#b1aeae 1px, transparent 1px), linear-gradient(90deg, #b1aeae 1px, #dedee7 1px)',
  };

  const ref = useRef();
  const [selectedApp, setSelectedApp] = useState('');

  useEffect(() => {
    if (ref.current) {
      (ref?.current as any)?.setNativeProps({ style: STYLE_OBJECT });
    }
  }, [ref]);

  return (
    <VStack
      background={'gray.400'}
      minHeight={'100vh'}
      height={'100%'}
      width="100%"
      ref={ref}
    >
      <ScrollView borderWidth={1}>
        {/* header */}
        <VStack
          width={'100%'}
          maxWidth={'768px'}
          margin={'auto'}
          backgroundColor={'white'}
          p={{ base: 2, md: 4 }}
          mt={{ base: 0, md: 4 }}
        >
          <Text> Design Book</Text>
        </VStack>

        {/* main */}

        <VStack
          p={{ base: 1, md: 6 }}
          width={'full'}
          maxWidth={'768px'}
          margin={'auto'}
          space={4}
          mb={4}
          backgroundColor={'#ffffff99'}
        >
          {/* Corporate General */}
          <VStack>
            <Section
              title="Apps Selector"
              figmaUrl="https://www.figma.com/design/TCVhFyWKDtlNU5IrJaVObN/DoIt-Design-Working-File-[intern]?node-id=17250-30884&m=dev"
            >
              <AppSelector
                apps={FAKE_APPS as unknown as App[]}
                values={[selectedApp]}
                onChange={setSelectedApp}
              />
            </Section>

            <Section
              title="Activity Tile"
              figmaUrl="https://www.figma.com/design/TCVhFyWKDtlNU5IrJaVObN/DoIt-Design-Working-File-[intern]?node-id=17193-41671&m=dev"
            >
              <ActivityDefinitionTile
                activityDefinition={
                  FAKE_ACTIVITY_DEFINITION as unknown as ActivityDefinition
                }
              />
            </Section>

            <Section
              title="Corp Side Card"
              figmaUrl="https://www.figma.com/design/TCVhFyWKDtlNU5IrJaVObN/DoIt-Design-Working-File-[intern]?node-id=17252-31246&m=dev"
            >
              <Section title="Corp Employees Side Card" figmaUrl="">
                <CorpSideCard.Employees
                  employeesCount={160}
                  onInviteButtonPress={() => null}
                  onViewInsightsButtonPress={() => null}
                />
              </Section>
              <Section title="Corp Featured Activity Card" figmaUrl="">
                <CorpSideCard.FeaturedActivity
                  applicantsCount={548}
                  activityDefinition={
                    FAKE_ACTIVITY_DEFINITION as unknown as ActivityDefinition
                  }
                  onChangeFeaturedActivityButtonPress={() => null}
                  onSetFeaturedActivityButtonPress={() => null}
                />
              </Section>
              <Section title="Corp Apps Side Card" figmaUrl="">
                <CorpSideCard.Apps
                  apps={FAKE_APPS as unknown as App[]}
                  onViewAppsButtonPress={() => null}
                />
              </Section>
            </Section>

            <Section
              title="Corp Company Profile Card"
              figmaUrl="https://www.figma.com/design/TCVhFyWKDtlNU5IrJaVObN/DoIt-Design-Working-File-[intern]?node-id=17096-35742&m=dev"
            >
              <CorpCompanyProfileCard />
            </Section>
          </VStack>
        </VStack>
      </ScrollView>
    </VStack>
  );
};

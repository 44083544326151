import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IActionsItems } from 'ui/components/FormBuilder/components/FormAction/types';
import { ITabsRefProps } from 'ui/components/Tabs/types';

import { INVITE_COMPOSE_FORM_DEFAULT_VALUES } from '~/constants/messages.constants';
import { MemberTypeEnum } from '~/enums';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadOrganization } from '~/hooks/useLoadOrganization';
import { useLoadOrganizationEcosystems } from '~/hooks/useLoadOrganizationEcosystems';
import { addMemberActions } from '~/store/slices/addMember';
import { uploadImageToS3 } from '~/utils/uploadImageToS3';

import { useLoadSharableAppsByOrganization } from '../../../../../hooks/useLoadSharableAppsByOrganization';
import { addOrgMembersSchema } from './schema';
import {
  AddMemberTab,
  AddOrgMembersFormValues,
  TabTransitions,
  UploadComposeSettingsImages,
} from './types';

const tabTransitions: TabTransitions<AddOrgMembersFormValues> = {
  [AddMemberTab.Access]: {
    nextTab: AddMemberTab.Permissions,
    fieldsToTriggerValidation: ['ecosystems'],
  },
  [AddMemberTab.Permissions]: {
    nextTab: AddMemberTab.Compose,
    fieldsToTriggerValidation: ['partnersPermissions', 'membersPermissions'],
  },
  [AddMemberTab.Compose]: {
    nextTab: AddMemberTab.Invitation,
    fieldsToTriggerValidation: [
      'title',
      'message',
      'logo',
      'backgroundImage',
      'brandColor',
    ],
  },
  [AddMemberTab.Invitation]: {
    nextTab: undefined,
    fieldsToTriggerValidation: ['employeeEmail', 'selectedEmails'],
  },
};

interface AddMemberController {
  memberType: MemberTypeEnum;
  onSubmitInviteMember: (data: UploadComposeSettingsImages) => void;
  onSubmitPublicInviteMember: (data: UploadComposeSettingsImages) => void;
}

export const useAddOrgMembersController = ({
  memberType,
  onSubmitInviteMember,
  onSubmitPublicInviteMember,
}: AddMemberController) => {
  const [currentTab, setCurrentTab] = useState(AddMemberTab.Access);

  const tabsRef = React.useRef<ITabsRefProps>(null);

  const {
    selectedMemberPermissions,
    selectedPartnerPermissions,
    userConfigurations,
    composeSettings,
    ecosystemsToShare,
  } = useAppSelector(({ addMember }) => addMember);

  const dispatch = useAppDispatch();

  const handleClickCancel = async () => {
    if (currentTab > 0) {
      setCurrentTab((tabIndex) => tabIndex - 1);
      reset({
        ecosystems: watch('ecosystems'),
        membersPermissions: selectedMemberPermissions.map((p) => p._id),
        partnersPermissions: selectedPartnerPermissions.map((p) => p._id),
        logo: watch('logo'),
        backgroundImage: watch('backgroundImage'),
        brandColor: watch('brandColor'),
        title: watch('title'),
        message: watch('message'),
      });
    }
  };

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);

  const { data: organization, isLoading: isLoadingOrganization } =
    useLoadOrganization(organizationSelectedId || '');

  const { ecosystems, isLoadingOrganizationEcosystems } =
    useLoadOrganizationEcosystems();

  const { appsConnectedToOrganization, isLoadingAppsConnectedToOrganization } =
    useLoadSharableAppsByOrganization(organizationSelectedId);

  const isLoading =
    isLoadingOrganizationEcosystems ||
    isLoadingAppsConnectedToOrganization ||
    isLoadingOrganization;

  const currentValidationSchema = addOrgMembersSchema[currentTab];

  const { formState, control, reset, setValue, handleSubmit, watch } =
    useForm<AddOrgMembersFormValues>({
      resolver: yupResolver(currentValidationSchema),
      reValidateMode: 'onChange',
    });

  const formActions: IActionsItems[] = useMemo(() => {
    const cancelButtonProps = {
      variant: 'ghost',
      isDisabled: currentTab === AddMemberTab.Access,
      onPress: handleClickCancel,
    };

    const submitButtonProps = {
      backgroundColor:
        currentTab === AddMemberTab.Invitation ? 'primary.400' : 'primary.50',
    };

    const submitButtonTextProps = {
      color: currentTab === AddMemberTab.Invitation ? 'white' : 'primary.600',
      fontWeight: 500,
    };

    return [
      {
        id: '1',
        name: 'cancel',
        text: 'Back',
        buttonProps: cancelButtonProps,
      },
      {
        id: '2',
        name: 'continue',
        text:
          currentTab === AddMemberTab.Invitation
            ? 'Invite and share link'
            : 'Continue',
        isSubmitButton: true,
        buttonProps: submitButtonProps,
        textProps: submitButtonTextProps,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const ecosystemWithApps = useMemo(() => {
    return ecosystems?.filter((ecosystem) => {
      if (memberType === MemberTypeEnum.Partner) {
        return appsConnectedToOrganization?.some(
          (appConnected) => appConnected.receiverEcosystem === ecosystem._id,
        );
      }
      if (memberType === MemberTypeEnum.Member) {
        return ecosystem;
      }
      return [];
    });
  }, [appsConnectedToOrganization, ecosystems, memberType]);

  const handleTabTransition = () => {
    const transition = tabTransitions[currentTab];
    if (transition?.nextTab) {
      setCurrentTab(transition.nextTab);
    }
  };

  const handleSubmitCustom = handleSubmit(async () => {
    handleTabTransition();
    if (currentTab === AddMemberTab.Compose) {
      const { title, message, logo, backgroundImage, brandColor } = watch();
      const formattedBrandColor = brandColor.startsWith('#')
        ? brandColor
        : `#${brandColor}`;

      dispatch(
        addMemberActions.setComposeSettings({
          title,
          message,
          brandColor: formattedBrandColor,
          backgroundImage,
          logo,
        }),
      );
    }

    if (currentTab === AddMemberTab.Invitation) {
      let backgroundImageURL = '';
      let logoURL = '';

      if (composeSettings?.backgroundImage) {
        backgroundImageURL = await uploadImageToS3(
          composeSettings.backgroundImage as unknown as File[],
          'background image',
        );
      }

      if (composeSettings?.logo) {
        logoURL = await uploadImageToS3(
          composeSettings.logo as unknown as File[],
          'logo',
        );
      }

      if (userConfigurations.length) {
        await onSubmitInviteMember({
          backgroundImage: backgroundImageURL,
          logo: logoURL,
        });
      }
      await onSubmitPublicInviteMember({
        backgroundImage: backgroundImageURL,
        logo: logoURL,
      });
    }
  });

  useEffect(() => {
    setValue('memberType', memberType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentTab === AddMemberTab.Compose) {
      const ecosystemToShare = ecosystemsToShare[0];
      const { message, title } = watch();

      if (ecosystemToShare) {
        const findEcosystem = ecosystems?.find(
          (e) => e._id === ecosystemToShare.ecosystemId,
        );
        if (findEcosystem) {
          const { message: defaultMessage, title: defaultTitle } =
            INVITE_COMPOSE_FORM_DEFAULT_VALUES(findEcosystem.name);
          if (!message) {
            setValue('message', defaultMessage);
          }
          if (!title) {
            setValue('title', defaultTitle);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  return {
    organization,
    control,
    tabsRef,
    currentTab,
    formActions,
    formState,
    isLoading,
    appsConnectedToOrganization,
    ecosystemWithApps,
    handleTabTransition,
    handleSubmitCustom,
    setValue,
  };
};

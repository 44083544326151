import Compressor from 'compressorjs';

export const generateThumbnail = (file: File) => {
  const thumbnail = new Promise((resolve, reject) => {
    new Compressor(file, {
      maxWidth: 200,
      convertTypes: 'image/webp',
      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err);
      },
    });
  });

  return thumbnail;
};

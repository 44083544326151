import { Avatar, FlatList, HStack, Text, VStack } from 'native-base';
import { getNameInitials } from 'ui/utils/getNameInititals';

import { AwardedName } from '~/pages/Authenticated/Insights/components/AwardedName';
import { InsightsGenericEmptyState } from '~/pages/Authenticated/Insights/components/EmptyState';
import { InsightScaffold } from '~/pages/Authenticated/Insights/components/InsightScaffold';
import { useMostCompletedActivitiesController } from '~/pages/Authenticated/Insights/components/MostCompletedActivities/controller';
import { MostCompletedActivitiesSkeletonList } from '~/pages/Authenticated/Insights/components/MostCompletedActivities/MostCompletedActivitiesSkeleton';

import { MostCompletedActivitiesProps } from './types';

type MostCompletedActivitiesItemProps = {
  name: string;
  description: string;
  index: number;
  avatar?: string;
  charity: string;
  logo?: string;
};

const MostCompletedActivitiesItem = ({
  description,
  index,
  name,
  avatar,
  charity,
  logo,
}: MostCompletedActivitiesItemProps) => {
  return (
    <VStack>
      <HStack alignItems={'center'}>
        <Avatar size={10} mr={2} source={{ uri: avatar }}>
          {getNameInitials(name)}
        </Avatar>
        <VStack>
          <AwardedName name={name} index={index} />
          <Text fontSize="xl" fontWeight="medium" color={'gray.600'}>
            {description}
          </Text>
        </VStack>
      </HStack>
      <VStack space={2} mt={2}>
        <Text fontSize="xs" fontWeight="medium" color={'gray.600'}>
          Top Charity
        </Text>
        <HStack alignItems={'center'} space={2}>
          <Avatar size={6} mr={2} source={{ uri: logo }}>
            {getNameInitials(charity)}
          </Avatar>
          <Text fontSize="sm" fontWeight="regular" color={'darkText'}>
            {charity}
          </Text>
        </HStack>
      </VStack>
    </VStack>
  );
};

export const MostCompletedActivities = ({
  className,
}: MostCompletedActivitiesProps) => {
  const { usersSummaries, isLoading } = useMostCompletedActivitiesController();

  return (
    <InsightScaffold
      className={className}
      title={{
        icon: 'user-check',
        name: 'Most completed activities',
      }}
    >
      <VStack space={4} mx={6} mb={6} flex={1}>
        {isLoading ? (
          <MostCompletedActivitiesSkeletonList rows={3} />
        ) : (
          <FlatList
            height={'full'}
            keyExtractor={(item, index) => `${item?.id || index}`}
            data={usersSummaries}
            renderItem={({ item, index }) => (
              <MostCompletedActivitiesItem
                index={index}
                name={item.name}
                avatar={item.profilePicture}
                logo={item.organizationLogo}
                charity={item.organizationName}
                description={`${item.score} activities done`}
              />
            )}
            ItemSeparatorComponent={() => (
              <VStack height={'2px'} backgroundColor="gray.200" marginY={7} />
            )}
          />
        )}

        {!isLoading && !usersSummaries.length && (
          <InsightsGenericEmptyState showActionButton />
        )}
      </VStack>
    </InsightScaffold>
  );
};

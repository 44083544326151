import { useMemo } from 'react';
import {
  makeActivityTitleNode,
  makeActivityTypeBadgeTitleNode,
} from 'ui/components/v2/Cards/NotificationCard/utils';

import { userUserNotificationCardController } from '../controller';
import { ActivityApplicationNotificationCardProps } from './types';

export const useActivityApplicationNotificationCardController = (
  props: ActivityApplicationNotificationCardProps,
) => {
  const { activityDefinitionId, activityTitle, activityType } = props;

  const { date, profilePictureUrl, authorTitleNode } =
    userUserNotificationCardController(props);

  const titleNodes = useMemo(() => {
    return [
      authorTitleNode,
      { text: 'applied for' },
      makeActivityTitleNode(activityDefinitionId, activityTitle, activityType),
      makeActivityTypeBadgeTitleNode(activityType),
    ];
  }, [activityType, activityDefinitionId, activityTitle, authorTitleNode]);
  return { titleNodes, date, profilePictureUrl };
};

import { Avatar, HStack, Skeleton, Text, VStack } from 'native-base';
import { getNameInitials } from 'ui/utils/getNameInititals';
import { formatMeasurementAmount } from 'ui/utils/measurements/format';

import { AwardedName } from '~/pages/Authenticated/Insights/components/AwardedName';

type VolunteeringHourItemProps = {
  name: string;
  hours: number;
  index: number;
  avatar?: string;
  shouldRenderAvatar?: boolean;
};

export const VolunteeringHourItem = ({
  hours,
  index,
  name,
  avatar,
  shouldRenderAvatar = true,
}: VolunteeringHourItemProps) => {
  return (
    <HStack alignItems={'center'}>
      {shouldRenderAvatar && (
        <Avatar size={10} mr={2} source={{ uri: avatar }}>
          {getNameInitials(name)}
        </Avatar>
      )}
      <VStack>
        <AwardedName name={name} index={index} />
        <Text fontSize="xl" fontWeight="medium" color={'gray.600'}>
          {formatMeasurementAmount(hours)}
        </Text>
      </VStack>
    </HStack>
  );
};

export const VolunteeringHourItemSkeleton = ({
  shouldRenderAvatar,
}: Pick<VolunteeringHourItemProps, 'shouldRenderAvatar'>) => {
  return (
    <HStack alignItems={'center'}>
      {shouldRenderAvatar && <Skeleton.Circle size={10} mr={2} />}
      <VStack>
        <Skeleton.Text lines={1} w="100px" />
        <Skeleton.Text lines={1} w="50px" mt={2} />
      </VStack>
    </HStack>
  );
};

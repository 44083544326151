import { useEffect } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { GeneratingReport } from 'ui/components/GeneratingReport';
import { DevelopOnly } from 'ui/components/v2/FeatureSwitch';

import { AuthLayout } from '~/components/AuthLayout';
import { HeaderLayout } from '~/components/HeaderLayout';
import Page404 from '~/components/Page404';
import { PAGES } from '~/constants/pages.constants';
import { ActivityTypeEnum } from '~/enums';
import { useCurrentOrganization } from '~/hooks/organization/useCurrentOrganization';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useFirebaseRoutesAnalytics } from '~/hooks/useFirebaseAnalytics';
import { AccountSettings } from '~/pages/Authenticated/AccountSettings';
import { ActionSuccessful } from '~/pages/Authenticated/Actions/ActionSuccessful';
import { AddAction } from '~/pages/Authenticated/Actions/AddAction';
import { EditAction } from '~/pages/Authenticated/Actions/EditAction';
import { Activities } from '~/pages/Authenticated/Activities';
import { ActivityDetails } from '~/pages/Authenticated/Activities/ActivityDetails';
import ViewTeamActivityApplication from '~/pages/Authenticated/ActivityApplication/ViewTeamActivityApplication';
import AddChildOrg from '~/pages/Authenticated/AddChildOrg';
import { AddMember } from '~/pages/Authenticated/AddMember';
import { PublicInviteSuccess } from '~/pages/Authenticated/AddOrgMember/components/PublicInviteSuccess';
import { AddPartner } from '~/pages/Authenticated/AddPartner';
import { AppGroupPermissions } from '~/pages/Authenticated/AppGroupPermissions';
import { BulkUploads } from '~/pages/Authenticated/BulkUploads';
import { ActivitiesUpload } from '~/pages/Authenticated/BulkUploads/ActivitiesUpload';
import PlacesUpload from '~/pages/Authenticated/BulkUploads/PlacesUpload';
import { ChangeOrg } from '~/pages/Authenticated/changeOrg';
import { ChangePassword } from '~/pages/Authenticated/ChangePassword';
import { CreateEcosystem } from '~/pages/Authenticated/Ecosystem/Create';
import { EditEcosystem } from '~/pages/Authenticated/Ecosystem/Edit';
import { ManageApps } from '~/pages/Authenticated/Ecosystem/ManageApps';
import { AddEvent } from '~/pages/Authenticated/Events/AddEvent';
import { EditEvent } from '~/pages/Authenticated/Events/EditEvent';
import { EventSuccessful } from '~/pages/Authenticated/Events/EventSuccessful';
import { Insights } from '~/pages/Authenticated/Insights';
// TODO: Remove this comment when we have home page
// import { Home } from '~/pages/Authenticated/Home';
import { ManageAccess } from '~/pages/Authenticated/ManageAccess';
import Members from '~/pages/Authenticated/Members';
import { ViewChildOrg } from '~/pages/Authenticated/Members/ChildOrganisation/ViewChildOrg';
import { ViewMember } from '~/pages/Authenticated/Members/Member/ViewMember';
import { ViewPartner } from '~/pages/Authenticated/Members/Partners/ViewPartner';
import { AddOpportunity } from '~/pages/Authenticated/Opportunities/AddOpportunity';
import { EditOpportunity } from '~/pages/Authenticated/Opportunities/EditOpportunity';
import { OpportunitySuccessful } from '~/pages/Authenticated/Opportunities/OpportunitySuccess';
import Settings from '~/pages/Authenticated/Settings';
import { AdminsSettings } from '~/pages/Authenticated/Settings/AdminsSettings';
import { EcosystemDetails } from '~/pages/Authenticated/Settings/EcosystemDetails';
import { Ecosystems } from '~/pages/Authenticated/Settings/Ecosystems';
import { GeneralSettings } from '~/pages/Authenticated/Settings/General';
import Integrations from '~/pages/Authenticated/Settings/Integrations';
import { Permissions } from '~/pages/Authenticated/Settings/Permissions';
import { AddPermissionsGroup } from '~/pages/Authenticated/Settings/Permissions/AddPermissionsGroup';
import { AssignGroup } from '~/pages/Authenticated/Settings/Permissions/AssignGroup';
import { SwitchEcosystem } from '~/pages/Authenticated/SwitchEcosystem';
import { ViewTeam } from '~/pages/Authenticated/Teams/ViewTeam';
import AdminSuccessfullyJoined from '~/pages/Common/JoinAdmin/AdminSuccessfullyJoined';
import JoinAsAdminInvite from '~/pages/Common/JoinAdmin/JoinAsAdminInvite';
import JoinAsAdminInviteDeclined from '~/pages/Common/JoinAdmin/JoinAsAdminInviteDeclined';
import { JoinAsPartner } from '~/pages/Common/JoinAsPartner';
import { JoinAsPartnerInviteDeclined } from '~/pages/Common/JoinAsPartner/JoinAsAdminInviteDeclined';
import { AccountDeletedFeedback } from '~/pages/Unauthenticated/AccountDeletedFeedback';
import ConfirmEmailPage from '~/pages/Unauthenticated/SignUpPage/ConfirmEmailPage';
import RegisterOrganisation from '~/pages/Unauthenticated/SignUpPage/RegisterOrganisation';
import { CompanyRoutes } from '~/routes/Authenticated/Company';
import { NFPRoutes } from '~/routes/Authenticated/NFP';
import { DeveloperRoutes } from '~/routes/Developer';
import { redirectSliceActions } from '~/store/slices/redirect';

import { AppDetails } from '../../pages/Authenticated/Settings/AppDetails';
import { Apps } from '../../pages/Authenticated/Settings/Apps';

export const AuthenticatedRoutes = () => {
  useFirebaseRoutesAnalytics();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { redirectURL } = useAppSelector(({ redirect }) => redirect);

  const { isNFP } = useCurrentOrganization();

  const locationForActivitySuccessful =
    location.state?.locationForActivitySuccessful;

  const locationAddMemberSuccess = location.state?.addMemberSuccess;

  useEffect(() => {
    if (!redirectURL || redirectURL !== PAGES.ROOT) return;
    navigate(redirectURL);
    dispatch(redirectSliceActions.clear());
  }, [redirectURL]);

  return (
    <>
      <Routes
        location={
          locationForActivitySuccessful || locationAddMemberSuccess || location
        }
      >
        {/*Invite routes without slug */}
        <Route
          path={PAGES.INVITE_JOIN_AS_PARTNER}
          element={<JoinAsPartner />}
        />
        <Route
          path={PAGES.INVITE_JOIN_AS_PARTNER_DECLINED}
          element={<JoinAsPartnerInviteDeclined />}
        />

        {/*Invite routes with slug */}
        <Route
          path={PAGES.INVITE_JOIN_AS_PARTNER_SLUG}
          element={<JoinAsPartner />}
        />
        <Route
          path={PAGES.INVITE_JOIN_AS_PARTNER_DECLINED_SLUG}
          element={<JoinAsPartnerInviteDeclined />}
        />

        <Route path={PAGES.CHANGE_ORG} element={<ChangeOrg />} />

        <Route path={PAGES.SWITCH_ECOSYSTEM} element={<SwitchEcosystem />} />

        <Route
          element={
            <AuthLayout>
              <Outlet />
            </AuthLayout>
          }
        >
          {isNFP ? NFPRoutes() : CompanyRoutes()}

          <Route path={PAGES.SETTINGS} element={<Settings />}>
            <Route path="" element={<GeneralSettings />} />
            <Route path="ecosystems" element={<Ecosystems />} />
            <Route path="ecosystems/:id" element={<EcosystemDetails />} />
            <Route path="apps" element={<Apps />} />
            <Route path="admins" element={<AdminsSettings />} />
            <Route path="apps/:id" element={<AppDetails />} />
            <Route path="integrations" element={<Integrations />} />
            <Route path="permissions" element={<Permissions />} />
            <Route path="permissions/:id/assign" element={<AssignGroup />} />
          </Route>
          {/* Todo: Change this when Activities page is created */}
          <Route path={PAGES.ACTIVITIES} element={<Activities />} />

          <Route
            path={PAGES.INSIGHTS}
            element={
              <DevelopOnly
                fallBackElement={<Navigate to={PAGES.HOME} replace />}
              >
                <Insights />
              </DevelopOnly>
            }
          />

          <Route path={PAGES.ADD_EVENT} element={<AddEvent />} />
          <Route path={`${PAGES.EDIT_EVENT}/:id`} element={<EditEvent />} />

          <Route path={PAGES.ADD_ACTION} element={<AddAction />} />
          <Route path={`${PAGES.EDIT_ACTION}/:id`} element={<EditAction />} />

          <Route
            path={`${PAGES.ADD_ONGOING_OPPORTUNITY}`}
            element={<AddOpportunity />}
          />
          <Route
            path={`${PAGES.EDIT_ONGOING_OPPORTUNITY}/:id`}
            element={<EditOpportunity />}
          />

          <Route
            path={`${PAGES.VIEW_EVENT_ACTIVITY}/:id`}
            element={<ActivityDetails activityType={ActivityTypeEnum.Event} />}
          />
          <Route
            path={`${PAGES.VIEW_ACTION_ACTIVITY}/:id`}
            element={<ActivityDetails activityType={ActivityTypeEnum.Action} />}
          />
          <Route
            path={`${PAGES.VIEW_ONGOING_ACTIVITY}/:id`}
            element={
              <ActivityDetails
                activityType={ActivityTypeEnum.OngoingOpportunity}
              />
            }
          />

          <Route path={PAGES.CREATE_ECOSYSTEM} element={<CreateEcosystem />} />
          <Route
            path={`${PAGES.EDIT_ECOSYSTEM}/:id`}
            element={<EditEcosystem />}
          />
          <Route
            path={`${PAGES.EDIT_ECOSYSTEM}/:id/manage-apps`}
            element={<ManageApps />}
          />
          <Route path={PAGES.MEMBERS} element={<Members />} />
          <Route path={PAGES.ADD_ORG_MEMBER} element={<AddMember />} />
          <Route path={PAGES.ADD_ORG_PARTNER} element={<AddPartner />} />
          <Route path={PAGES.ADD_CHILD_ORG} element={<AddChildOrg />} />
          <Route path={PAGES.ACCOUNT_SETTINGS} element={<AccountSettings />} />
          <Route path={PAGES.VIEW_MEMBER} element={<ViewMember />} />
          <Route path={PAGES.VIEW_PARTNER} element={<ViewPartner />} />
          <Route path={PAGES.VIEW_CHILD_ORG} element={<ViewChildOrg />} />
          <Route path={`${PAGES.VIEW_TEAM}/:id`} element={<ViewTeam />} />

          <Route
            path={PAGES.APP_GROUP_PERMISSION_EDIT}
            element={<AppGroupPermissions />}
          />
          <Route
            path={PAGES.MANAGE_ORGANIZATION_ACCESS}
            element={<ManageAccess />}
          />
          <Route
            path={PAGES.NEW_PERMISSION_GROUP}
            element={<AddPermissionsGroup />}
          />
          <Route
            path={PAGES.EDIT_PERMISSION_GROUP}
            element={<AddPermissionsGroup isEditing={true} />}
          />
          {/* BULK UPLOADS */}
          <Route path={PAGES.UPLOADS} element={<BulkUploads />} />
          <Route
            path={PAGES.UPLOADS_ACTIVITIES}
            element={<ActivitiesUpload />}
          />
          <Route
            path={`${PAGES.UPLOADS_ACTIVITIES}/:id?`}
            element={<ActivitiesUpload />}
          />

          <Route
            path={`${PAGES.UPLOADS_PLACES}/:id?`}
            element={<PlacesUpload />}
          />

          {/* ACTIVITY APPLICATION */}
          <Route
            path={`${PAGES.VIEW_TEAM_ACTIVITY_APPLICATION}`}
            element={<ViewTeamActivityApplication />}
          />
        </Route>

        <Route
          element={
            <HeaderLayout>
              <Outlet />
            </HeaderLayout>
          }
        >
          <Route path={PAGES.CHANGE_PASSWORD} element={<ChangePassword />} />
          <Route
            path={PAGES.ACCOUNT_DELETED}
            element={<AccountDeletedFeedback />}
          />
          <Route
            path={PAGES.SIGN_UP_CREATE_ORGANISATION}
            element={<RegisterOrganisation />}
          />
          <Route
            path={PAGES.SIGN_UP_EMAIL_CONFIRMATION}
            element={<ConfirmEmailPage />}
          />
          <Route
            path={PAGES.INVITE_JOIN_ADMIN}
            element={<JoinAsAdminInvite />}
          />
          <Route
            path={PAGES.INVITE_ADMIN_DECLINED}
            element={<JoinAsAdminInviteDeclined />}
          />
          <Route
            path={PAGES.JOIN_ADMIN_SUCCESS}
            element={<AdminSuccessfullyJoined />}
          />
        </Route>
        <Route path={PAGES.PAGE_404} element={<Page404 />} />
        <Route path={PAGES.GENERATING_REPORT} element={<GeneratingReport />} />

        {DeveloperRoutes()}

        <Route
          path={PAGES.ROOT}
          element={<Navigate to={redirectURL || PAGES.HOME} replace />}
        />
        <Route path="*" element={<Navigate to={PAGES.PAGE_404} replace />} />
      </Routes>

      {locationForActivitySuccessful && (
        <Routes>
          <Route
            path={PAGES.ADD_EVENT_SUCCESS}
            element={<EventSuccessful pageTitle="You created a new event!" />}
          />
          <Route
            path={PAGES.EDIT_EVENT_SUCCESS}
            element={
              <EventSuccessful pageTitle="You updated your event successfully!" />
            }
          />
          <Route
            path={PAGES.ADD_ACTION_SUCCESS}
            element={<ActionSuccessful pageTitle="You created a new action!" />}
          />
          <Route
            path={PAGES.EDIT_ACTION_SUCCESS}
            element={
              <ActionSuccessful pageTitle="You updated your action successfully!" />
            }
          />
          <Route
            path={PAGES.ADD_ONGOING_OPPORTUNITY_SUCCESS}
            element={
              <OpportunitySuccessful pageTitle="You created a new ongoing opportunity!" />
            }
          />
          <Route
            path={PAGES.EDIT_ONGOING_OPPORTUNITY_SUCCESS}
            element={
              <OpportunitySuccessful pageTitle="You updated your ongoing opportunity!" />
            }
          />
        </Routes>
      )}

      {locationAddMemberSuccess ? (
        <Routes>
          <Route
            path={PAGES.ADD_ORG_MEMBER_SUCCESS}
            element={<PublicInviteSuccess />}
          />
          <Route
            path={PAGES.ADD_ORG_PARTNER_SUCCESS}
            element={<PublicInviteSuccess />}
          />
          <Route
            path={PAGES.ADD_CHILD_ORG_SUCCESS}
            element={<PublicInviteSuccess />}
          />
        </Routes>
      ) : null}
    </>
  );
};

import { AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ImageZoneImageItem } from 'ui/components/Dropzone/ImageZoneMini/types';
import { ITabsRefProps } from 'ui/components/Tabs/types';
import {
  EActivityAttendanceTypes,
  EActivityLocationTypeEnum,
  LocationOptionsEnum,
} from 'ui/enums';
import { ActivityType } from 'ui/types/activities';
import { LocationData } from 'ui/types/interfaces/activity-forms';
import { getActivityDefinitionLocationOption } from 'ui/utils/activities';
import { convertCarouselImagesToImageZone } from 'ui/utils/formatter';
import { ValidationsRegex } from 'ui/utils/validations/validations';

import { DEFAULT_MEASUREMENT_UNIT_TIME_ID } from '~/config';
import { COMMON_ERRORS } from '~/constants/error.constants';
import { OPPORTUNITY_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { ActivityTypeEnum } from '~/enums';
import { useActivityCategoryOptions } from '~/hooks/useActivityCategoriesOptions';
import { useAppSelector } from '~/hooks/useAppSelector';
import { usePermissions } from '~/hooks/usePermissions';
import { useRouter } from '~/hooks/useRouter';
import ActivitiesService from '~/services/resources/activities';
import ActivityService from '~/services/resources/activity';
import ActivityDefinitionService from '~/services/resources/activityDefinition';
import {
  IActivityCategoryDTO,
  IAddressDTO,
  IEditActivityDTO,
} from '~/types/dtos';
import { IActivity } from '~/types/interfaces/activity';
import {
  handleActivityCoverImage,
  handleCarouselImagesForActivityEdit,
} from '~/utils/activitiesImages';
import {
  transformHoursToSeconds,
  transformSecondsToHours,
} from '~/utils/functions';

import { OpprtunityFormTabPages } from './types';
import { mapMultipleLocationsFormValue, parseActivitiesData } from './utils';

export const useOpportunityFormController = () => {
  const tabsRef = useRef<ITabsRefProps>(null);
  const [formData, setFormData] = useState<IEditActivityDTO>(
    {} as IEditActivityDTO,
  );

  const [defaultLocationGroupsByDate, setDefaultLocationGroupsByDate] =
    useState<Record<number, LocationData[]>>({});

  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);

  const {
    replaceRoute,
    params: { id: eventId },
    searchParams,
  } = useRouter();

  const tabId = Number(searchParams.get('tabId'));

  const [currentTab, setCurrentTab] = useState(
    tabId || OpprtunityFormTabPages.About,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDefaultValues, setIsLoadingDefaultValues] = useState(true);
  const [activitiesIds, setActivitiesIds] = useState<string[]>([]);

  const { filterAppsForActivityCreation, validateIfOrganizationIsAppOwner } =
    usePermissions();

  const filteredAppsByPermissions = filterAppsForActivityCreation(
    ActivityType.Ongoing,
    false,
  );

  const appIdToValidatePermisssions =
    formData.selectedApp && formData?.selectedApp.length > 0
      ? (formData?.selectedApp[0] as string)
      : '';
  const isAppOwner = validateIfOrganizationIsAppOwner(
    appIdToValidatePermisssions,
  );

  const location = useLocation();
  const navigate = useNavigate();

  const { control } = useForm<IEditActivityDTO>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });

  const {
    requirementOptions,
    causeOptions,
    requirementOptionsData,
    causeOptionsData,
  } = useActivityCategoryOptions();

  const handleEditOpportunityForSingleLocation = async (
    acitivityDefinitionId: string,
    data: IEditActivityDTO,
  ) => {
    const { address, noVolunteerLimit, volunteerNumber, selectedApp } = data;
    try {
      let normalizedAddress: IAddressDTO | undefined = undefined;
      if (address?.lat && address?.lng) {
        normalizedAddress = {
          street: address.rawLocation || '',
          location: {
            type: 'Point',
            coordinates: [address.lng, address.lat],
          },
        };
      } else {
        // Filter if coordinates are undefined
        const verifiedCoordinates = [
          address?.location?.coordinates[1],
          address?.location?.coordinates[0],
        ].filter((coordinate) => !!coordinate);

        if (verifiedCoordinates && verifiedCoordinates.length === 2) {
          normalizedAddress = {
            street: address?.street || '',
            location: {
              type: (address?.location?.type as 'Point') || 'Point',
              coordinates: verifiedCoordinates as number[],
            },
          };
        } else {
          normalizedAddress = undefined;
        }
      }

      if (!normalizedAddress) throw Error('Incorrect Address Data');

      const normalizedPayloadForActivity: IEditActivityDTO = {
        activityDefinition: acitivityDefinitionId,
        volunteerNumber: !noVolunteerLimit ? volunteerNumber : null,
        organization: organizationSelectedId || '',
        app: selectedApp?.[0],
        ecosystem: selectedEcosystem?._id,
        isVolunteerNumberLimited: !noVolunteerLimit,
        publishedApps: data.publishedApps,
        address: normalizedAddress,
      };
      await ActivitiesService.editActivityEvent(
        formData._activityId as string,
        normalizedPayloadForActivity,
      );
    } catch (error: any) {
      toast.error(OPPORTUNITY_MESSAGES.CREATE_OPPORTUNITY_ERROR);
    } finally {
      setIsLoading(false);
    }
  };
  const handleEditOpportunityForMultipleLocations = async (
    acitivityDefinitionId: string,
    data: IEditActivityDTO,
  ) => {
    const { selectedApp } = data;
    const payloads: Promise<AxiosResponse<IActivity>>[] = [];
    for (const activityId of activitiesIds.values()) {
      const allLocations =
        data.locationsByGroup && Object.values(data.locationsByGroup).flat();
      const activityIdStillExists = allLocations?.find(
        (date) => date._activityId && date._activityId === activityId,
      );

      if (!activityIdStillExists) {
        payloads.push(ActivityService.deleteOne(activityId));
      }
    }
    if (data.locationsGroups) {
      for (const [index, date] of data.locationsGroups?.entries()) {
        if (data.locationsByGroup && data.locationsByGroup[index]) {
          for (const locationData of data.locationsByGroup[index]?.values()) {
            if (!locationData.location) return;
            try {
              const normalizedAddress: IAddressDTO = {
                street: locationData.returnedAddress,
                rawLocation: locationData.returnedAddress,
                location: {
                  type: 'Point',
                  coordinates: [
                    locationData.location.lng,
                    locationData.location.lat,
                  ],
                },
              };

              const normalizedPayloadForActivity = {
                organization: organizationSelectedId || '',
                ecosystem: selectedEcosystem?._id,
                activityDefinition: acitivityDefinitionId,
                address: normalizedAddress,
                volunteerNumber: date.volunteerNumber
                  ? Number(date.volunteerNumber)
                  : undefined,
                isVolunteerNumberLimited: !date.noVolunteerLimit,
                app: selectedApp?.[0],
              };
              const locationIsBeingEdited = !!locationData._activityId;

              let payload;

              if (locationIsBeingEdited) {
                payload = ActivitiesService.editActivityEvent(
                  locationData._activityId as string,
                  normalizedPayloadForActivity,
                );
              } else {
                payload = ActivitiesService.createActivityOpportunity(
                  normalizedPayloadForActivity,
                );
              }

              payloads.push(payload);
            } catch (error) {
              toast.error(OPPORTUNITY_MESSAGES.CREATE_OPPORTUNITY_ERROR);
            }
          }
        }
      }
    } else {
      throw OPPORTUNITY_MESSAGES.CREATE_OPPORTUNITY_ERROR;
    }

    await Promise.all(payloads);
  };
  const handleEditOpportunityForFromHome = async (
    acitivityDefinitionId: string,
    data: IEditActivityDTO,
  ) => {
    const { selectedApp } = data;
    try {
      // TODO: this should be at a 'constants file' elsewhere
      const defaultUKCoordinates = {
        lat: 54.092806,
        lng: -1.994255,
      };
      // Can't have blank address for Ongoing Opportunity
      const normalizedAddress = {
        street: data.meetingLink ? data.meetingLink : 'From Home',
        location: {
          type: 'Point',
          coordinates: [
            data.regions?.[0].geocenterLocation.lon || defaultUKCoordinates.lng,
            data.regions?.[0].geocenterLocation.lat || defaultUKCoordinates.lat,
          ],
        },
      };

      const basePayload: IEditActivityDTO = {
        activityDefinition: acitivityDefinitionId,
        volunteerNumber: null,
        organization: organizationSelectedId || '',
        app: selectedApp?.[0],
        ecosystem: selectedEcosystem?._id,
        isVolunteerNumberLimited: false,
        publishedApps: data.publishedApps,
        address: normalizedAddress as IAddressDTO,
        regions: data.regions,
        isOnline: data.meetingLink ? true : false,
      };

      const normalizedPayloadForActivity = data.meetingLink
        ? { ...basePayload, meetingLink: data.meetingLink }
        : basePayload;

      await ActivitiesService.editActivityEvent(
        formData._activityId as string,
        normalizedPayloadForActivity,
      );
    } catch (error: any) {
      toast.error(OPPORTUNITY_MESSAGES.CREATE_OPPORTUNITY_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const editOpportunity = async (data: IEditActivityDTO) => {
    try {
      setIsLoading(true);
      const causeOptions = causeOptionsData?.data.filter(
        (cause: IActivityCategoryDTO) => data.cause?.includes(cause._id),
      );

      let requirementOptions: IActivityCategoryDTO[] | undefined;

      if (data.requirementOptions?.length) {
        requirementOptions = requirementOptionsData?.data?.filter(
          ({ _id }: { _id: string }) => {
            // @mycatdoitbetter TODO: Fix this, this is a really bad way to do this
            // the data.requirementOptions is a string[] and the _id is a string...
            const requirementOptions =
              data.requirementOptions as unknown as string[];

            return requirementOptions.find(
              (requirementOption) => requirementOption === _id,
            );
          },
        );
      }

      const app = Array.isArray(data.selectedApp) ? data.selectedApp[0] : '';
      if (!app) {
        throw OPPORTUNITY_MESSAGES.CREATE_OPPORTUNITY_ERROR;
      }

      const { coverImageURL, thumbnailImageURL } =
        await handleActivityCoverImage(
          data.coverImage,
          data.thumbnailImage as string,
        );
      const carouselImagesUrls = await handleCarouselImagesForActivityEdit(
        data.carouselImages as ImageZoneImageItem[],
      );

      const activityDefinitionPayload: Partial<IEditActivityDTO> = {
        ...data,
        title: data.title,
        description: data.description,
        // TODO: Remove this once it is not required anymore
        // All will be national for now.
        eventType: EActivityLocationTypeEnum.National,
        coverImage: coverImageURL,
        carouselImages: carouselImagesUrls as string[],
        app: app as string,
        ecosystem: selectedEcosystem?._id,
        volunteerRewards: data.volunteerRewards,
        volunteerRequirements: data.volunteerRequirements,
        type: ActivityTypeEnum.OngoingOpportunity,
        organization: organizationSelectedId,
        spaceOptions: data.spaceOptions,
        typeOfWork: data.typeOfWork,
        causeOptions,
        requirementOptions: requirementOptions,
        meetingLink: data.meetingLink,
        publishedApps: data.publishedApps,
        measurementUnit: data.measurementUnit,
        measurementUnitPluralLabel: data.measurementUnitPluralLabel,
        locationOption: data.locationOption,
        isOnline: data.meetingLink ? true : false,
        //Attendance Type is required for Ongoing Opportunity for now
        attendanceType:
          data.locationOption === LocationOptionsEnum.FromHome
            ? EActivityAttendanceTypes.Remote
            : EActivityAttendanceTypes.InPerson,
        thumbnailImage: thumbnailImageURL,
      };

      if (data.targetAmount) {
        activityDefinitionPayload['targetAmount'] = transformHoursToSeconds(
          Number(data.targetAmount),
        );
        activityDefinitionPayload['measurementUnit'] =
          DEFAULT_MEASUREMENT_UNIT_TIME_ID;
      }
      if (
        data.locationOption === LocationOptionsEnum.FromHome &&
        data.meetingLink
      ) {
        if (!ValidationsRegex.Url.test(encodeURI(data.meetingLink))) {
          toast.error(
            'Meeting link should be a valid full url! Only https is allowed. E.g: https://example.com',
          );
          throw Error();
        }
      }

      const { data: activityDefinitionData } =
        await ActivityDefinitionService.editOpportunityActivityDefinition(
          activityDefinitionPayload._id as string,
          activityDefinitionPayload,
        );

      const locationOption = data.locationOption;

      switch (locationOption) {
        case LocationOptionsEnum.SingleLocation: {
          await handleEditOpportunityForSingleLocation(
            activityDefinitionData._id,
            data,
          );
          break;
        }
        case LocationOptionsEnum.MultipleLocations: {
          await handleEditOpportunityForMultipleLocations(
            activityDefinitionData._id,
            data,
          );
          break;
        }
        case LocationOptionsEnum.FromHome: {
          await handleEditOpportunityForFromHome(
            activityDefinitionData._id,
            data,
          );
          break;
        }
      }
      replaceRoute(`${PAGES.ACTIVITIES}`);
      setTimeout(() => {
        replaceRoute(`${PAGES.ADD_ONGOING_OPPORTUNITY_SUCCESS}`, {
          state: {
            locationForActivitySuccessful: location,
            activity: activityDefinitionData,
          },
        });
      }, 500);
    } catch (error) {
      toast.error(OPPORTUNITY_MESSAGES.CREATE_OPPORTUNITY_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const loadData = useCallback(async () => {
    try {
      if (!eventId) return;
      const { data: activityDefinition, status: activityDefinitionStatus } =
        await ActivityDefinitionService.get(eventId);

      const {
        data: allActivitiesByDefinition,
        status: allActivitiesByDefinitionStatus,
      } = await ActivityService.getAllByDefinition(activityDefinition._id);

      if (
        activityDefinitionStatus === StatusCodes.OK &&
        allActivitiesByDefinitionStatus === StatusCodes.OK
      ) {
        const idsToSave = allActivitiesByDefinition.data.map(
          (activity: IEditActivityDTO) => {
            return activity._id;
          },
        );
        setActivitiesIds(idsToSave);

        const activityLocationOption = getActivityDefinitionLocationOption(
          activityDefinition.locationOption,
          allActivitiesByDefinition.data.length,
        );

        const parsedFormData: IEditActivityDTO = {
          ...activityDefinition,
          // @mycatdoitbetter TODO: Fix this type
          cause: activityDefinition.causeOptions.map(
            (cause: { _id: string }) => cause._id,
          ),
          volunteerRequirements: activityDefinition.volunteerRequirements,
          spaceOptions: activityDefinition.spaceOptions,
          typeOfWork: activityDefinition.typeOfWork,
          attendanceType: activityDefinition.attendanceType,
          selectedApp: [activityDefinition.app],
          // @mycatdoitbetter  TODO: Fix this type
          requirementOptions: activityDefinition.requirementOptions.map(
            (option: { _id: string }) => option._id,
          ),
          locationOption: activityLocationOption,
          carouselImages: convertCarouselImagesToImageZone(
            activityDefinition.carouselImages,
          ),
        };

        // Populate the form data early with activity definition data.
        setFormData(parsedFormData);

        if (activityDefinition.targetAmount) {
          parsedFormData['targetAmount'] = transformSecondsToHours(
            activityDefinition.targetAmount,
          );
        }

        switch (activityLocationOption) {
          case LocationOptionsEnum.SingleLocation: {
            const parsedActivity = parseActivitiesData(
              allActivitiesByDefinition.data,
            );

            setFormData({ ...parsedFormData, ...parsedActivity });
            break;
          }
          case LocationOptionsEnum.MultipleLocations: {
            const { locationGroups, locationGroupsByVolunteerLimit } =
              mapMultipleLocationsFormValue(allActivitiesByDefinition.data);
            setDefaultLocationGroupsByDate(locationGroupsByVolunteerLimit);

            setFormData({
              ...parsedFormData,
              locationsGroups: locationGroups,
            });
            break;
          }
          case LocationOptionsEnum.FromHome: {
            const parsedActivity = parseActivitiesData(
              allActivitiesByDefinition.data,
            );

            setFormData({ ...parsedFormData, ...parsedActivity });
            break;
          }
          default:
            setFormData(parsedFormData);
            throw new Error(COMMON_ERRORS.ERROR_ON_LOAD_ACTIVITIES);
        }
      } else {
        throw new Error(COMMON_ERRORS.ERROR_ON_LOAD_EVENT);
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsLoadingDefaultValues(false);
    }
  }, [eventId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleContinue = (data: IEditActivityDTO) => {
    setFormData((prev: IEditActivityDTO) => ({ ...prev, ...data }));

    if (tabsRef.current?.currentTabIndex !== 2) {
      tabsRef.current?.nextStep();
      setCurrentTab((prev) => prev + 1);
    } else {
      editOpportunity({ ...formData, ...data });
    }
  };

  const handleBack = () => {
    if (
      tabsRef.current?.currentTabIndex &&
      tabsRef.current?.currentTabIndex > 0
    ) {
      tabsRef.current?.backStep();
      setCurrentTab((prev) => prev - 1);
    } else {
      navigate(-1);
    }
  };

  const handleUpdateLocationOption = (locationOption: LocationOptionsEnum) => {
    setFormData((prev: IEditActivityDTO) => ({ ...prev, locationOption }));
  };

  const currentLocationOption = formData.locationOption;
  return {
    tabsRef,
    currentTab,
    causeOptions,
    requirementOptions,
    handleContinue,
    handleBack,
    isLoading,
    formData,
    control,
    isLoadingDefaultValues,
    filteredAppsByPermissions,
    appIdToValidatePermisssions,
    handleUpdateLocationOption,
    isAppOwner,
    defaultLocationGroupsByDate,
    currentLocationOption,
  };
};

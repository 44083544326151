import { toast } from 'react-toastify';
import { generateThumbnail } from 'ui/utils/generateThumbnail';

import { DEFAULT_AWS_PUBLIC_BUCKET } from '~/config';
import { UPLOAD_IMAGE_ERROR } from '~/constants/messages.constants';
import { UploadsSignedURLObjectTarget } from '~/services/resources/types';
import { UploadsService } from '~/services/resources/uploads';

export const uploadThumbnailToS3 = async (image: File[], fieldName: string) => {
  let imageURL = '';

  if (image?.length && image[0]?.type) {
    try {
      const thumbnail = (await generateThumbnail(image[0])) as File;
      const signedURLResponse = await UploadsService.generatePreSignedURL({
        Target: UploadsSignedURLObjectTarget.OrganizationPicture,
        FileExtension: thumbnail.type.split('/').pop() || '',
      });

      imageURL = `${DEFAULT_AWS_PUBLIC_BUCKET}/${signedURLResponse.Key}`;

      await UploadsService.uploadFileToS3({
        file: thumbnail,
        signedURL: signedURLResponse,
      });
    } catch {
      toast.error(UPLOAD_IMAGE_ERROR(fieldName));
      throw new Error(UPLOAD_IMAGE_ERROR(fieldName));
    }
  }

  return imageURL;
};

import { ScrollView, Text, VStack } from 'native-base';
import { useEffect, useRef } from 'react';
import { ActivityTypeBadge } from 'ui/components/v2/Badges/ActivityTypeBadge';
import { NotificationCard } from 'ui/components/v2/Cards/NotificationCard';
import { ActivitySemanticType, ActivityType } from 'ui/types/activities';
import { MeasurementUnit } from 'ui/types/measurementUnit';

import Section from './components/DesignBookSection';
import { FAKE_ACTIVITY_DEFINITION } from './mocks/fake-activity-definition';
import { FAKE_MEASUREMENT_UNITIES } from './mocks/fake-measurement-unities';
import { FAKE_ORGANIZATION } from './mocks/fake-organization';
import { FAKE_USER_PROFILE } from './mocks/fake-user-profile';

/* This page is used only for components testing */

export const NotificationsDesignBook = () => {
  const STYLE_OBJECT = {
    backgroundColor: '#e5e5f7',
    backgroundSize: '50px 50px, 50px 50px, 10px 10px, 10px 10px',
    backgroundPosition: '-1px -1px, -1px -2px, -1px -1px, -1px -1px',
    backgroundImage:
      'linear-gradient(#b1aeae 1px, transparent 1px), linear-gradient(90deg, #b1aeae 1px, transparent 1px), linear-gradient(#b1aeae 1px, transparent 1px), linear-gradient(90deg, #b1aeae 1px, #dedee7 1px)',
  };

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      (ref?.current as any)?.setNativeProps({ style: STYLE_OBJECT });
    }
  }, [ref]);

  return (
    <VStack
      background={'gray.400'}
      minHeight={'100vh'}
      height={'100%'}
      width="100%"
      ref={ref}
    >
      <ScrollView borderWidth={1}>
        {/* header */}
        <VStack
          width={'100%'}
          maxWidth={'768px'}
          margin={'auto'}
          backgroundColor={'white'}
          p={{ base: 2, md: 4 }}
          mt={{ base: 0, md: 4 }}
        >
          <Text> Notifications</Text>
        </VStack>

        {/* main */}

        <VStack
          p={{ base: 1, md: 6 }}
          width={'full'}
          maxWidth={'768px'}
          margin={'auto'}
          space={4}
          mb={4}
          backgroundColor={'#ffffff99'}
        >
          {/* Corporate Notifications */}
          <VStack>
            <Section
              title="Notifications"
              figmaUrl="https://www.figma.com/design/TCVhFyWKDtlNU5IrJaVObN/DoIt-Design-Working-File-[intern]?node-id=17252-31246&m=dev"
            >
              <Section
                title="Notification Card"
                figmaUrl="https://www.figma.com/design/TCVhFyWKDtlNU5IrJaVObN/DoIt-Design-Working-File-[intern]?node-id=17193-41511&m=dev"
              >
                <NotificationCard.Base
                  authorImageUrl="https://ichef.bbci.co.uk/ace/ws/640/cpsprodpb/1601/live/0caf7060-2963-11ef-951f-bfad685c889d.jpg.webp"
                  titleNodes={[
                    {
                      text: 'Lucas',
                      linkUrl: 'www.google.com.br',
                    },
                    { text: 'logged' },
                    {
                      text: '10 pieces of rubbish',
                      fontWeight: 500,
                    },
                    {
                      text: 'on an activity',
                    },
                    {
                      customRender: (
                        <ActivityTypeBadge
                          activityType={ActivitySemanticType.Action}
                          defaultSize="sm"
                        />
                      ),
                    },
                  ]}
                  date={new Date('2024-07-12t12:00')}
                />
              </Section>

              <Section
                title="New Member Notification Card"
                figmaUrl="https://www.figma.com/design/TCVhFyWKDtlNU5IrJaVObN/DoIt-Design-Working-File-[intern]?node-id=17193-41511&m=dev"
              >
                <NotificationCard.UserProfileCreated
                  userName={FAKE_USER_PROFILE.userSummary.name}
                  userProfileId={FAKE_USER_PROFILE._id}
                  profilePictureUrl={FAKE_USER_PROFILE.profilePicture}
                  ecosystemName={'Meu ecosystem fake'}
                  date={new Date()}
                />
              </Section>

              <Section
                title="Activity Application Notification Card"
                figmaUrl="https://www.figma.com/design/TCVhFyWKDtlNU5IrJaVObN/DoIt-Design-Working-File-[intern]?node-id=17193-41511&m=dev"
              >
                <VStack space={4}>
                  <NotificationCard.ActivityApplicationCreated
                    userName={FAKE_USER_PROFILE.userSummary.name}
                    userProfileId={FAKE_USER_PROFILE._id}
                    profilePictureUrl={FAKE_USER_PROFILE.profilePicture}
                    //specialization
                    activityDefinitionId={FAKE_ACTIVITY_DEFINITION._id}
                    activityTitle={FAKE_ACTIVITY_DEFINITION.title}
                    activityType={ActivityType.Action}
                    date={new Date('2024-07-12t12:00')}
                  />

                  <NotificationCard.ActivityApplicationCreated
                    userName={FAKE_USER_PROFILE.userSummary.name}
                    userProfileId={FAKE_USER_PROFILE._id}
                    profilePictureUrl={FAKE_USER_PROFILE.profilePicture}
                    activityDefinitionId={FAKE_ACTIVITY_DEFINITION._id}
                    activityTitle={FAKE_ACTIVITY_DEFINITION.title}
                    activityType={ActivityType.Event}
                    date={new Date('2024-06-12t12:00')}
                  />

                  <NotificationCard.ActivityApplicationCreated
                    userName={FAKE_USER_PROFILE.userSummary.name}
                    userProfileId={FAKE_USER_PROFILE._id}
                    profilePictureUrl={FAKE_USER_PROFILE.profilePicture}
                    activityDefinitionId={FAKE_ACTIVITY_DEFINITION._id}
                    activityTitle={FAKE_ACTIVITY_DEFINITION.title}
                    activityType={ActivityType.Ongoing}
                    date={new Date()}
                  />

                  <NotificationCard.ActivityApplicationCanceled
                    userName={FAKE_USER_PROFILE.userSummary.name}
                    userProfileId={FAKE_USER_PROFILE._id}
                    profilePictureUrl={FAKE_USER_PROFILE.profilePicture}
                    activityDefinitionId={FAKE_ACTIVITY_DEFINITION._id}
                    activityTitle={FAKE_ACTIVITY_DEFINITION.title}
                    activityType={ActivityType.Ongoing}
                    date={new Date()}
                  />
                </VStack>
              </Section>
            </Section>

            <Section title="Activity Application Log Notification Card">
              <VStack w="full" space={2}>
                <Section title="Targetless">
                  <NotificationCard.MeasurementCreated
                    date={new Date()}
                    activityType={ActivityType.Event}
                    activityTitle={FAKE_ACTIVITY_DEFINITION.title}
                    activityAppName={FAKE_ACTIVITY_DEFINITION.app.name}
                    activityOrganizationName={
                      FAKE_ACTIVITY_DEFINITION.organizationSubDocument.name
                    }
                    measurementUnit={
                      FAKE_MEASUREMENT_UNITIES[0] as unknown as MeasurementUnit
                    }
                    measurementsAmount={100}
                    measurementsTarget={undefined}
                    profilePictureUrl={FAKE_USER_PROFILE.profilePicture}
                    userName={FAKE_USER_PROFILE.userSummary.name}
                    userProfileId={FAKE_USER_PROFILE._id}
                  />
                </Section>

                <Section title="With Target">
                  <NotificationCard.MeasurementCreated
                    date={new Date()}
                    activityType={ActivityType.Ongoing}
                    activityTitle={FAKE_ACTIVITY_DEFINITION.title}
                    activityAppName={FAKE_ACTIVITY_DEFINITION.app.name}
                    activityOrganizationName={
                      FAKE_ACTIVITY_DEFINITION.organizationSubDocument.name
                    }
                    measurementUnit={
                      FAKE_MEASUREMENT_UNITIES[1] as unknown as MeasurementUnit
                    }
                    measurementsAmount={100}
                    measurementsTarget={200}
                    profilePictureUrl={FAKE_USER_PROFILE.profilePicture}
                    userName={FAKE_USER_PROFILE.userSummary.name}
                    userProfileId={FAKE_USER_PROFILE._id}
                  />
                </Section>

                <Section title="Completion">
                  <NotificationCard.MeasurementCreated
                    date={new Date()}
                    activityType={ActivityType.Action}
                    activityTitle={FAKE_ACTIVITY_DEFINITION.title}
                    activityAppName={FAKE_ACTIVITY_DEFINITION.app.name}
                    activityOrganizationName={
                      FAKE_ACTIVITY_DEFINITION.organizationSubDocument.name
                    }
                    measurementUnit={
                      FAKE_MEASUREMENT_UNITIES[2] as unknown as MeasurementUnit
                    }
                    measurementsAmount={2}
                    measurementsTarget={4}
                    profilePictureUrl={FAKE_USER_PROFILE.profilePicture}
                    userName={FAKE_USER_PROFILE.userSummary.name}
                    userProfileId={FAKE_USER_PROFILE._id}
                  />
                </Section>
              </VStack>
            </Section>

            <Section title="New Activity Created Notification Cards">
              <VStack w="full" space={2}>
                <Section title="Event Activity Created">
                  <NotificationCard.ActivityCreated
                    date={new Date()}
                    activityStartDate={new Date()}
                    activityType={ActivityType.Event}
                    activityTitle={FAKE_ACTIVITY_DEFINITION.title}
                    activityAppName={FAKE_ACTIVITY_DEFINITION.app.name}
                    activityDefinitionId={FAKE_ACTIVITY_DEFINITION._id}
                    activityOrganizationName={
                      FAKE_ACTIVITY_DEFINITION.organizationSubDocument.name
                    }
                    activityOrganizationLogoUrl={FAKE_ORGANIZATION.logo}
                    activityCoverImageUrl={FAKE_ACTIVITY_DEFINITION.coverImage}
                    organizationName={FAKE_ORGANIZATION.name}
                    organizationLogoUrl={FAKE_ORGANIZATION.logo}
                    organizationId={FAKE_ORGANIZATION._id}
                  />
                </Section>

                <Section title="Ongoing Activity Created">
                  <NotificationCard.ActivityCreated
                    date={new Date()}
                    activityStartDate={new Date()}
                    activityType={ActivityType.Ongoing}
                    activityTitle={FAKE_ACTIVITY_DEFINITION.title}
                    activityAppName={FAKE_ACTIVITY_DEFINITION.app.name}
                    activityDefinitionId={FAKE_ACTIVITY_DEFINITION._id}
                    activityOrganizationName={
                      FAKE_ACTIVITY_DEFINITION.organizationSubDocument.name
                    }
                    activityOrganizationLogoUrl={FAKE_ORGANIZATION.logo}
                    activityCoverImageUrl={FAKE_ACTIVITY_DEFINITION.coverImage}
                    organizationName={FAKE_ORGANIZATION.name}
                    organizationLogoUrl={FAKE_ORGANIZATION.logo}
                    organizationId={FAKE_ORGANIZATION._id}
                  />
                </Section>

                <Section title="Action Activity Created">
                  <NotificationCard.ActivityCreated
                    date={new Date()}
                    activityType={ActivityType.Action}
                    activityTitle={FAKE_ACTIVITY_DEFINITION.title}
                    activityAppName={FAKE_ACTIVITY_DEFINITION.app.name}
                    activityDefinitionId={FAKE_ACTIVITY_DEFINITION._id}
                    activityOrganizationName={
                      FAKE_ACTIVITY_DEFINITION.organizationSubDocument.name
                    }
                    activityTargetAmount={FAKE_ACTIVITY_DEFINITION.targetAmount}
                    activityOrganizationLogoUrl={FAKE_ORGANIZATION.logo}
                    activityCoverImageUrl={FAKE_ACTIVITY_DEFINITION.coverImage}
                    activityMeasurementUnit={
                      FAKE_MEASUREMENT_UNITIES[1] as unknown as MeasurementUnit
                    }
                    organizationName={FAKE_ORGANIZATION.name}
                    organizationLogoUrl={FAKE_ORGANIZATION.logo}
                    organizationId={FAKE_ORGANIZATION._id}
                  />
                </Section>
                <Section title="Bulk Activities Created">
                  <NotificationCard.BulkActivitiesCreated
                    date={new Date()}
                    activitiesAppName={FAKE_ACTIVITY_DEFINITION.app.name}
                    organizationName={
                      FAKE_ACTIVITY_DEFINITION.organizationSubDocument.name
                    }
                    organizationLogoUrl={FAKE_ORGANIZATION.logo}
                    organizationId={FAKE_ORGANIZATION._id}
                    activitiesCount={20}
                  />
                </Section>
              </VStack>
            </Section>
          </VStack>
        </VStack>
      </ScrollView>
    </VStack>
  );
};

import { HStack, PresenceTransition, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { CorpSideCardLoadingSkeleton } from './fragments/LoadingSkeleton';
import { CorpSideCardProps } from './types';

export const CorpSideCard = ({
  children,
  isLoading,
  title = '',
  titleIcon = '',
  backgroundColor = 'gray.100',
  onPrimaryAction,
  onSecondaryAction,
  primaryActionIcon,
  primaryActionLabel,
  secondaryActionLabel,
  showPrimaryAction = true,
  showSecondaryAction = true,
}: CorpSideCardProps) => {
  return (
    <VStack
      w="full"
      borderWidth={1}
      borderRadius={16}
      overflow={'hidden'}
      borderColor={'gray.200'}
      backgroundColor={backgroundColor}
    >
      {isLoading ? (
        <CorpSideCardLoadingSkeleton />
      ) : (
        <VStack p={4} space={4}>
          {/* Header */}
          <HStack alignItems={'center'} space={3}>
            {/* Leading */}
            <Icon icon={titleIcon} size={'20px'} color={colors.gray[600]} />

            {/* title */}
            <Text
              color={'gray.600'}
              numberOfLines={1}
              fontSize={'md'}
              fontWeight={500}
            >
              {title}
            </Text>
          </HStack>
          {/* body */}
          <PresenceTransition
            visible={true}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 250 } }}
          >
            <VStack>{children}</VStack>
          </PresenceTransition>
          {/* footer */}
          <VStack h="100%" space={'10px'}>
            {onPrimaryAction && showPrimaryAction ? (
              <Button
                leftIconSize={24}
                leftIconName={primaryActionIcon}
                onPress={onPrimaryAction}
                backgroundColor={'primary.400'}
                py={'21px'}
              >
                <Text
                  numberOfLines={1}
                  fontSize={{ base: 'sm', md: 'md' }}
                  fontWeight="500"
                  color="white"
                >
                  {primaryActionLabel}
                </Text>
              </Button>
            ) : null}

            {onSecondaryAction && showSecondaryAction ? (
              <Button onPress={onSecondaryAction} variant={'ghost'}>
                <HStack
                  justifyContent={'center'}
                  alignItems={'center'}
                  space="0.65rem"
                >
                  <Text
                    color={colors.black}
                    numberOfLines={1}
                    fontSize={{ base: 'sm', md: 'md' }}
                    fontWeight={500}
                  >
                    {secondaryActionLabel}
                  </Text>
                  <Icon
                    enableAutoCustom
                    icon={'chevron-right'}
                    size={'22px'}
                    color={colors.black}
                  />
                </HStack>
              </Button>
            ) : null}
          </VStack>
        </VStack>
      )}
    </VStack>
  );
};

import { HStack, Image, Text, VStack } from 'native-base';
import { useEffect, useRef } from 'react';

import { CoverImageCaptionProps } from './types';

const STYLE_OBJECT = {
  backgroundImage:
    'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.85) 100%)',
};

export const CoverImageCaption = ({
  text = '',
  leadingImage,
}: CoverImageCaptionProps) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      (ref?.current as any)?.setNativeProps({ style: STYLE_OBJECT });
    }
  }, [ref]);

  return (
    <VStack
      position={'absolute'}
      top={0}
      left={0}
      width={'full'}
      height={'full'}
      zIndex={999}
      ref={ref}
      justifyContent="flex-end"
    >
      <HStack px={3} py={4} space={2} alignItems={'center'}>
        {leadingImage && (
          <Image
            w="24px"
            h="24px"
            resizeMode="contain"
            alt={text}
            source={{ uri: leadingImage || '' }}
            borderRadius={8}
            borderWidth={1}
            borderColor={'gray.400'}
          />
        )}
        <Text color="white" fontSize={'0.625rem'} fontWeight={500}>
          {text}
        </Text>
      </HStack>
    </VStack>
  );
};

import ApexCharts from 'apexcharts';
import { Box, HStack, IconButton, Text } from 'native-base';
import React, { useEffect, useRef } from 'react';
import Icon from 'ui/components/Icon';

import { InsightCSV } from '~/pages/Authenticated/Insights/utils';

export type InsightChartData = Record<string, unknown>;

export type InsightScaffoldChildrenReactComponent = (props: {
  onSetChart: (
    chartInstance: ApexCharts | null,
    chartData?: InsightChartData[],
  ) => void;
}) => JSX.Element;

export type InsightScaffoldProps = {
  // Obrigatorio pra definir os estilos do grid via css
  className: string;
  title: {
    icon: string;
    name: string;
  };
  chartData?: InsightChartData[];
  chartInstance?: ApexCharts | null;
} & (
  | { children: React.ReactNode }
  // Pro caso de usarmos um chat com tabs (diferentes charts)
  | {
      children: InsightScaffoldChildrenReactComponent;
    }
);

export const InsightScaffold = ({
  className,
  title,
  children,
  chartData,
  chartInstance,
}: InsightScaffoldProps) => {
  const apexChatRef = useRef<ApexCharts>(chartInstance ?? null);
  const chartDataRef = useRef<InsightChartData[]>(chartData ?? null);

  useEffect(() => {
    // @ts-expect-error - set ref
    chartDataRef.current = chartData;
  }, [chartData]);

  const handleDownloadCSV = () => {
    if (!apexChatRef.current && !chartDataRef.current) {
      throw new Error('No chart defined');
    }

    if (apexChatRef.current) {
      //@ts-expect-error - access global values
      return apexChatRef.current?.exportToCSV();
    }
    InsightCSV.downloadCSV(
      InsightCSV.convertObjectToCSV(chartDataRef.current!),
      `export_${Date.now()}.csv`,
    );
  };

  // Funcão pra setar os dados pra download
  const onSetChart = (
    chartInstance: ApexCharts | null,
    chartDataInstance?: InsightChartData[],
  ) => {
    // @ts-expect-error - set ref
    apexChatRef.current = chartInstance;
    // @ts-expect-error - set ref
    chartDataRef.current = chartDataInstance ?? null;
  };

  return (
    <div className={className}>
      <Box
        rounded="lg"
        backgroundColor="white"
        borderColor="coolGray.200"
        _web={{
          shadow: 2,
          borderWidth: 0,
        }}
        height={'full'}
      >
        <IconButton
          position="absolute"
          icon={<Icon size={16} icon="download" color={'gray.800'} />}
          borderRadius="md"
          borderColor={'gray.200'}
          borderWidth={'1'}
          top={3}
          right={'3.5'}
          padding={2}
          onPress={handleDownloadCSV}
          zIndex={5}
        />
        <HStack alignItems={'center'} space={2} p={6}>
          <Icon size={20} icon={title.icon} isCustom />
          <Text color={'gray.600'} fontSize={'md'} fontWeight={500}>
            {title.name}
          </Text>
        </HStack>
        {typeof children === 'function' ? children({ onSetChart }) : children}
      </Box>
    </div>
  );
};

import { Box } from 'native-base';
import BlackDoitLogo from 'ui/assets/illustrations/BlackDoitLogo.svg';

export const Header = () => (
  <Box bg="white" py="4" minH="82px" h="82px">
    <Box h="100%" width="min(90%, 1280px)" mx="auto" justifyContent="center">
      <Box w="70px">
        <img alt="Doit Logo" src={BlackDoitLogo} width="100%" height="100%" />
      </Box>
    </Box>
  </Box>
);

export const FAKE_ORGANIZATION = {
  _id: '659351f8ea6975704c8df57d',
  name: 'NCVO ',
  description: '',
  type: 'NFP',
  admins: [],
  deleted: false,
  causeOptions: [],
  logo: 'https://s3.eu-west-2.amazonaws.com/doit2.0-staging-uploads/OrganizationPicture/ad9d0fd0-3348-40bb-b54e-b7df67106198_2024-06-20T13:48:14.172Z.png',
  contactEmail: 'help@doit.life',
  contactPhoneNumber: '+447949359231',
  purpose:
    'Championing the remarkable role of charities and volunteers.\n\nInspired and empowered by over 16,000 members, we connect, represent and support voluntary organisations, from the smallest community groups to the largest charities',
  termsOfServicesLink: '',
  websiteLink: 'https://www.doit.foundation/',
  apiKey: 'cy26g2e5v7',
  logoThumbnail:
    'https://s3.eu-west-2.amazonaws.com/doit2.0-staging-uploads/ActivityPicture/thumbnail_ad9d0fd0-3348-40bb-b54e-b7df67106198_2024-04-14T16%3A49%3A38.593Z.webp',
};

import { Box, HStack, Image, Pressable, Stack, Text } from 'native-base';
import PlaceHolder from 'ui/assets/svgs/placeholder.svg';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import { colors } from 'ui/theme/colors';

import { MenuDropdown } from '~/components/MenuDropdown';

import { HeaderAuthenticatedProps } from './types';

export const HEADER_HEIGHT = '82px';

export const HeaderAuthenticated = ({
  ecosystemLogo,
  ecosystemName,
  onLogoPress,
}: HeaderAuthenticatedProps) => {
  return (
    <Box
      w="full"
      h={HEADER_HEIGHT}
      shadow={2}
      borderBottomWidth={1}
      borderBottomColor={colors.gray[100]}
    >
      <HStack
        bg="white"
        w="full"
        justifyContent={'space-between'}
        alignItems={'center'}
        flex={1}
        px={{ base: 4, lg: 12 }}
      >
        <Pressable onPress={onLogoPress}>
          <Box flexDir="row" style={{ gap: 12 }} alignItems={'center'}>
            <Image
              alt="Ecosystem Logo"
              src={ecosystemLogo || PlaceHolder}
              width={{ base: '42px', md: '50px' }}
              height={{ base: '42px', md: '50px' }}
              borderRadius={100}
            />
            {ecosystemName ? (
              <Stack>
                <Text fontSize={{ base: 'sm', md: 'md' }} fontWeight={700}>
                  {ecosystemName}
                </Text>
                <HStack space={2} alignItems="center">
                  <Text
                    fontSize={{ base: 'xs', md: 'sm' }}
                    fontWeight={500}
                    color="gray.400"
                  >
                    Powered by
                  </Text>
                  <Illustration
                    name={ILLUSTRATIONS_NAME.DOIT_TRANSPARENT_LOGO}
                    width={28}
                    height={15}
                    tintColor={colors.gray[400]}
                  />
                </HStack>
              </Stack>
            ) : null}
          </Box>
        </Pressable>

        <HStack space={2} alignItems="center">
          <MenuDropdown />
        </HStack>
      </HStack>
    </Box>
  );
};

import { api } from '~/services/api';
import {
  BaseInsightQueryParams,
  CharitiesReportResponse,
  InsightActivityApplicationGroupedBy,
  InsightByPeriodTypeQueryParams,
} from '~/services/resources/types';

const MODULE_URL = 'org-admin/insights';

type GetFavoriteCausesResponse = {
  total: number;
  causeOption: string;
};

type GetEngagementUsersReportResponse = {
  currentPeriodInsights: {
    applications: {
      total: number;
      date: string;
    }[];
    totalApplications: number;
  };
  previousPeriodInsights: {
    totalApplications: number;
  };
  percentageVariation: number;
};

export type GetApplicationsReportQueryParams = BaseInsightQueryParams & {
  groupBy: InsightActivityApplicationGroupedBy[];
};

type GetApplicationDatesReportResponse = {
  total: number;
  date: string;
  activityType: string;
};

type GetPreferableActiviesResponse = {
  total: number;
  activityType: string;
};

export default class InsightActivityApplicationService {
  static FAVORITE_CAUSES_KEY = `${MODULE_URL}/applications/favorite-causes`;
  static async getFavoriteCauses({
    organization,
    apps,
    ...restQueryParams
  }: BaseInsightQueryParams) {
    const response = await api.get<GetFavoriteCausesResponse[]>(
      `${MODULE_URL}/${organization}/applications/favorite-causes`,
      {
        params: {
          apps: apps.length ? JSON.stringify(apps) : undefined,
          ...restQueryParams,
        },
      },
    );

    return response.data ?? [];
  }

  static ENGAGEMENT_USERS_REPORT = `${MODULE_URL}/applications/employee-engagement`;
  static async getEngagementUsersReport({
    apps,
    organization,
    ...restQueryParams
  }: InsightByPeriodTypeQueryParams) {
    const response = await api.get<GetEngagementUsersReportResponse>(
      `${MODULE_URL}/${organization}/applications/employee-engagement`,
      {
        params: {
          apps: apps.length ? JSON.stringify(apps) : undefined,
          ...restQueryParams,
        },
      },
    );

    return response.data ?? [];
  }

  static APPLICATIONS_CHARITIES_HELPED_REPORT_KEY = `${MODULE_URL}/applications/charities-helped`;
  static async getApplicationsCharitiesHelpedReport({
    organization,
    apps,
    ...restQueryParams
  }: InsightByPeriodTypeQueryParams) {
    const response = await api.get<CharitiesReportResponse>(
      `${MODULE_URL}/${organization}/applications/charities-helped`,
      {
        params: {
          apps: apps.length ? JSON.stringify(apps) : undefined,
          ...restQueryParams,
        },
      },
    );

    return response.data;
  }

  static APPLICATION_DATE_REPORT_KEY = `${MODULE_URL}/applications/dates`;
  static async getApplicationDatesReport({
    organization,
    apps,
    groupBy,
    ...restQueryParams
  }: GetApplicationsReportQueryParams) {
    const response = await api.get<GetApplicationDatesReportResponse[]>(
      `${MODULE_URL}/${organization}/applications`,
      {
        params: {
          apps: apps.length ? JSON.stringify(apps) : undefined,
          groupBy: groupBy.length ? JSON.stringify(groupBy) : undefined,
          ...restQueryParams,
        },
      },
    );

    return response.data ?? [];
  }

  static GET_APPLICATIONS_REPORT = `${MODULE_URL}/applications`;
  static async getPreferableActivies({
    organization,
    apps,
    ...restQueryParams
  }: BaseInsightQueryParams) {
    const response = await api.get<GetPreferableActiviesResponse[]>(
      `${MODULE_URL}/${organization}/applications`,
      {
        params: {
          apps: apps.length ? JSON.stringify(apps) : undefined,
          groupBy: JSON.stringify([
            InsightActivityApplicationGroupedBy.ActivityType,
          ]),
          ...restQueryParams,
        },
      },
    );

    return response.data ?? [];
  }
}

import { yupResolver } from '@hookform/resolvers/yup';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { SIGN_UP_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';
import {
  defaultValues,
  schemaValidation,
} from '~/pages/Unauthenticated/SignUpPage/constants';
import { ISignUpFormFields } from '~/pages/Unauthenticated/SignUpPage/types';
import AuthService from '~/services/resources/auth';
import {
  CognitoError,
  CognitoErrorCode,
  CognitoErrorCodeMessages,
} from '~/types/interfaces/cognito-errors';

const useSignUpController = () => {
  const modalTermsOfServiceRef = useRef<IModalRefProps>(null);
  const modalPrivacyPolicyRef = useRef<IModalRefProps>(null);

  const navigate = useNavigate();
  const { goToRoute, goBack } = useRouter();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<ISignUpFormFields>({
    resolver: yupResolver(schemaValidation),
    defaultValues,
    mode: 'all',
  });

  const handleGoToSignIn = () => goToRoute(PAGES.SIGNIN);

  const handleOnSubmit = handleSubmit(async (data) => {
    try {
      await AuthService.signUpWithEmailAndPassword({
        email: data.email,
        password: data.password.trim(),
        attributes: {
          name: `${data.firstName} ${data.lastName}`,
          phone_number: data.phoneNumber.replace(/\s/g, ''),
        },
      });

      toast.success(SIGN_UP_MESSAGES.ACCOUNT_CREATED_SUCCESSFULLY);
      navigate(PAGES.SIGN_UP_EMAIL_CONFIRMATION, {
        state: { email: data.email, password: data.password.trim() },
      });
    } catch (err) {
      const error = err as CognitoError;

      if (error.code === CognitoErrorCode.UsernameExistsException) {
        toast.info(CognitoErrorCodeMessages[error.code]);
        goToRoute(PAGES.ROOT);
        return;
      }

      if (error.code === CognitoErrorCode.NotAuthorized) {
        toast.info(CognitoErrorCodeMessages.UsernameExistsException);
        goToRoute(PAGES.ROOT);
        return;
      }

      toast.error(SIGN_UP_MESSAGES.CREATE_ACCOUNT_ERROR);
    }
  });

  const handleOpenTermsOfService = () => modalTermsOfServiceRef.current?.open();

  const handleOpenPrivacyPolicy = () => modalPrivacyPolicyRef.current?.open();

  return {
    modalTermsOfServiceRef,
    modalPrivacyPolicyRef,
    control,
    isValid,
    errors,
    isSubmitting,
    goBack,
    handleGoToSignIn,
    handleOnSubmit,
    handleSubmit,
    handleOpenTermsOfService,
    handleOpenPrivacyPolicy,
  };
};

export default useSignUpController;

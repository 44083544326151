import { MeasurementCategory } from 'ui/enums';

import { api } from '~/services/api';
import {
  BaseInsightQueryParams,
  CharitiesReportResponse,
  InsightByPeriodTypeQueryParams,
  InsightMeasurementGroupedBy,
} from '~/services/resources/types';

const MODULE_URL = 'org-admin/insights';

type GetFavoriteCausesByHourResponse = {
  total: number;
  causeOption: string;
};

type GetMeasurementGoalsResponse = {
  organizationGoal: number;
  completedGoalPercentage: number;
  percentageVariation: number;
  totalCurrentPeriod: number;
  totalPreviousPeriod: number;
  totalThisYearPeriod: number;
};

export type GetMeasurementCharitiesHelpedReportQueryParams =
  InsightByPeriodTypeQueryParams & {
    measurementType: MeasurementCategory;
    measurementUnit: string;
  };

export type GetMeasurementByUserReportQueryParams = BaseInsightQueryParams & {
  groupBy: InsightMeasurementGroupedBy[];
  measurementType: MeasurementCategory;
};

export type GetMeasurementByUserReportResponse = {
  total: number;
  userProfile: {
    name: string;
    _id: string;
    profilePicture: string;
  };
};

export default class InsightMeasurementService {
  static FAVORITE_CAUSES_BY_HOUR_KEY = `${MODULE_URL}/measurements/favorite-causes`;
  static async getFavoriteCausesByHour({
    apps,
    organization,
    ...restQueryParams
  }: BaseInsightQueryParams) {
    const response = await api.get<GetFavoriteCausesByHourResponse[]>(
      `${MODULE_URL}/${organization}/measurements/favorite-causes`,
      {
        params: {
          apps: apps.length ? JSON.stringify(apps) : undefined,
          ...restQueryParams,
        },
      },
    );

    return response.data ?? [];
  }

  static MEASUREMENT_GOALS_KEY = `${MODULE_URL}/measurements/goals`;
  static async getMeasurementGoals({
    apps,
    organization,
    ...restQueryParams
  }: InsightByPeriodTypeQueryParams) {
    const response = await api.get<GetMeasurementGoalsResponse>(
      `${MODULE_URL}/${organization}/measurements/goals`,
      {
        params: {
          apps: apps.length ? JSON.stringify(apps) : undefined,
          ...restQueryParams,
        },
      },
    );

    return response.data;
  }

  static MEASUREMENT_CHARITIES_HELPED_REPORT_KEY = `${MODULE_URL}/measurements/charities-helped`;
  static async getMeasurementCharitiesHelpedReport({
    organization,
    apps,
    ...restQueryParams
  }: GetMeasurementCharitiesHelpedReportQueryParams) {
    const response = await api.get<CharitiesReportResponse>(
      `${MODULE_URL}/${organization}/measurements/charities-helped`,
      {
        params: {
          apps: apps.length ? JSON.stringify(apps) : undefined,
          ...restQueryParams,
        },
      },
    );

    return response.data;
  }

  static MEASUREMENT_REPORT_KEY = `${MODULE_URL}/measurements`;
  static async getMeasurementByUserReport({
    organization,
    apps,
    groupBy,
    ...restQueryParams
  }: GetMeasurementByUserReportQueryParams) {
    const response = await api.get<GetMeasurementByUserReportResponse[]>(
      `${MODULE_URL}/${organization}/measurements`,
      {
        params: {
          apps: apps.length ? JSON.stringify(apps) : undefined,
          groupBy: groupBy.length ? JSON.stringify(groupBy) : undefined,
          ...restQueryParams,
        },
      },
    );

    return response.data ?? [];
  }
}

import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ILocationSelected } from 'ui/components/InputSearchLocation/types';
import { ITabsRefProps } from 'ui/components/Tabs/types';
import { compressImage } from 'ui/utils/compressImage';

import { DEFAULT_AWS_PUBLIC_BUCKET } from '~/config';
import { ORGANIZATION_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { useRouter } from '~/hooks/useRouter';
import { InviteCodeService } from '~/services/resources/inviteCode';
import { OrganizationService } from '~/services/resources/organization';
import { UploadsSignedURLObjectTarget } from '~/services/resources/types';
import { UploadsService } from '~/services/resources/uploads';
import { authSliceActions } from '~/store/slices/auth';
import { IPaginatedResponse } from '~/types';
import {
  IActivityCategoryDTO,
  IOrganizationCreatePayloadDTO,
} from '~/types/dtos';

import { defaultValues, queryFilter, schemaValidation } from './constants';
import { IRegisterOrganizationForm, organizationTypes } from './types';

export const useRegisterOrganisationController = () => {
  const tabsRef = useRef<ITabsRefProps>(null);
  const [selectedLocation, setSelectedLocation] = useState<ILocationSelected>();
  const { isAuthenticated } = useAppSelector(({ auth }) => auth);
  const { isJoiningAsPartner, inviteURL } = useAppSelector(
    ({ joinAsPartner }) => joinAsPartner,
  );

  const { replaceRoute } = useRouter();
  const dispatch = useAppDispatch();

  const organisationTypes = organizationTypes;

  const { data: causeOptionsData } = useQuery<
    IPaginatedResponse<IActivityCategoryDTO[]>
  >(`/activity-category`, {
    requestOptions: {
      params: queryFilter.causeOptions,
    },
  });

  const {
    control,
    formState: { isSubmitting, isValid, isDirty, errors },
    getValues,
    watch,
    setValue,
    handleSubmit,
  } = useForm<IRegisterOrganizationForm>({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });
  const {
    fields: adminEmails,
    append: appendAdminEmail,
    remove: removeAdminEmail,
  } = useFieldArray({
    control,
    name: 'adminEmailList',
  });
  const currentTab = watch('currentStep');

  const isDisabledButton = useMemo(
    () => !isValid || !isDirty,
    [isValid, isDirty],
  );

  const causeSelectOptions = useMemo(() => {
    if (causeOptionsData?.data?.length) {
      return causeOptionsData?.data.map((causeOptions) => {
        return {
          value: causeOptions._id,
          label: causeOptions.displayName,
        };
      });
    }

    return [];
  }, [causeOptionsData]);

  const handleAddAdminEmail = (email: string) => () => {
    if (adminEmails.find((item) => item.email === email)) {
      toast.warn('Email already added.');
      return;
    }
    appendAdminEmail({ email });
    setValue('adminEmail', '');
  };

  const handleRemoveAdminEmail = (index: number) => () =>
    removeAdminEmail(index);

  const handleContinue = () => {
    const currentStep = getValues('currentStep');
    if (currentStep !== 2) {
      setValue('currentStep', currentStep + 1, { shouldValidate: true });
      tabsRef.current?.nextStep();
    }
  };

  const handleBack = () => {
    const currentStep = getValues('currentStep');
    if (currentStep !== 0) {
      setValue('currentStep', currentStep - 1, { shouldValidate: true });
      tabsRef.current?.backStep();
    }
  };

  const onSubmit = async (organisationData: IRegisterOrganizationForm) => {
    if (!selectedLocation) return;

    try {
      const causeOptions =
        causeOptionsData?.data.filter((item) =>
          organisationData.causeOptions.includes(item._id),
        ) || [];

      const logo = organisationData.logo as File[];
      let logoURL = '';

      if (logo?.length && logo[0]?.type) {
        try {
          const compressedImage = (await compressImage(logo[0], 0.3)) as File;
          const signedURLResponse = await UploadsService.generatePreSignedURL({
            Target: UploadsSignedURLObjectTarget.OrganizationPicture,
            FileExtension: compressedImage.type.split('/').pop() || '',
          });

          logoURL = `${DEFAULT_AWS_PUBLIC_BUCKET}/${signedURLResponse.Key}`;

          await UploadsService.uploadFileToS3({
            file: compressedImage,
            signedURL: signedURLResponse,
          });
        } catch {
          toast.error(ORGANIZATION_MESSAGES.ERROR_ON_UPLOAD);
          return;
        }
      }

      const payload: Partial<IOrganizationCreatePayloadDTO> = {
        ...organisationData,
        causeOptions,
        logo: logoURL,
        termsOfServicesLink: organisationData.termsOfServiceLink,
        contactEmail: organisationData.email,
        contactPhoneNumber: organisationData.phone,
        fullAddress: {
          street: selectedLocation.rawLocation ?? '',
          location: {
            type: 'Point',
            coordinates: [selectedLocation.lng, selectedLocation.lat],
          },
        },
      };

      const { newOrganization } = await OrganizationService.createOrganization(
        payload,
      );

      if (organisationData.adminEmailList.length) {
        await InviteCodeService.create({
          organizationId: newOrganization._id,
          emails: organisationData.adminEmailList.map((admin) => admin.email),
        });
      }

      dispatch(
        authSliceActions.update({
          organizations: [newOrganization],
          hasOrganization: true,
          organizationSelectedId: newOrganization._id,
        }),
      );

      if (isJoiningAsPartner && inviteURL) {
        replaceRoute(inviteURL);
      } else {
        replaceRoute(PAGES.ROOT);
      }

      toast.success(ORGANIZATION_MESSAGES.SUCCESS_ON_CREATE);
    } catch (error) {
      toast.error(ORGANIZATION_MESSAGES.ERROR_ON_CREATE);
    }
  };

  return {
    organisationTypes,
    causeSelectOptions,
    control,
    errors,
    isDisabledButton,
    tabsRef,
    isAuthenticated,
    currentTab,
    selectedLocation,
    isSubmitting,
    adminEmails,
    handleAddAdminEmail,
    handleRemoveAdminEmail,
    handleBack,
    handleContinue,
    setSelectedLocation,
    handleOnSubmit: handleSubmit(onSubmit),
  };
};

import '@inovua/reactdatagrid-community/index.css';
import './styles.css';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import { Stack } from 'native-base';
import { memo } from 'react';
import Loading from 'ui/components/v2/Loading';

import { BaseModel, TableSizeClass } from './@types/basicTypes';
import { IDataSimpleTableProps, IDataTableProps } from './@types/propTypes';
import { useSimpleTableController, useTableController } from './controller';
import { styles } from './styles';
import { ROWS_PER_PAGE } from './utils';

const DataTable = <Model extends BaseModel>({
  columns,
  defaultFilterValues,
  loadData,
  isLoading,
  refetchQuantity,
  checkboxColumn,
  onSelectionChange,
  selected,
  onReady,
  defaultLimit,
  pageSizes,
  className = TableSizeClass.Default,
  pagination = 'remote',
  style = {},
  emptyText,
}: IDataTableProps<Model>) => {
  const {
    filterValue,
    dataSource,
    checkboxColumnConfig,
    onSkipChange,
    onLimitChange,
    onFilterValueChange,
    handleOnSelectionChange,
  } = useTableController({
    loadData,
    defaultFilterValues,
    refetchQuantity,
    checkboxColumn,
    onSelectionChange,
  });

  return (
    <ReactDataGrid
      onReady={onReady}
      idProperty="_id"
      columns={columns}
      dataSource={dataSource}
      filterValue={filterValue}
      pagination={pagination}
      onFilterValueChange={onFilterValueChange}
      onSkipChange={onSkipChange}
      onLimitChange={onLimitChange}
      pageSizes={pageSizes || ROWS_PER_PAGE}
      loading={isLoading}
      defaultLimit={defaultLimit || ROWS_PER_PAGE[1]}
      style={{ ...styles.dataTableStyle, ...style }}
      className={className}
      checkboxColumn={checkboxColumnConfig}
      onSelectionChange={handleOnSelectionChange}
      selected={selected}
      emptyText={emptyText}
    />
  );
};

export default memo(DataTable);

export const SimpleDataTable = <Model extends BaseModel>({
  columns,
  defaultFilterValues,
  isLoading,
  checkboxColumn,
  onSelectionChange,
  selected,
  onReady,
  defaultLimit,
  showHeader = true,
  style = {},
  className = TableSizeClass.Default,
  dataSource,
}: IDataSimpleTableProps<Model>) => {
  const {
    filterValue,
    onFilterValueChange,
    checkboxColumnConfig,
    handleOnSelectionChange,
  } = useSimpleTableController({
    defaultFilterValues,
    checkboxColumn,
    onSelectionChange,
  });

  return (
    <Stack position={'relative'}>
      {isLoading && <Loading float />}
      <ReactDataGrid
        onReady={onReady}
        idProperty="_id"
        columns={columns}
        dataSource={dataSource}
        filterValue={filterValue}
        pagination="local"
        showHeader={showHeader}
        onFilterValueChange={onFilterValueChange}
        defaultLimit={defaultLimit || ROWS_PER_PAGE[1]}
        style={{ ...styles.dataTableStyle, ...style }}
        className={className}
        checkboxColumn={checkboxColumnConfig}
        onSelectionChange={handleOnSelectionChange}
        selected={selected}
      />
    </Stack>
  );
};

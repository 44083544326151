import { HStack, Skeleton, Text, VStack } from 'native-base';
import { useState } from 'react';

import { CharitiesHelpedApplications } from '~/pages/Authenticated/Insights/components/CharitiesHelped/CharitiesHelpedApplications';
import { CharitiesHelpedLogs } from '~/pages/Authenticated/Insights/components/CharitiesHelped/CharitiesHelpedLogs';
import {
  InsightScaffold,
  InsightScaffoldChildrenReactComponent,
} from '~/pages/Authenticated/Insights/components/InsightScaffold';
import {
  InsightTabOption,
  InsightTabs,
} from '~/pages/Authenticated/Insights/components/InsightTabs';
import { PercentageChangeIndicator } from '~/pages/Authenticated/Insights/components/PercentageChangeIndicator';
import { CharitiesReportResponse } from '~/services/resources/types';

import { CharitiesHelpedProps } from './types';
import { INSIGHT_TABS, insightTabList } from './utils';

export const CharitiesHelpedTabs: InsightScaffoldChildrenReactComponent = ({
  onSetChart,
}) => {
  const [periodData, setPeriodData] = useState<CharitiesReportResponse>();
  const [selectedTab, setSelectedTab] = useState<InsightTabOption>(
    insightTabList?.[0],
  );

  const handleChangeTab = (tab: InsightTabOption) => {
    setPeriodData(undefined);
    setSelectedTab(tab);
  };

  const renderTabs = () => {
    switch (selectedTab.value) {
      case INSIGHT_TABS.LOGS:
        return <CharitiesHelpedLogs onSetPeriodData={handleSetPeriodData} />;
      case INSIGHT_TABS.APPLICATIONS:
        return (
          <CharitiesHelpedApplications onSetPeriodData={handleSetPeriodData} />
        );
      default:
        return null;
    }
  };

  const handleSetPeriodData = (data?: CharitiesReportResponse) => {
    if (!data) {
      setPeriodData(undefined);
      onSetChart(null, []);
      return;
    }

    const { currentPeriodInsights, ...restPeriodData } = data;
    const flattenData = currentPeriodInsights.map((item) => ({
      ...item.activityOwnerOrganizationSummary,
      total: item.total,
      ...restPeriodData,
    }));

    setPeriodData(data);
    onSetChart(null, flattenData);
  };

  return (
    <VStack space={4}>
      <HStack
        space={[4, 4, 2]}
        alignItems={'center'}
        px={6}
        pb={6}
        justifyContent={['center', 'center', 'space-between']}
        flexGrow={'grow'}
        direction={['column-reverse', 'column-reverse', 'row']}
      >
        <HStack space={4} alignItems="center" flex={1}>
          {!periodData ? (
            <Skeleton.Text lines={2} height={4} width={'40%'} />
          ) : (
            <>
              <Text color="gray.800" fontSize="4xl" fontWeight={500}>
                {String(periodData.totalCurrentPeriod).padStart(2, '0')}
              </Text>
              <PercentageChangeIndicator
                isPositive={periodData.percentageVariation > 0}
                percentage={periodData.percentageVariation.toFixed(2)}
              />
            </>
          )}
        </HStack>
        <InsightTabs
          flex={1}
          onChange={handleChangeTab}
          options={insightTabList}
        />
      </HStack>
      {renderTabs()}
    </VStack>
  );
};

export const CharitiesHelped = ({ className }: CharitiesHelpedProps) => {
  return (
    <InsightScaffold
      className={className}
      title={{
        icon: 'heart',
        name: 'Charities Helped',
      }}
    >
      {CharitiesHelpedTabs}
    </InsightScaffold>
  );
};

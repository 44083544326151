export const FAKE_USER_PROFILE = {
  _id: '65baa0caa043c437c649daf9',

  user: '65baa01928cb037204c69340',
  userSummary: {
    _id: '65baa01928cb037204c69340',
    name: 'Lucas Fonseca Barreto',
    email: 'lucasbfonte@gmail.com',
  },
  ecosystem: '65b9443a3caaba90f5fa4e8b',
  ecosystemSummary: {
    name: 'Lucas Fonseca ecosystem',
    description: 'Lucas Fonseca ecosystem',
    organization: '65b944393caaba90f5fa4e7d',
    _id: '65b9443a3caaba90f5fa4e8b',
    createdAt: {
      $date: '2024-01-30T18:47:22.186Z',
    },
    updatedAt: {
      $date: '2024-01-31T19:34:34.828Z',
    },
    __v: 0,
  },
  organization: '65b944393caaba90f5fa4e7d',
  organizationSubDocument: '65b944393caaba90f5fa4e7d',
  appPermissionGroups: [],
  deleted: false,
  createdAt: {
    $date: '2024-01-31T19:34:34.828Z',
  },
  updatedAt: {
    $date: '2024-06-05T12:46:03.340Z',
  },
  __v: 0,
  emailPreferences: {
    joinConfirmation: true,
    bookingConfirmation: true,
    applicationConfirmation: true,
    bookingReminder: true,
    canceledEvent: true,
    canceledOngoingOpportunity: true,
    thirdParty: true,
    survey: true,
    registerParticipation: true,
    weeklyDigest: true,
    dailyDigest: true,
  },
  externalId: '123',
  profilePicture:
    'https://s3.eu-west-2.amazonaws.com/doit2.0-staging-uploads/ProfilePicture/0c26d67e-db41-4bfa-bfca-9bf432614645_2024-02-06T14:10:51.091Z.jpeg',
};

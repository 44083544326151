import { toast } from 'react-toastify';
import { compressImage } from 'ui/utils/compressImage';

import { DEFAULT_AWS_PUBLIC_BUCKET } from '~/config';
import { SIGN_UP_MESSAGES } from '~/constants/messages.constants';
import { UploadsSignedURLObjectTarget } from '~/services/resources/types';
import { UploadsService } from '~/services/resources/uploads';

const FILE_RESIZE_DEFAULT_RATIO = 0.3;

export const uploadLogoIfProvided = async (
  file?: File,
): Promise<string | undefined> => {
  if (!file) return undefined;
  try {
    const compressedImage = await compressImage(
      file,
      FILE_RESIZE_DEFAULT_RATIO,
    );
    const signedUrlResponse = await UploadsService.generatePreSignedURL({
      Target: UploadsSignedURLObjectTarget.OrganizationPicture,
      FileExtension: file.type.split('/')[1],
    });
    await UploadsService.uploadFileToS3({
      file: compressedImage as File,
      signedURL: signedUrlResponse,
    });
    return `${DEFAULT_AWS_PUBLIC_BUCKET}/${signedUrlResponse.Key}`;
  } catch (err) {
    toast.error(SIGN_UP_MESSAGES.ERROR_ON_UPLOAD);
  }
};

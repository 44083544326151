import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { Stack } from 'native-base';
import { IColumn } from 'ui/components/DataTable/types';
import { IconEnum } from 'ui/components/Icon';
import { TableCellWithAvatar } from 'ui/components/TableCellWithAvatar';
import Tag from 'ui/components/Tags/Tag';
import { EEventApplicationType } from 'ui/enums';
import { ActivityType } from 'ui/types/activities';
import { formattedDate } from 'ui/utils/formatter';

import { ApplicationActions } from '~/pages/Authenticated/Activities/components/ApplicationActions';
import { ApplicationStatusTag } from '~/pages/Authenticated/Activities/components/ApplicationStatusTag';
import { colors } from '~/theme/colors';
import {
  ActivityApplicationEnum,
  IActivityApplication,
} from '~/types/interfaces/activity';
import { IUser } from '~/types/interfaces/user';

import { IApplicationColumnsData, IHandleApplicationFunctions } from './types';

export const applicationsColumns = ({
  onAcceptApplication,
  onRejectApplication,
  isHandlingApplications,
  handleViewMemberApplication,
  handleViewTeamApplication,
}: IHandleApplicationFunctions): IColumn[] => [
  {
    filterable: true,
    name: 'applicationType',
    header: 'Application Type',
    defaultFlex: 1.4,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: false,
      wrapMultiple: false,
      dataSource: Object.values(EEventApplicationType).map(
        (applicationType) => {
          return {
            id: applicationType,
            label: applicationType,
          };
        },
      ),
    },
    render: ({ data }: IApplicationColumnsData) => (
      <Stack>
        <Tag
          text={data.applicationType}
          type="actions"
          icon={
            data.applicationType === EEventApplicationType.Individual
              ? IconEnum.USER
              : IconEnum.USERS
          }
          tagTextColor={colors.darkText}
          tagBgColor={
            data.applicationType === EEventApplicationType.Individual
              ? colors.warning[200]
              : colors.lime[200]
          }
          showIcon
        />
      </Stack>
    ),
  },
  {
    filterable: true,
    name: 'userProfileSubDocument.userSummary.name',
    header: 'Name',
    defaultFlex: 1.5,
    render: ({ data }: IApplicationColumnsData) => {
      return (
        <TableCellWithAvatar
          name={
            data?.teamSubDocument?.name ||
            (data?.user as unknown as IUser)?.name ||
            (data?.user as unknown as IUser)?.email ||
            'Unnamed'
          }
          avatar={data.userProfileSubDocument?.userSummary?.profileImage || ''}
        />
      );
    },
  },
  {
    filterable: true,
    name: 'organization.name',
    header: 'Member of',
    defaultFlex: 1.5,
    render: ({ data }: IApplicationColumnsData) => (
      <TableCellWithAvatar
        name={data.organization.name}
        avatar={data.organization.logo}
        isOrganization
      />
    ),
  },
  {
    name: 'applicationDate',
    header: 'Date of Application',
    defaultFlex: 1,
    filterEditor: DateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'YYYY-MM-DD',
        cancelButton: false,
        highlightWeekends: false,
      };
    },
    render: ({ data }: IApplicationColumnsData) =>
      data.createdAt ? formattedDate(data.applicationDate, 'dd/MM/yyyy') : '-',
  },
  {
    name: 'activitySubDocument.activityDefinitionSubDocument.title',
    header: 'Activity',
    defaultFlex: 1,

    render: ({ data }: IApplicationColumnsData) =>
      data.activitySubDocument.activityDefinitionSubDocument.title,
  },
  {
    name: 'activitySubDocument.address.street',
    header: 'Activity Location',
    defaultFlex: 1,

    render: ({ data }: IApplicationColumnsData) => {
      const typesWithAddress = [ActivityType.Event, ActivityType.Ongoing];
      if (
        typesWithAddress.includes(
          data.activitySubDocument.activityDefinitionSubDocument
            .type as ActivityType,
        )
      ) {
        return (
          data.activitySubDocument.address?.street ||
          data.activitySubDocument.meetingLink
        );
      } else {
        return 'From Home';
      }
    },
  },
  {
    name: 'activitySubDocument.startDate',
    header: 'Start Date',
    defaultFlex: 1,

    render: ({ data }: IApplicationColumnsData) =>
      data.activitySubDocument.startDate
        ? formattedDate(data.activitySubDocument.startDate)
        : 'N/A',
  },
  {
    name: 'status',
    header: 'Status',
    defaultFlex: 1.2,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: false,
      wrapMultiple: false,
      dataSource: Object.values(ActivityApplicationEnum).map(
        (applicationStatus) => {
          return {
            id: applicationStatus,
            label: applicationStatus,
          };
        },
      ),
    },
    render: ({ data }: IApplicationColumnsData) => (
      <ApplicationStatusTag status={data.status as ActivityApplicationEnum} />
    ),
  },
  {
    name: 'actions',
    header: 'Actions',
    defaultFlex: 1.6,
    render: ({ data }: IApplicationColumnsData) => (
      <ApplicationActions
        showDeclineButton={
          [
            ActivityApplicationEnum.Pending,
            ActivityApplicationEnum.Approved,
            ActivityApplicationEnum.Referral,
          ].includes(data.status) && !data.isAttendanceConfirmed
        }
        showAcceptButton={
          data.status === ActivityApplicationEnum.Pending ||
          data.status === ActivityApplicationEnum.Rejected
        }
        onPressAccept={() =>
          onAcceptApplication({
            activityId: data.activitySubDocument._id,
            isTeamApplication:
              data.applicationType === EEventApplicationType.Team,
            userProfile: data.userProfileSubDocument
              ? data.userProfileSubDocument
              : undefined,
            teamProfile: data.teamSubDocument
              ? data.teamSubDocument
              : undefined,
          })
        }
        onPressDecline={() => onRejectApplication(data as IActivityApplication)}
        onPressView={() => {
          if (data.applicationType === EEventApplicationType.Team) {
            handleViewTeamApplication(data._id);
            return;
          }
          handleViewMemberApplication(data.userProfileSubDocument?._id || '');
        }}
        isButtonsDisabled={isHandlingApplications}
      />
    ),
  },
];

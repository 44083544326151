export const tabElements = [
  {
    title: 'About the activity',
    component: () => <></>,
  },

  {
    title: 'Applications',
    component: () => <></>,
  },
];

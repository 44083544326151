import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Organization } from 'ui/types/interfaces/organization';

import { ECOSYSTEM_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { EcosystemVisibility, OrgPlusContentViewedFlags } from '~/enums';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { useRouter } from '~/hooks/useRouter';
import { EcosystemService } from '~/services/resources/ecosystem';
import { OrganizationService } from '~/services/resources/organization';
import { IPaginatedResponse } from '~/types';
import { IEcosystem } from '~/types/interfaces/ecosystem';
import { IOrganization } from '~/types/interfaces/organization';
import { hasSomeAdminEditedEcosystem } from '~/utils/hasSomeAdminEditedEcosystem';
import { uploadLogoIfProvided } from '~/utils/uploadLogoIfProvided';

import { ICreateEcosystemForm } from '../types';

export const useEditEcosystemController = () => {
  const { params, replaceRoute } = useRouter();
  const { organizationSelectedId } = useAppSelector(({ auth }) => auth);

  const { data: organization } = useQuery<IOrganization>(
    `organization/${organizationSelectedId}`,
  );
  const { data: ecosystems } = useQuery<IPaginatedResponse<IEcosystem[]>>(
    'ecosystem',
    {
      requestOptions: {
        params: {
          filter: JSON.stringify({ organization: organizationSelectedId }),
          limit: 1,
        },
      },
      queryOptions: {
        enabled: !!organization && !hasSomeAdminEditedEcosystem(organization),
      },
    },
  );

  const queryClient = useQueryClient();
  const ecosystemIdToEdit = params.id;

  const handleOnSubmit = async (ecosystemData: ICreateEcosystemForm) => {
    if (!ecosystemIdToEdit) return;

    const logo = ecosystemData.logo as File[];
    let logoURL;
    if (logo[0]?.type) {
      logoURL = await uploadLogoIfProvided(logo[0]);
    }

    try {
      if (
        ecosystems?.count === 1 &&
        organization &&
        !hasSomeAdminEditedEcosystem(organization)
      ) {
        let contentViewedFlags = 0;
        if (organization.contentViewedFlags) {
          contentViewedFlags =
            organization.contentViewedFlags |
            OrgPlusContentViewedFlags.firstEditEcosystemModal;
        } else {
          contentViewedFlags =
            OrgPlusContentViewedFlags.firstEditEcosystemModal;
        }

        /* NOTE: Api nao suporta contentViewedFlags e aparentemente nao tem utilidade */
        await OrganizationService.updateOne(organization._id, {
          contentViewedFlags,
        } as Partial<Organization>);
      }

      await EcosystemService.updateOne(ecosystemIdToEdit, {
        ...ecosystemData,
        visibility: ecosystemData.isPrivate
          ? EcosystemVisibility.Private
          : EcosystemVisibility.Public,
        logo: logoURL,
      });
      await queryClient.invalidateQueries(`/ecosystem/${ecosystemIdToEdit}`);
      toast.success(ECOSYSTEM_MESSAGES.SUCCESS_ON_UPDATE);
      replaceRoute(`${PAGES.ECOSYSTEM_SETTINGS}/${ecosystemIdToEdit}`);
    } catch {
      toast.error(ECOSYSTEM_MESSAGES.ERROR_ON_UPDATE);
    }
  };

  return {
    ecosystemIdToEdit,
    handleOnSubmit,
  };
};

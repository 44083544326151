import {
  Avatar,
  Divider,
  HStack,
  Input,
  Stack,
  Text,
  useClipboard,
  VStack,
} from 'native-base';
import { toast } from 'react-toastify';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import Tag from 'ui/components/Tags/Tag';

import { colors } from '~/theme/colors';
import {
  iconToShowByActivityType,
  labelTypeEventBgColorByActivityType,
  showCustomIconByActivityTypeAction,
  textToShowByActivityType,
} from '~/utils/functions';

import { ActivitySuccessModalProps } from './types';

export const ActivitySuccessModal = ({
  title,
  activityName,
  activityType,
  // activityDate,
  activityEcosystem,
  ecosystemLogo,
  activityUrl,
  onViewActivity,
  onClose,
  eventApplicationType,
}: ActivitySuccessModalProps) => {
  const { onCopy } = useClipboard();

  const handleCopyLink = () => {
    if (activityUrl) {
      onCopy(activityUrl);
      toast.success('Link copied to clipboard');
    }
  };

  return (
    <VStack w="55%" minH="40%" bg="white" borderRadius="xl" overflow="hidden">
      <HStack pt={6} px={6} alignItems="center" justifyContent="space-between">
        <Text fontSize="2xl" fontWeight="medium">
          {title}
        </Text>
        <Button variant="ghost" minW="2rem" w="2rem" onPress={onClose}>
          <Icon icon="x" size={24} color={colors.gray[400]} />
        </Button>
      </HStack>

      <Divider my={4} />
      <VStack fontWeight="medium" px={6} pt={2} flex={1}>
        <Text fontSize="lg" color="gray.500">
          Let’s share the link to the activity page and start getting
          applications.
        </Text>
        <VStack
          borderRadius="xl"
          borderWidth="2"
          borderColor="gray.200"
          p={2}
          mt={6}
        >
          <Tag
            text={textToShowByActivityType(activityType, eventApplicationType)}
            type="actions"
            icon={iconToShowByActivityType(activityType, eventApplicationType)}
            tagTextColor={colors.darkText}
            tagBgColor={labelTypeEventBgColorByActivityType(activityType)}
            showCustomIcon={showCustomIconByActivityTypeAction(activityType)}
            showIcon
          />

          <HStack space={4} p={2} alignItems="center">
            <Stack w="7%">
              <Avatar
                source={{
                  uri: ecosystemLogo,
                }}
                height="48px"
                width="48px"
                borderRadius="full"
                bgColor="primary.500"
              >
                {activityEcosystem?.charAt(0)}
              </Avatar>
            </Stack>
            <VStack pt={2} w="90%">
              <HStack space={2} flexWrap="wrap">
                <Stack>
                  <Text fontWeight="bold" fontSize="lg">
                    {activityName}
                  </Text>
                </Stack>
                <Text fontSize="lg" fontWeight="medium">
                  •
                </Text>
                <Text fontSize="lg" color="gray.600">
                  {activityEcosystem}
                </Text>
              </HStack>
            </VStack>
          </HStack>
        </VStack>
        <VStack space={2} my={4}>
          <Text fontSize="sm" fontWeight="medium" color="gray.600">
            Activity URL
          </Text>
          <HStack w="full" space="2" alignItems="center">
            <Input
              flex={0.9}
              value={activityUrl}
              editable={false}
              isReadOnly
              InputLeftElement={
                <Stack ml={2}>
                  <Icon icon="globe" size={18} color={colors.gray[400]} />
                </Stack>
              }
            />
            <Button
              flex={0.1}
              borderWidth={0}
              leftIconName="copy"
              leftIconColor={colors.primary[400]}
              variant="subtle"
              bgColor={colors.primary[50]}
              minW="6rem"
              w="6rem"
              onPress={handleCopyLink}
            >
              <Text color="primary.600" fontSize="sm" fontWeight="medium">
                Copy
              </Text>
            </Button>
          </HStack>
        </VStack>
      </VStack>

      <HStack
        w="full"
        h="4rem"
        alignItems="flex-end"
        space={2}
        bg={colors.coolGray[100]}
        p={2}
      >
        <Button onPress={onClose} flex={0.5} variant="ghost">
          Close
        </Button>
        <Button onPress={onViewActivity} flex={0.5}>
          View Activity
        </Button>
      </HStack>
    </VStack>
  );
};

import { Route } from 'react-router-dom';
import { DesignBook } from 'ui/pages/DesignBook';
import { NotificationsDesignBook } from 'ui/pages/DesignBook/Notifications';

export const DeveloperRoutes = () => {
  return (
    <>
      <Route path={'developer/design-book'} element={<DesignBook />} />
      <Route
        path={'developer/design-book/notifications'}
        element={<NotificationsDesignBook />}
      />
    </>
  );
};

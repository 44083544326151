import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { VStack } from 'native-base';
import DataTableActions from 'ui/components/DataTableActions';
import Tag from 'ui/components/Tags/Tag';

import { ActivityTypeEnum } from '~/enums';
import { useRouter } from '~/hooks/useRouter';
import { colors } from '~/theme/colors';
import {
  iconToShowByActivityType,
  labelTypeEventBgColorByActivityType,
  showCustomIconByActivityTypeAction,
  textToShowByActivityType,
} from '~/utils/functions';

const ActionsColumn = ({ activityId }: { activityId: string }) => {
  const { goToRoute } = useRouter();

  return (
    <DataTableActions
      viewButton
      color={colors.primary[400]}
      onPressViewButton={() => goToRoute(`/activities/${activityId}`)}
    />
  );
};

export const tableActivitiesColumns = [
  {
    name: 'activity',
    header: 'Activity',
    defaultFlex: 1.3,
  },
  {
    name: 'organization',
    header: 'Organisation',
    defaultFlex: 1,
  },
  {
    name: 'type',
    header: 'Type',
    defaultFlex: 0.8,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: false,
      wrapMultiple: false,
      dataSource: Object.values(ActivityTypeEnum).map((activityType) => {
        return {
          id: activityType,
          label:
            activityType === ActivityTypeEnum.OngoingOpportunity
              ? 'Ongoing'
              : activityType,
        };
      }),
    },
    render: ({ data }: any) => (
      <VStack width="85px">
        <Tag
          text={textToShowByActivityType(data.type)}
          type="actions"
          icon={iconToShowByActivityType(data.type)}
          tagTextColor={colors.darkText}
          tagBgColor={labelTypeEventBgColorByActivityType(data.type)}
          showCustomIcon={showCustomIconByActivityTypeAction(data.type)}
          showIcon
        />
      </VStack>
    ),
  },
  {
    name: 'applications',
    header: 'Applications',
    defaultFlex: 0.7,
  },
  {
    name: 'members_amount',
    header: "Member's Total log",
    defaultFlex: 1,
  },
  {
    name: 'actions',
    header: 'Actions',
    defaultFlex: 0.6,
    render: ({ data }: any) => <ActionsColumn activityId={data.activity} />,
  },
];

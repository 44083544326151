import { constants } from './constants';

export const getEnvironment = () => {
  return {
    isDevelopment: constants.environment === 'development',
    isProduction: constants.environment === 'production',
  };
};

export const DevelopOnly = ({
  children,
  fallBackElement,
}: {
  children: JSX.Element;
  fallBackElement?: JSX.Element;
}): React.ReactElement | null => {
  if (!getEnvironment().isDevelopment) {
    return fallBackElement || null;
  }
  return children;
};

export default DevelopOnly;

export const apexChartsDefaultColors = [
  '#008FFB', // Blue
  '#00E396', // Green
  '#FEB019', // Orange
  '#FF4560', // Red
  '#775DD0', // Purple
  '#3F51B5', // Indigo
  '#03A9F4', // Light Blue
  '#009688', // Teal
  '#FF9800', // Amber
  '#FF5722', // Deep Orange
];

import { Box, HStack, Stack, Text, VStack } from 'native-base';
import { Controller } from 'react-hook-form';
import Button from 'ui/components/Button';
import CircleZone from 'ui/components/Dropzone/CircleZone';
import Input from 'ui/components/Input';
import InputUrl from 'ui/components/InputUrl';
import LocationAutoComplete from 'ui/components/v2/LocationInputs/LocationAutoComplete';
import { ILocation } from 'ui/types/interfaces';
import { transformInputValueToLowerCase } from 'ui/utils/formatter';

import { HelpCentreRedirectLink } from '~/components/HelpCentre';
import { GooglePlacesAutoCompleteAdapter } from '~/services/google/google-places-auto-auto-complete';
import { colors } from '~/theme/colors';

import { IDetailsFormProps } from '../types';
import { styleConfig } from './styleConfig';

const googlePlacesAutoComplete = GooglePlacesAutoCompleteAdapter.getSingleton();
export const DetailsForm = ({
  control,
  errors,
  isDisabledButton,
  selectedLocation,
  onLocationChangeHandler,
  handleBack,
  handleContinue,
}: IDetailsFormProps) => {
  return (
    <Stack space={5} mt={8} p={2} w="100%">
      <Stack paddingRight={6}>
        <HelpCentreRedirectLink />
      </Stack>
      <Stack w="100%" space={2}>
        <Text color="gray.600" fontSize="20px">
          Details
        </Text>
        <Text color="gray.600" fontWeight={400} fontSize="sm">
          Provide more information for volunteers to get to know your
          organisation.
        </Text>
      </Stack>
      <HStack flexDirection={styleConfig.direction} w="100%" space={8}>
        <Stack w={styleConfig.logoContainer}>
          <Controller
            name="logo"
            control={control}
            render={({ field: { value, onChange } }) => (
              <CircleZone
                filesField="image"
                acceptedWidth={400}
                acceptedHeight={400}
                title="Profile image"
                handleDropFile={onChange}
                defaultImage={value as string}
                btnIconColor={colors.primary[600]}
              />
            )}
          />
        </Stack>
        <VStack
          space={styleConfig.inputsSpace}
          w={styleConfig.inputsContainer}
          mt={styleConfig.inputsMargin}
        >
          <InputUrl
            label="URL"
            name="domainSlug"
            control={control}
            placeholder="Type your organisation's custom URL"
            errorMessage={errors.domainSlug?.message}
            iconErrorMessage="info"
            backgroundColor="white"
            isRequired
            isFullWidth
          />
          <Input
            isRequired
            isFullWidth
            control={control}
            name="email"
            placeholder="Fill in the email"
            label="Contact email address"
            iconErrorMessage="info"
            backgroundColor="white"
            borderColor="muted.200"
            onChangeTransform={transformInputValueToLowerCase}
          />
          <Input
            isRequired
            isFullWidth
            isPhoneInput
            control={control}
            name="phone"
            placeholder="Fill in the organisation's phone number"
            label="Contact phone number"
            iconErrorMessage="info"
            backgroundColor="white"
            borderColor="muted.200"
            leftIconName="phone"
            leftIconColor={colors.muted[400]}
          />
        </VStack>
      </HStack>
      <Box zIndex={999}>
        <LocationAutoComplete
          isRequired
          label="Full address"
          onChange={onLocationChangeHandler as any}
          defaultValue={selectedLocation as ILocation}
          provider={googlePlacesAutoComplete}
        />
      </Box>
      <Box>
        <Input
          multiline
          isFullWidth
          control={control}
          errorMessage={errors.description?.message}
          numberOfLines={10}
          name="description"
          label="Description"
          placeholder="Tell us a little more about your organisation..."
        />
      </Box>
      <HStack w="full" space="3">
        <Box flex="1">
          <Input
            isFullWidth
            control={control}
            name="websiteLink"
            placeholder="Fill in your website link"
            label="Website link"
            iconErrorMessage="info"
            backgroundColor="white"
            borderColor="muted.200"
          />
        </Box>
        <Box flex="1">
          <Input
            isFullWidth
            control={control}
            name="termsOfServiceLink"
            label="Terms of services page link"
            placeholder="Fill in the link to terms of services"
            iconErrorMessage="info"
            backgroundColor="white"
            borderColor="muted.200"
          />
        </Box>
      </HStack>

      <HStack
        w="full"
        flexDirection={styleConfig.direction}
        alignItems="center"
        space={2}
        mt="40px"
      >
        <Stack
          w={styleConfig.buttonWidth}
          display={styleConfig.desktopBackButtonDisplay}
        >
          <Button variant="ghost" width="100%" onPress={handleBack}>
            Back
          </Button>
        </Stack>
        <Stack w={styleConfig.buttonWidth}>
          <Button
            width="100%"
            isDisabled={isDisabledButton || !selectedLocation}
            onPress={handleContinue}
          >
            Continue
          </Button>
        </Stack>
        <Stack
          w={styleConfig.buttonWidth}
          display={styleConfig.mobileBackButtonDisplay}
        >
          <Button
            variant="ghost"
            width="100%"
            mt={styleConfig.buttonMargin}
            onPress={handleBack}
          >
            Back
          </Button>
        </Stack>
      </HStack>
    </Stack>
  );
};

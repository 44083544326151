import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from 'react-native/types';
import { toast } from 'react-toastify';

import { COGNITO_ERRORS_CODE } from '~/constants/error.constants';
import { COMMON_MESSAGES } from '~/constants/messages.constants';
import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import AuthService from '~/services/resources/auth';
import { OrganizationService } from '~/services/resources/organization';
import UserService from '~/services/resources/user';
import { authSliceActions } from '~/store/slices/auth';
import { IAuthDTO } from '~/types/dtos';

import { schemaValidation } from './constants';

export const useLoginController = () => {
  const { goToRoute } = useRouter();
  const { isAuthenticated } = useAppSelector(({ auth }) => auth);
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<IAuthDTO>({
    resolver: yupResolver(schemaValidation),
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const canSignIn = useMemo(() => !isValid || !isDirty, [isValid, isDirty]);

  const handleGoToSignUp = () => {
    goToRoute(PAGES.SIGN_UP);
  };

  const handleGoToForgotPassword = () => {
    goToRoute(PAGES.FORGOT_PASSWORD);
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await AuthService.signInWithEmailAndPassword(data);
      const { data: responseMeData } = await UserService.me();
      const userId = responseMeData._id;
      const filter = JSON.stringify({
        admins: { $in: [responseMeData._id] },
        // TODO: List of Activities: Add it back when implementing the back-end adjustments and validate it
        // type: OrganizationType.NFP,
      });
      const { data: organizationData } = await OrganizationService.findAll({
        filter,
      });

      dispatch(authSliceActions.signIn(response));
      if (organizationData?.data.length) {
        const userData = {
          _id: userId,
          organizations: organizationData.data,
          hasOrganization: true,
          isAuthenticated: true,
          organizationSelectedId: organizationData.data[0]._id,
        };
        dispatch(authSliceActions.update(userData));
        goToRoute(PAGES.ROOT);
      } else {
        dispatch(
          authSliceActions.update({ _id: userId, isAuthenticated: true }),
        );
        goToRoute(PAGES.SIGN_UP_CREATE_ORGANISATION);
      }
      toast.success(COMMON_MESSAGES.SUCCESSFUL_LOGIN);
    } catch (error) {
      if (error instanceof Error) {
        if (error?.code === COGNITO_ERRORS_CODE.USER_NOT_CONFIRMED_EXCEPTION) {
          await Auth.resendSignUp(data.email);
          goToRoute(PAGES.SIGN_UP_EMAIL_CONFIRMATION, {
            state: { email: data.email, password: data.password },
          });
          return;
        }
        toast.error(error.message);
      } else {
        toast.error(COMMON_MESSAGES.UNKNOWN_ERROR);
      }
    }
  });

  // NOTE: This is a workaround to dispatch the onSubmit method when the user press the enter key
  // i was not able to do this same thing with the html <form></form>
  const onKeyDown = ({
    nativeEvent,
  }: NativeSyntheticEvent<TextInputKeyPressEventData>): void => {
    if (nativeEvent.key === 'Enter') {
      onSubmit();
    }
  };

  return {
    isValid,
    control,
    isDirty,
    isSubmitting,
    canSignIn,
    isAuthenticated,
    errors,
    handleGoToSignUp,
    handleGoToForgotPassword,
    onSubmit,
    onKeyDown,
  };
};
